
import ProductType from "@/data/models/product-type";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ProductCreateUpdateDialog",
  components: {},
  props: {
    product: {
      type: Object as PropType<any>,
      default: () => null,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    visible: {
      handler() {
        this.form.id = this.product?.id || "";
        this.form.title = this.product?.title || "";
        this.form.consignmentPrice = this.product?.consignmentPrice || 0;
        this.form.servicePrice = this.product?.servicePrice || 0;
        this.form.consignmentPrice = this.form.consignmentPrice / 100;
        this.form.servicePrice = this.form.servicePrice / 100;
      },
    },
  },
  computed: {
    dialogTitle(): string {
      return this.product ? this.$t("products.updateDialogTitle").toString() : this.$t("products.createDialogTitle").toString();
    },
    action(): string {
      return this.product ? this.$t("common.edit").toString() : this.$t("common.create").toString();
    },
  },
  data() {
    return {
      form: {
        id: "",
        title: "",
        consignmentPrice: 0,
        servicePrice: 0,
      },
      loadingUpdateCreate: false,
      valid: false,
    };
  },
  methods: {
    async onSubmit(handleSubmit: Function, validate: any) {
      handleSubmit();
      const valid = await validate();

      if (!valid) {
        return;
      }

      this.loadingUpdateCreate = true;

      const formattedPrice = ProductType.map({
        id: this.form.id,
        title: this.form.title,
        consignment_price: this.form.consignmentPrice * 100,
        service_price: this.form.servicePrice * 100,
      });
      if (this.product) {
        this.$services.product
          .updateProductType(formattedPrice)
          .then(() => {
            this.$notify(this.$t("products.updatedSuccessfully").toString(), { color: "primary" });
            this.$emit("reload");
            this.close();
          })
          .catch((e) => {
            console.log(e);
            this.$notify(this.$t("common.error").toString(), { color: "error" });
          })
          .finally(() => {
            this.loadingUpdateCreate = false;
          });
      } else {
        this.$services.product
          .addProductType(formattedPrice)
          .then(() => {
            this.$notify(this.$t("products.addedSuccessfully").toString(), { color: "primary" });
            this.$emit("reload");
            this.close();
          })
          .catch(() => {
            this.$notify(this.$t("common.error").toString(), { color: "error" });
          })
          .finally(() => {
            this.loadingUpdateCreate = false;
          });
      }
    },
    close() {
      this.$emit("close");
      (this.$refs.observer as any)?.reset();

      this.form = {
        id: "",
        title: "",
        consignmentPrice: 0,
        servicePrice: 0,
      };
    },
  },
});
