
import Order from "@/data/models/order";
import Schedule from "@/data/models/schedule";
import User from "@/data/models/user";
import dayjs from "dayjs";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ScheduleDialog",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    from: {
      type: Object as PropType<User>,
      required: true,
    },
    to: {
      type: Object as PropType<User>,
    },
    order: {
      type: Object as PropType<Order>,
    },
    reversed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    days(): any[] {
      const ids = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

      return dayjs.weekdays(true).map((d, index) => {
        return {
          id: ids[index],
          title: d.charAt(0).toUpperCase() + d.slice(1),
        };
      });
    },
    first(): User | null {
      return this.reversed ? this.to : this.from;
    },
    second(): User | null {
      return this.reversed ? this.from : this.to;
    },
  },
  data() {
    return {
      fromSchedules: [] as Schedule[],
      toSchedules: [] as Schedule[],
      orderToSchedules: [] as Schedule[],
    };
  },
  watch: {
    visible: {
      handler(newValue: boolean) {
        if (!newValue) {
          return;
        }

        this.getSchedules(this.first, "from");
        this.getSchedules(this.second, "to");

        if (this.order) {
          this.getSchedules(this.order.to, "orderTo");
        }
      },
      immediate: true,
    },
  },

  methods: {
    close() {
      this.$emit("close");
      this.fromSchedules = [];
      this.toSchedules = [];
    },
    getSchedules(user: User | null, source: "from" | "to" | "orderTo") {
      if (!user || !user.id) {
        return;
      }

      this.$services.user.getSchedules(user.id).then((schedules) => {
        switch (source) {
          case "from":
            this.fromSchedules = schedules;
            break;
          case "to":
            this.toSchedules = schedules;
            break;
          case "orderTo":
            this.orderToSchedules = schedules;
            break;
          default:
            break;
        }
      });
    },
    getSchedulesStringForDay(array: Schedule[], day: string): string {
      const schedules = array.filter((item) => item.day === day);

      if (schedules.length === 0) {
        return "-";
      }

      return schedules.map((i) => i.time).join(", ");
    },
    openOnMap(address: string) {
      console.log(address);
      if (!address || address == "") {
        return;
      }

      window.open(`https://www.google.com/maps/search/?api=1&query=${address.replace(/ /g, "+")}`, "_blank");
    },
  },
});
