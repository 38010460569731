
import Vue, { PropType } from "vue";
import Order from "@/data/models/order";
import EditableItem from "@/components/atoms/EditableItem.vue";
import ProductTypeWithQuantity from "@/data/models/product-type-with-quantity";

export default Vue.extend({
  name: "CorrectProductsDialog",
  components: { EditableItem },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  watch: {
    order: {
      handler(newValue: Order) {
        this.products = newValue.products.map((p) => p.toProductTypesWithQuantity) || [];
      },
      immediate: true,
    },
    visible: {
      async handler(newValue: boolean) {
        if (newValue) {
          for (let index = 0; index < this.products.length; index++) {
            const ref: any = this.$refs[`editable_item_${index}`];

            if (ref && ref[0]) {
              ref[0].reset()

              if (!(await ref[0].refreshAvailableQuantity())) {
                return;
              }

            }
          }
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      products: [] as any[],
      productsError: false,
      loading: false,
      validQuantities: true,
    };
  },
  methods: {
    close() {
      this.validQuantities = true;
      this.$emit("close");
    },
    updateProducts(products: any[]) {
      this.products = products;
    },
    async onSubmit(handleSubmit: Function, validate: any) {
      handleSubmit();
      const valid = (await validate()) && this.products.length > 0;

      if (this.products.length === 0) {
        this.productsError = true;
      }

      if (!valid) {
        return;
      }

      if (this.$refs) {
        for (let index = 0; index < this.products.length; index++) {
          const ref: any = this.$refs[`editable_item_${index}`];

          if (ref && ref[0]) {
            if (!(await ref[0].valid())) {
              this.validQuantities = false;
              return;
            }
          }
        }
      }

      const products: any[] = this.products.map((p) => {
        return {
          product_type_id: p.productType.id,
          product_name: p.productType.title,
          product_quantity: p.quantity,
          product_consignment_price: p.productType.consignmentPrice,
          product_service_price: p.productType.servicePrice,
        };
      });

      this.loading = true;

      this.$services.order
        .updateOrder({
          id: this.order.id,
          fromId: this.order.from?.id,
          toId: this.order.to?.id,
          wishDate: this.order.wishDate,
          fromAddress: this.order.fromAddress,
          toAddress: this.order.toAddress,
          status: this.order.status,
          orders_has_products: {
            data: products,
          },
          deliveryComment: this.order.deliveryComment,
          orderedAt: this.order.orderedAt,
          type: this.order.type,
          carrierId: this.order.carrier?.id,
        })
        .then(() => {
          this.$notify(this.$t("orders.dialog.updateOrderSuccess").toString(), {
            color: "primary",
          });

          this.close();
        })
        .catch(() => {
          this.$notify(this.$t("orders.dialog.updateOrderFailed").toString(), {
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getItemTitle(item: ProductTypeWithQuantity): string {
      if (!item || item.productType === null) {
        return "";
      }

      return `${item.quantity} x ${item.productType?.title}`;
    },
  },
});
