
import Order from "@/data/models/order";
import PriceHelper from "@/helpers/price-helper";
import Vue from "vue";
import LoadingCompany from "@/components/atoms/LoadingCompany.vue";
import OrderActions from "@/components/molecules/orders/OrderActions.vue";

export default Vue.extend({
  name: "Order",
  components: {
    LoadingCompany,
    OrderActions,
  },
  computed: {
    title(): string {
      return `${this.$t("order.title")} / ${this.order?.id || ""}`;
    },
    getUserIdStart(): string {
      return (this.order?.reversed ? this.order?.from?.id || "" : this.order?.to?.id || "") || "";
    },
    getUserIdDestination(): string {
      return (this.order?.reversed ? this.order?.to?.id || "" : this.order?.from?.id || "") || "";
    },
  },
  data() {
    return {
      order: null as Order | null,
      loadingInvoice: false,
      invoiceUrl: null as string | null,
      headers: [
        {
          text: this.$t("order.productsTable.headers.title"),
          value: "name",
        },
        {
          text: this.$t("order.productsTable.headers.quantity"),
          value: "quantity",
        },
        {
          text: this.$t("order.productsTable.headers.consignmentPrice"),
          value: "consignmentPrice",
        },
        {
          text: this.$t("order.productsTable.headers.servicePrice"),
          value: "servicePrice",
        },
        {
          text: this.$t("order.productsTable.headers.total"),
          value: "total",
        },
      ],
    };
  },
  mounted() {
    this.getOrder();
  },
  methods: {
    getOrder() {
      const id = this.$route.params.id;

      this.$services.order.getOrder(id).then((order) => {
        this.order = order;

        if (order?.carrier?.id !== this.$store.getters.currentUser?.id) {
          this.getInvoice();
        }
      });
    },
    getInvoice() {
      if (!this.order) {
        return;
      }

      this.loadingInvoice = true;

      this.$services.order
        .getInvoice(this.order)
        .then((invoiceData) => {
          if (!invoiceData) {
            this.invoiceUrl = null;
            return;
          }

          const blob = new Blob([invoiceData], { type: "application/pdf" });
          const link = URL.createObjectURL(blob);

          this.invoiceUrl = link;
        })
        .catch(() => {
          this.$notify(this.$t("returns.loadingInvoiceFailed").toString(), {
            color: "error",
          });
        })
        .finally(() => {
          this.loadingInvoice = false;
        });
    },
    seeInvoice() {
      if (this.invoiceUrl) {
        window.open(this.invoiceUrl);
      }
    },
    dateFormatted(date: string | null, displayHour: boolean) {
      if (!date) {
        return "";
      }
      if (displayHour) {
        return date.toDateString(this.$t("common.dateFormat.long").toString());
      } else {
        return date.toDateString(this.$t("common.dateFormat.short").toString());
      }
    },
    intToPriceString(value: number): string {
      return PriceHelper.intToPriceString(value);
    },
  },
});
