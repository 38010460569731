import { Store } from "vuex";
import AuthService from "@/data/services/auth-service";
import UserService from "@/data/services/user-service";
import StorageService from "@/data/services/storage-service";
import OrderService from "@/data/services/order-service";
import ProductService from "@/data/services/product-service";
import EmailService from "../services/email-services";
import ConfigService from "../services/config-service";
import FileStorageService from "../services/file-storage-service";

export const services = {
  auth: AuthService,
  user: UserService,
  order: OrderService,
  product: ProductService,
  storage: StorageService,
  email: EmailService,
  config: ConfigService,
  fileStorage: FileStorageService,
};

declare module "vue/types/vue" {
  interface Vue {
    $services: {
      auth: typeof AuthService;
      user: typeof UserService;
      order: typeof OrderService;
      product: typeof ProductService;
      storage: typeof StorageService;
      email: typeof EmailService;
      config: typeof ConfigService;
      fileStorage: typeof FileStorageService;
    };
    $store: Store<any>;
  }
}
