
import Vue, { PropType } from "vue";
import "@/helpers/date-helper";
import InformationDialog from "@/components/organisms/stock/InformationDialog.vue";

export default Vue.extend({
  name: "ProductTypeQuantityTable",
  components: { InformationDialog },
  props: {
    items: {
      type: Array as PropType<any[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        { text: this.$t("stock.table.title"), value: "title", width: 0 },
        { text: this.$t("stock.table.passplat"), value: "passplat" },
        { text: this.$t("stock.table.streamers"), value: "streamers" },
        { text: this.$t("stock.table.carriers"), value: "carriers" },
        { text: this.$t("stock.table.pickers"), value: "pickers" },
        { text: this.$t("stock.table.washermen"), value: "washermen" },
        { text: this.$t("stock.table.unusable"), value: "unusable" },
        { text: this.$t("stock.table.total"), value: "total" },
      ],
      informationDialogVisible: false,
    };
  },
  methods: {
    getTotal(item: any) {
      return item.passplat + item.streamers + item.carriers + item.pickers + item.washermen + item.unusable;
    },
    openInformation() {
      this.informationDialogVisible = true;
    },
  },
});
