
import Vue from "vue";
import OrdersTable from "@/components/organisms/orders/OrdersTable.vue";
import OrdersCreateUpdateDialog from "@/components/organisms/orders/OrdersCreateUpdateDialog.vue";
import Order from "@/data/models/order";
import OrderType from "@/data/enums/order-type";
import HomeStock from "@/components/organisms/home/HomeStock.vue";
import UserRole from "@/data/enums/roles";
import HomeAddProductDialog from "@/components/organisms/home/HomeAddProductDialog.vue";

export default Vue.extend({
  name: "Orders",
  components: {
    OrdersTable,
    OrdersCreateUpdateDialog,
    HomeStock,
    HomeAddProductDialog,
  },
  data() {
    return {
      orders: [] as Order[],
      showNewOrderDialog: false,
      showAddProductDialog: false,
      loading: false,
    };
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      let toId: string | null = null;

      if (!this.isAdmin()) {
        toId = this.$store.getters.currentUser?.id;
      }

      this.loading = true;

      this.$services.order
        .getOrders(OrderType.NEW_BOXES, null, [], toId)
        .then((orders) => {
          this.orders = orders;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createOrder() {
      this.showNewOrderDialog = true;
    },
    close() {
      this.showNewOrderDialog = false;
      this.getOrders();
    },
    reload() {
      (this.$refs.homeStock as any)?.reload();
      this.getOrders();
    },
    isAdmin(): boolean {
      return ((this.$store.getters.currentUser?.defaultRole as UserRole) || "") === UserRole.ADMIN;
    },
  },
});
