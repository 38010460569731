import ProductDao from "@/data/dao/product-dao";
import Product from "@/data/models/product";
import ProductStatus from "../enums/product-status";
import ProductType from "../models/product-type";

export default class ProductService {
  private static productDao = new ProductDao();

  static getProducts(status: ProductStatus | null = null, userId: string | null = null): Promise<Product[]> {
    return this.productDao.getProducts(status, userId);
  }

  static getProductTypes(status: ProductStatus = ProductStatus.AVAILABLE): Promise<ProductType[]> {
    return this.productDao.getProductTypes(status);
  }

  static getProductQuantityOnUserAndStatus(status: ProductStatus = ProductStatus.AVAILABLE, userId: string) {
    return this.productDao.getProductQuantityOnUserAndStatus(status, userId);
  }

  static getProductType(id: string): Promise<ProductType> {
    return this.productDao.getProductType(id);
  }

  static getProductsWithStatus(status: string): Promise<Product[]> {
    return this.productDao.getProductsWithStatus(status);
  }

  static addProducts(product: Product[]) {
    return this.productDao.addProducts(product);
  }

  static deleteProduct(product: Product) {
    return this.productDao.deleteProduct(product);
  }

  static addProductType(pr: ProductType) {
    return this.productDao.addProductType(pr);
  }

  static deleteProductType(id: number) {
    return this.productDao.deleteProductType(id);
  }

  static updateProductType(pr: ProductType) {
    return this.productDao.updateProductType(pr);
  }

  static getLostBrokenProducts() {
    return this.productDao.getLostBrokenProducts();
  }

  static getProductsForBreakage(status: ProductStatus, limit: number, productTypeId: string, userId: string) {
    return this.productDao.getProductsForBreakage(status, limit, productTypeId, userId);
  }

  static updateProductsForBreakage(ids: Array<string>, userId: string, update_date: string) {
    return this.productDao.updateProductsForBreakage(ids, userId, update_date);
  }

  static getGraphData() {
    return this.productDao.getGraphData();
  }

  static getCardsData() {
    return this.productDao.getCardsData();
  }

  static getDrawerData() {
    return this.productDao.getDrawerData();
  }

  static updateProductsStatus(limit: number, fromStatus: ProductStatus, toStatus: ProductStatus, productTypeId: string, fromUserId: string, toUserId: string | null, date: string) {
    return this.productDao.updateProductsStatus(limit, fromStatus, toStatus, productTypeId, fromUserId, toUserId, date);
  }
}
