
import Button from "@/components/atoms/Button.vue";
import ProductsCreateUpdateForm from "@/components/organisms/products/ProductsCreateUpdateDIalog.vue";
import Product from "@/data/models/product";
import PriceHelper from "@/helpers/price-helper";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ProductList",
  components: { Button, ProductsCreateUpdateForm },
  props: {
    items: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showCreateEditDialog: false,
      item: null as any,
      headers: [
        {
          text: this.$t("products.productsTable.headers.title"),
          value: "title",
        },
        {
          text: `${this.$t("products.consignmentPrice").toString()} ${this.$t("common.exclVAT").toString()}`,
          value: "consignmentPrice",
        },
        {
          text: `${this.$t("products.consignmentPrice").toString()} ${this.$t("common.vat").toString()}`,
          value: "consignmentPriceVAT",
        },
        {
          text: `${this.$t("products.servicePrice").toString()} ${this.$t("common.exclVAT").toString()}`,
          value: "servicePrice",
        },
        {
          text: `${this.$t("products.servicePrice").toString()} ${this.$t("common.vat").toString()}`,
          value: "servicePriceVAT",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    openDialog(item: any | null) {
      this.item = item || null;
      this.showCreateEditDialog = true;
    },
    intToPriceString(value: number): string {
      return PriceHelper.intToPriceString(value);
    },
    reload(){
      this.$emit("reload");
    }
  },
});
