import Vue from "vue";
import VueApollo from "vue-apollo";
import { ApolloClient, DefaultOptions } from "apollo-client";
import { createHttpLink, HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { services } from "@/data/di";

Vue.use(VueApollo);

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_HASURA_GRAPHQL_URL,
} as HttpLink.Options);

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "content-type": "application/json",
      "authorization": `Bearer ${services.auth.session?.accessToken}`
    },
  };
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

export default apolloProvider;
