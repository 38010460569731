import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route } from "vue-router";
import privateRoutes from "./routes/private";
import publicRoutes from "./routes/public";
import store from "@/store";
import AuthService from "@/data/services/auth-service";
import Meta from "vue-meta";
import  { UserRoleHelper } from "@/data/enums/roles";

Vue.use(VueRouter);
Vue.use(Meta);

const handleRoute = (to: Route, _from: Route, next: NavigationGuardNext) => {
  if (to.meta?.isSecured && !AuthService.isSignedIn) {
    next({ path: "/auth", replace: true });
  } else if (to.path === "/auth" && AuthService.isSignedIn) {
    next({ path: "/", replace: true });
  } else {
    const role = AuthService.currentUserRole;
    const disallowedRoutes = UserRoleHelper.disallowedRoutes(role);

    if (disallowedRoutes.includes(to.path || "")) {
      next(UserRoleHelper.redirect(role));
      return;
    }

    next();
  }
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [...privateRoutes, ...publicRoutes],
});

router.beforeEach((to, from, next) => {
  if (store.getters.isApplicationReady) {
    handleRoute(to, from, next);
  } else {
    store.watch(
      (state) => state.isApplicationReady,
      (ready) => {
        if (ready) {
          handleRoute(to, from, next);
        }
      }
    );
  }
});

export default router;
