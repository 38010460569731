
import Vue from "vue";
import { Doughnut } from "vue-chartjs";

export default Vue.extend({
  name: "HomeGraph",
  extends: Doughnut,
  props: {
    bp: {
      type: String,
    },
    graphData: {
      type: Object,
    },
  },
  data() {
    return {
      labels: [] as string[],
    };
  },
  watch: {
    bp() {
      this.displayGraph();
    },
    graphData() {
      this.labels = [
        this.$tc("home.graph.caption", 0, {
          role: this.$t("home.graph.passplat"),
          boxes: this.graphData.passplat.count,
          percent: this.graphData.passplat.percentage,
        }),
        this.$tc("home.graph.caption", 0, {
          role: this.$t("home.graph.streamers"),
          boxes: this.graphData.streamers.count,
          percent: this.graphData.streamers.percentage,
        }),
        this.$tc("home.graph.caption", 0, {
          role: this.$t("home.graph.carriers"),
          boxes: this.graphData.carriers.count,
          percent: this.graphData.carriers.percentage,
        }),
        this.$tc("home.graph.caption", 0, {
          role: this.$t("home.graph.pickers"),
          boxes: this.graphData.pickers.count,
          percent: this.graphData.pickers.percentage,
        }),
        this.$tc("home.graph.caption", 0, {
          role: this.$t("home.graph.washermen"),
          boxes: this.graphData.washermen.count,
          percent: this.graphData.washermen.percentage,
        }),
        this.$tc("home.graph.caption", 0, {
          role: this.$t("home.graph.breakagesLosses"),
          boxes: this.graphData.breakagesLosses.count,
          percent: this.graphData.breakagesLosses.percentage,
        }),
      ];
      this.displayGraph();
    },
  },
  methods: {
    displayGraph() {
      (this as any).renderChart(
        {
          labels: this.labels,
          datasets: [
            {
              data: [
                this.graphData.passplat.count,
                this.graphData.streamers.count,
                this.graphData.carriers.count,
                this.graphData.pickers.count,
                this.graphData.washermen.count,
                this.graphData.breakagesLosses.count,
              ],
              borderColor: "#36495d",
              backgroundColor: ["rgb(164, 196, 0)", "rgb(247, 157, 24)", "rgb(231, 76, 60)", "rgb(74, 91, 242)", "rgb(44, 195, 216)", "rgb(0, 0, 0)"],
              borderWidth: 0,
            },
          ],
        },
        {
          cutoutPercentage: 60,
          responsive: true,
          maintainAspectRatio: false,
          lineTension: 1,
          legend: {
            position: "bottom",
            labels: {
              usePointStyle: true,
              fontSize: 16,
              fontFamily: "Oswald",
            },
          },
          layout: {
            padding: 30,
          },
        }
      );
    },
  },
});
