import { HasuraAuthClient, SignUpResponse, ApiResetPasswordResponse, ApiChangePasswordResponse, User, Session } from "@nhost/hasura-auth-js";

const hasuraAuth = new HasuraAuthClient({
  url: process.env.VUE_APP_HASURA_AUTH_URL || "",
  autoRefreshToken: true
});

export default class AuthDao {
  get isSignedIn(): boolean {
    return hasuraAuth.isAuthenticated();
  }

  get isSignedInAsync(): Promise<boolean> {
    return hasuraAuth.isAuthenticatedAsync();
  }

  get currentAuthUser(): User | null {
    return hasuraAuth.getUser();
  }

  get session(): Session | null {
    return hasuraAuth.getSession();
  }

  onAuthStateChanged(callback: () => void): void {
    hasuraAuth.onAuthStateChanged(() => {
      callback();
    });
  }

  signIn(email: string, password: string): Promise<any> {
    return hasuraAuth
      .signIn({
        email: email,
        password: password,
      })
      .then((result) => {
        if (result.error !== null) {
          return Promise.reject(result.error);
        }

        return Promise.resolve(result);
      });
  }

  signUp(email: string, password: string): Promise<SignUpResponse> {
    return hasuraAuth
      .signUp({
        email: email,
        password: password,
      })
      .then((result) => {
        if (result.error !== null) {
          return Promise.reject(result.error);
        }

        return Promise.resolve(result);
      });
  }

  resetPassword(email: string): Promise<ApiResetPasswordResponse> {
    return hasuraAuth
      .resetPassword({
        email: email,
      })
      .then((result) => {
        if (result.error === null) {
          return Promise.resolve(result);
        }

        return Promise.reject(result.error);
      });
  }

  signOut(): Promise<void> {
    return hasuraAuth.signOut().then((result: any) => {
      if (result.error === null) {
        return Promise.resolve();
      }

      return Promise.reject(result.error);
    });
  }

  changePassword(password: string): Promise<ApiChangePasswordResponse> {
    return hasuraAuth
      .changePassword({
        newPassword: password,
      })
      .then((result) => {
        if (result.error === null) {
          return Promise.resolve(result);
        }

        return Promise.reject(result.error);
      });
  }
}
