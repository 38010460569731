enum ProductStatus {
    AVAILABLE = "AVAILABLE",
    NEW_WAITING_FOR_COLLECT = "NEW_WAITING_FOR_COLLECT",
    NEW_IN_DELIVERY = "NEW_IN_DELIVERY",
    IN_CIRCULATION = "IN_CIRCULATION",
    DIRTY_WAITING_FOR_COLLECT = "DIRTY_WAITING_FOR_COLLECT",
    DIRTY_IN_DELIVERY = "DIRTY_IN_DELIVERY",
    IN_WASHING = "IN_WASHING",
    CLEAN_WAITING_FOR_COLLECT = "CLEAN_WAITING_FOR_COLLECT",
    CLEAN_IN_DELIVERY = "CLEAN_IN_DELIVERY",
    UNUSABLE = "UNUSABLE"
}

export default ProductStatus;