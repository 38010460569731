import BaseModel from "@/data/models/base/base-model";
import TypeUtils from "@/helpers/type-utils";
import ProductStatus from "../enums/product-status";
import UserRole from "../enums/roles";
import UserAddress from "./user-address";
import UserDetails from "./user-details";

export default class User extends BaseModel {
  createdAt: string | null = null;
  displayName: string | null = null;
  locale: string | null = null;
  email: string | null = null;
  emailVerified: boolean | null = null;
  isAnonymous: boolean | null = null;
  defaultRole: UserRole | null = null;
  phoneNumber: string | null = null;
  details: UserDetails | null = null;
  address: UserAddress | null = null;

  static map(data: Partial<User> | null): User {
    const object: User = new User();

    object.id = TypeUtils.hasStringOrDefault(data?.id);
    object.createdAt = TypeUtils.hasStringOrDefault(data?.createdAt);
    object.displayName = TypeUtils.hasStringOrDefault(data?.displayName);
    object.locale = TypeUtils.hasStringOrDefault(data?.locale);
    object.email = TypeUtils.hasStringOrDefault(data?.email);
    object.isAnonymous = TypeUtils.hasBooleanOrDefault(data?.isAnonymous);
    object.defaultRole = TypeUtils.hasStringOrDefault(data?.defaultRole) as UserRole | null;
    object.phoneNumber = TypeUtils.hasStringOrDefault(data?.phoneNumber);
    object.details = data?.details ? UserDetails.map(data.details) : null;
    object.emailVerified = TypeUtils.hasBooleanOrDefault(data?.emailVerified);
    object.address = data?.address ? UserAddress.map(data.address) : null;
    return object;
  }

  get fullName(): string | null {
    if (!this.details?.firstName || !this.details?.lastName) {
      return null;
    }

    return `${this.details?.firstName} ${this.details?.lastName}`;
  }

  get json(): any {
    return JSON.parse(JSON.stringify(this));
  }

  get copy(): any {
    return User.map(this.json);
  }

  get sourceProductStatus(): ProductStatus {
    switch (this.defaultRole) {
      case UserRole.STREAMER:
        return ProductStatus.IN_CIRCULATION;
      case UserRole.PICKER:
        return ProductStatus.IN_CIRCULATION;
      case UserRole.CARRIER:
        return ProductStatus.IN_CIRCULATION;
      case UserRole.ADMIN:
        return ProductStatus.AVAILABLE;
      case UserRole.WASHERMAN:
        return ProductStatus.IN_WASHING;
      case UserRole.CLD:
        return ProductStatus.IN_CIRCULATION;
      default:
        return ProductStatus.AVAILABLE;
    }
  }

  removeAvatar() {
    if (this.details?.avatar) {
      this.details.avatar = "";
    }
  }
}
