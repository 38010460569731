
import Vue from "vue";
import UsersTable from "@/components/organisms/users/UsersTable.vue";
import UsersCreateDialog from "@/components/organisms/users/UsersCreateDialog.vue";
import DeleteUserDialog from "@/components/organisms/common/DeleteUserDialog.vue";
import User from "@/data/models/user";

export default Vue.extend({
  name: "Users",
  components: { UsersTable, UsersCreateDialog, DeleteUserDialog },
  data() {
    return {
      users: [] as User[],
      showCreateUserDialog: false,
      showDeleteUsersDialog: false,
      usersSelected: [] as User[],
      loading: false,
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      this.loading = true;

      this.$services.user
        .getUsers()
        .then((users) => {
          this.users = users;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteUsers(users: any[]) {
      this.usersSelected = users;
      this.showDeleteUsersDialog = true;
    },
  },
});
