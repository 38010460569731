
import Order from "@/data/models/order";
import Vue, { PropType } from "vue";
import ScheduleDialog from "../organisms/common/ScheduleDialog.vue";

export default Vue.extend({
  name: "FromToAddress",
  components: {
    ScheduleDialog,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  data() {
    return {
      showScheduleDialog: false,
    };
  },
});
