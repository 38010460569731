
import Vue from "vue";
import Order from "@/data/models/order";
import OrderType from "@/data/enums/order-type";
import WashesTable from "@/components/organisms/washes/WashesTable.vue";
import ReturnsTable from "@/components/organisms/returns/ReturnsTable.vue";
import OrderStatus from "@/data/enums/order-status";
import OrdersCreateUpdateDialog from "@/components/organisms/orders/OrdersCreateUpdateDialog.vue";

export default Vue.extend({
  name: "Washes",
  components: { WashesTable, ReturnsTable, OrdersCreateUpdateDialog },
  data() {
    return {
      deposits: [] as Order[],
      collectRequests: [] as Order[],
      showCreateReturnDialog: false,
      loadingDeposits: false,
      loadingCollectRequests: false,
    };
  },
  mounted() {
    this.getDeposits();
    this.getCollectRequests();
  },
  methods: {
    getDeposits() {
      this.loadingDeposits = true;

      this.$services.order
        .getOrders(OrderType.DIRTY_BOXES, null, [OrderStatus.CARRIAGE_ACCEPTED, OrderStatus.COLLECTED, OrderStatus.DELIVERED])
        .then((orders) => {
          this.deposits = orders;
        })
        .finally(() => {
          this.loadingDeposits = false;
        });
    },
    getCollectRequests() {
      this.loadingCollectRequests = true;

      this.$services.order
        .getOrders(OrderType.CLEAN_BOXES)
        .then((orders) => {
          this.collectRequests = orders;
        })
        .finally(() => {
          this.loadingCollectRequests = false;
        });
    },
    close() {
      this.showCreateReturnDialog = false;
      this.getDeposits();
      this.getCollectRequests();
    },
  },
});
