import BaseModel from "@/data/models/base/base-model";
import TypeUtils from "@/helpers/type-utils";
import ProductDisplay from "@/data/models/product-display";
import User from "./user";
import OrderStatus from "../enums/order-status";
import OrderType from "../enums/order-type";
import store from "@/store";
import UserRole from "../enums/roles";
import ProductStatus from "../enums/product-status";

export default class Order extends BaseModel {
  from: User | null = null;
  to: User | null = null;
  carrier: User | null = null;
  fromAddress: string | null = null;
  toAddress: string | null = null;
  orderedAt: string | null = null;
  wishDate: string | null = null;
  status: OrderStatus | null = null;
  products: ProductDisplay[] = [];
  deliveryComment: string | null = null;
  type: OrderType | null = null;

  static map(data: any): Order {
    const object: Order = new Order();

    object.id = TypeUtils.hasStringOrDefault(data?.id);
    object.from = data?.from ? User.map(data.from) : null;
    object.to = data?.to ? User.map(data.to) : null;
    object.carrier = data?.carrier ? User.map(data.carrier) : null;
    object.fromAddress = TypeUtils.hasStringOrDefault(data?.fromAddress);
    object.toAddress = TypeUtils.hasStringOrDefault(data?.toAddress);
    object.orderedAt = TypeUtils.hasStringOrDefault(data?.orderedAt);
    object.wishDate = TypeUtils.hasStringOrDefault(data?.wishDate);
    object.status = data?.status ? (data.status as OrderStatus) : null;
    object.products = data?.orders_has_products ? data.orders_has_products.map((p: any) => ProductDisplay.map(p)) : [];
    object.deliveryComment = TypeUtils.hasStringOrDefault(data?.deliveryComment);
    object.type = data?.type ? (data.type as OrderType) : null;

    return object;
  }

  get subtotal(): number {
    if (this.products.length === 0) {
      return 0;
    }

    return this.products.map((p) => p.total).reduce((a, b) => a + b);
  }

  get subtotalTaxable(): number {
    if (this.products.length === 0) {
      return 0;
    }

    return this.products.map((p) => (p.servicePrice || 0) * (p.quantity || 0)).reduce((a, b) => a + b);
  }

  get consignmentPrice(): number {
    if (this.products.length === 0) {
      return 0;
    }

    return this.products.map((p) => (p.consignmentPrice || 0) * (p.quantity || 0)).reduce((a, b) => a + b);
  }

  get vat(): number {
    return this.subtotalTaxable * 0.2;
  }

  get total(): number {
    return this.subtotal + this.vat;
  }

  get json(): any {
    return JSON.parse(JSON.stringify(this));
  }

  get customAddress(): boolean {
    return this.toAddress !== this.to?.address?.label;
  }

  get cancellable(): boolean {
    return this.status === OrderStatus.WAITING_CARRIER || this.status === OrderStatus.WAITING_CARRIER_ACCEPTATION;
  }

  get editable(): boolean {
    return this.status === OrderStatus.WAITING_CARRIER || this.status === OrderStatus.WAITING_CARRIER_ACCEPTATION;
  }

  get isDirtyBoxes(): boolean {
    return this.type === OrderType.DIRTY_BOXES;
  }

  get currentUserIsCarrier(): boolean {
    return this.carrier?.id === store.getters.currentUser?.id;
  }

  get currentUserCanCorrectProducts(): boolean {
    const currentUserIsAdmin = store.getters.currentUser.defaultRole === UserRole.ADMIN;
    const currentUserIsCarrier = store.getters.currentUser.defaultRole === UserRole.CARRIER;
    const currentUserIsWasherman = store.getters.currentUser.defaultRole === UserRole.WASHERMAN;

    const newBoxes =
      (currentUserIsCarrier || currentUserIsAdmin || this.to?.id === store.getters.currentUser?.id) && this.status === OrderStatus.CARRIAGE_ACCEPTED && this.type === OrderType.NEW_BOXES;
    const dirtyBoxes = (currentUserIsWasherman || currentUserIsAdmin || this.to?.id === store.getters.currentUser?.id) && this.status === OrderStatus.DELIVERED && this.type === OrderType.DIRTY_BOXES;

    return newBoxes || dirtyBoxes;
  }

  get currentUserIsFrom(): boolean {
    return this.carrier?.id === store.getters.currentUser?.id;
  }

  get currentUserIsTo(): boolean {
    return this.carrier?.id === store.getters.currentUser?.id;
  }

  /**
   * Une Order concerne aussi bien les commandes de nouveaux produits, que les demandes de reprise de produits sales ou propres.
   * De ce fait, les fromId, toId, fromAddress et toAddress peuvent être soient la personne qui a initié la commande,
   * soit celle qui la reçoit.
   *
   * Par exemple, lorsqu'un diffuseur initie une commande des produits, le from est PASSPLAT, alors que le to est le diffuseur.
   * Lorsqu'un collecteur initie une demande de reprise de produits sales, le from est le collecteur lui-même, tandis que le to est le laveur.
   *
   * Ce getter nous permet, dans l'UI, de savoir qui afficher en premier en fonction de type de la commande.
   */
  get reversed(): boolean {
    return this.type === OrderType.DIRTY_BOXES || this.type === OrderType.CLEAN_BOXES;
  }

  get allowedActions(): string[] {
    const currentUserRole = store.getters.currentUser.defaultRole as UserRole;
    const actions: string[] = [];

    const currentUserIsAdmin = currentUserRole === UserRole.ADMIN;
    const currentUserIsOrderCarrier = this.carrier?.id === store.getters.currentUser?.id;
    const currentUserIsFrom = this.from?.id === store.getters.currentUser?.id;
    const currentUserIsTo = this.to?.id === store.getters.currentUser?.id;

    if (this.status === OrderStatus.WAITING_CARRIER) {
      if (
        currentUserIsAdmin ||
        (this.type === OrderType.NEW_BOXES && currentUserIsTo) ||
        (this.type === OrderType.DIRTY_BOXES && currentUserIsFrom) ||
        (this.type === OrderType.CLEAN_BOXES && currentUserIsFrom)
      ) {
        actions.push("edit_order");
        actions.push("cancel_order");
      }

      if (currentUserIsAdmin) {
        actions.push("set_carrier");
      } else {
        actions.push("waiting_carrier");
      }
    } else if (this.status === OrderStatus.WAITING_CARRIER_ACCEPTATION) {
      if (
        currentUserIsAdmin ||
        (this.type === OrderType.NEW_BOXES && currentUserIsTo) ||
        (this.type === OrderType.DIRTY_BOXES && currentUserIsFrom) ||
        (this.type === OrderType.CLEAN_BOXES && currentUserIsFrom)
      ) {
        actions.push("edit_order");
        actions.push("cancel_order");
      }

      if (currentUserIsOrderCarrier) {
        actions.push("accept_carriage");
        actions.push("refuse_carriage");
      } else if (currentUserIsAdmin) {
        actions.push("cancel_carriage_request");
      } else {
        actions.push("carriage_requested");
      }
    } else if (this.status === OrderStatus.CARRIAGE_ACCEPTED) {
      if (currentUserIsOrderCarrier) {
        actions.push("collect");

        if (currentUserIsAdmin && this.type !== OrderType.NEW_BOXES) {
          actions.push("correct");
        }
      } else if ((currentUserIsFrom || currentUserIsAdmin) && (this.type === OrderType.DIRTY_BOXES || this.type === OrderType.CLEAN_BOXES)) {
        actions.push("correct");
      } else {
        actions.push("carriage_accepted");
      }
      if (currentUserIsFrom && (this.type === OrderType.DIRTY_BOXES || this.type === OrderType.CLEAN_BOXES)) {
        actions.push("carriage_accepted");
      }
    } else if (this.status === OrderStatus.COLLECTED) {
      if (currentUserIsTo || currentUserIsAdmin) {
        actions.push("receive");

        if (currentUserIsAdmin && this.type !== OrderType.NEW_BOXES) {
          actions.push("correct");
        }
      } else if (currentUserIsOrderCarrier || currentUserIsAdmin) {
        actions.push("correct");
      } else {
        actions.push("collected");
      }
    } else if (this.status === OrderStatus.DELIVERED) {
      actions.push("delivered");
    } else if (this.status === OrderStatus.CANCELED) {
      actions.push("canceled");
    }

    return actions;
  }

  getProductsNextStepPayload(
    quantity = 0,
    oldOrder: Order
  ): {
    fromStatus: ProductStatus;
    toStatus: ProductStatus;
    fromId: string;
    toId: string;
  } | null {
    if (!this.status) {
      return null;
    }

    if (this.type === OrderType.NEW_BOXES) {
      if ([OrderStatus.WAITING_CARRIER].includes(this.status)) {
        return {
          fromStatus: ProductStatus.NEW_WAITING_FOR_COLLECT,
          toStatus: ProductStatus.AVAILABLE,
          fromId: oldOrder.carrier?.id || "",
          toId: "",
        };
      }

      if ([OrderStatus.WAITING_CARRIER_ACCEPTATION].includes(this.status)) {
        if (quantity < 0) {
          return {
            fromStatus: ProductStatus.NEW_WAITING_FOR_COLLECT,
            toStatus: ProductStatus.AVAILABLE,
            fromId: this.carrier?.id || "",
            toId: "",
          };
        } else {
          return {
            fromStatus: ProductStatus.AVAILABLE,
            toStatus: ProductStatus.NEW_WAITING_FOR_COLLECT,
            fromId: "",
            toId: this.carrier?.id || "",
          };
        }
      }

      if ([OrderStatus.COLLECTED].includes(this.status)) {
        if (quantity < 0) {
          return {
            fromStatus: ProductStatus.NEW_IN_DELIVERY,
            toStatus: ProductStatus.UNUSABLE,
            fromId: this.carrier?.id || "",
            toId: this.carrier?.id || "",
          };
        } else if (quantity > 0) {
          return {
            fromStatus: ProductStatus.AVAILABLE,
            toStatus: ProductStatus.NEW_IN_DELIVERY,
            fromId: "",
            toId: this.carrier?.id || "",
          };
        } else {
          return {
            fromStatus: ProductStatus.NEW_WAITING_FOR_COLLECT,
            toStatus: ProductStatus.NEW_IN_DELIVERY,
            fromId: this.carrier?.id || "",
            toId: this.carrier?.id || "",
          };
        }
      }

      if ([OrderStatus.DELIVERED].includes(this.status)) {
        return {
          fromStatus: ProductStatus.NEW_IN_DELIVERY,
          toStatus: ProductStatus.IN_CIRCULATION,
          fromId: this.carrier?.id || "",
          toId: "",
        };
      }

      if ([OrderStatus.CANCELED].includes(this.status)) {
        if (oldOrder.status === OrderStatus.WAITING_CARRIER) {
          return null;
        }

        return {
          fromStatus: ProductStatus.NEW_WAITING_FOR_COLLECT,
          toStatus: ProductStatus.AVAILABLE,
          fromId: this.carrier?.id || "",
          toId: "",
        };
      }
    } else if (this.type === OrderType.DIRTY_BOXES) {
      if ([OrderStatus.WAITING_CARRIER].includes(this.status)) {
        if (quantity < 0) {
          return {
            fromStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
            toStatus: ProductStatus.IN_CIRCULATION,
            fromId: this.from?.id || "",
            toId: "",
          };
        } else {
          if (oldOrder.status === OrderStatus.WAITING_CARRIER_ACCEPTATION) {
            return {
              fromStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
              toStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
              fromId: oldOrder.carrier?.id || "",
              toId: this.from?.id || "",
            };
          }

          return {
            fromStatus: ProductStatus.IN_CIRCULATION,
            toStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
            fromId: "",
            toId: this.from?.id || "",
          };
        }
      }

      if ([OrderStatus.WAITING_CARRIER_ACCEPTATION].includes(this.status)) {
        if (quantity < 0) {
          return {
            fromStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
            toStatus: ProductStatus.IN_CIRCULATION,
            fromId: this.carrier?.id || "",
            toId: "",
          };
        } else if (quantity > 0) {
          return {
            fromStatus: ProductStatus.IN_CIRCULATION,
            toStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
            fromId: "",
            toId: this.carrier?.id || "",
          };
        } else {
          if (oldOrder.status === OrderStatus.WAITING_CARRIER_ACCEPTATION) {
            return {
              fromStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
              toStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
              fromId: oldOrder.carrier?.id || "",
              toId: this.from?.id || "",
            };
          }

          return {
            fromStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
            toStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
            fromId: this.from?.id || "",
            toId: this.carrier?.id || "",
          };
        }
      }

      if ([OrderStatus.CARRIAGE_ACCEPTED].includes(this.status)) {
        if (quantity < 0) {
          return {
            fromStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
            toStatus: ProductStatus.IN_CIRCULATION,
            fromId: this.carrier?.id || "",
            toId: "",
          };
        } else if (quantity > 0) {
          return {
            fromStatus: ProductStatus.IN_CIRCULATION,
            toStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
            fromId: "",
            toId: this.carrier?.id || "",
          };
        } else {
          return {
            fromStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
            toStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
            fromId: this.from?.id || "",
            toId: this.carrier?.id || "",
          };
        }
      }

      if ([OrderStatus.COLLECTED].includes(this.status)) {
        if (quantity < 0) {
          return {
            fromStatus: ProductStatus.DIRTY_IN_DELIVERY,
            toStatus: ProductStatus.UNUSABLE,
            fromId: this.carrier?.id || "",
            toId: this.carrier?.id || "",
          };
        } else if (quantity > 0) {
          return {
            fromStatus: ProductStatus.IN_CIRCULATION,
            toStatus: ProductStatus.DIRTY_IN_DELIVERY,
            fromId: "",
            toId: this.carrier?.id || "",
          };
        } else {
          return {
            fromStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
            toStatus: ProductStatus.DIRTY_IN_DELIVERY,
            fromId: this.carrier?.id || "",
            toId: this.carrier?.id || "",
          };
        }
      }

      if ([OrderStatus.DELIVERED].includes(this.status)) {
        return {
          fromStatus: ProductStatus.DIRTY_IN_DELIVERY,
          toStatus: ProductStatus.IN_WASHING,
          fromId: this.carrier?.id || "",
          toId: this.to?.id || "",
        };
      }

      if ([OrderStatus.CANCELED].includes(this.status)) {
        if (oldOrder.status === OrderStatus.WAITING_CARRIER) {
          return {
            fromStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
            toStatus: ProductStatus.IN_CIRCULATION,
            fromId: this.from?.id || "",
            toId: "",
          };
        } else {
          return {
            fromStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
            toStatus: ProductStatus.IN_CIRCULATION,
            fromId: this.carrier?.id || "",
            toId: "",
          };
        }
      }
    } else if (this.type === OrderType.CLEAN_BOXES) {
      if ([OrderStatus.WAITING_CARRIER].includes(this.status)) {
        if (quantity < 0) {
          return {
            fromStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
            toStatus: ProductStatus.IN_WASHING,
            fromId: this.from?.id || "",
            toId: this.from?.id || "",
          };
        } else {
          if (oldOrder.status === OrderStatus.WAITING_CARRIER_ACCEPTATION) {
            return {
              fromStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
              toStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
              fromId: oldOrder.carrier?.id || "",
              toId: this.from?.id || "",
            };
          }

          return {
            fromStatus: ProductStatus.IN_WASHING,
            toStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
            fromId: this.from?.id || "",
            toId: this.from?.id || "",
          };
        }
      }

      if ([OrderStatus.WAITING_CARRIER_ACCEPTATION].includes(this.status)) {
        if (quantity < 0) {
          return {
            fromStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
            toStatus: ProductStatus.IN_WASHING,
            fromId: this.carrier?.id || "",
            toId: this.from?.id || "",
          };
        } else if (quantity > 0) {
          return {
            fromStatus: ProductStatus.IN_WASHING,
            toStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
            fromId: this.from?.id || "",
            toId: this.carrier?.id || "",
          };
        } else {
          if (oldOrder.status === OrderStatus.WAITING_CARRIER_ACCEPTATION) {
            return {
              fromStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
              toStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
              fromId: oldOrder.carrier?.id || "",
              toId: this.from?.id || "",
            };
          }

          return {
            fromStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
            toStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
            fromId: this.from?.id || "",
            toId: this.carrier?.id || "",
          };
        }
      }

      if ([OrderStatus.CARRIAGE_ACCEPTED].includes(this.status)) {
        if (quantity < 0) {
          return {
            fromStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
            toStatus: ProductStatus.IN_WASHING,
            fromId: this.carrier?.id || "",
            toId: this.from?.id || "",
          };
        } else if (quantity > 0) {
          return {
            fromStatus: ProductStatus.IN_WASHING,
            toStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
            fromId: this.from?.id || "",
            toId: this.carrier?.id || "",
          };
        } else {
          return {
            fromStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
            toStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
            fromId: this.from?.id || "",
            toId: this.carrier?.id || "",
          };
        }
      }

      if ([OrderStatus.COLLECTED].includes(this.status)) {
        if (quantity < 0) {
          return {
            fromStatus: ProductStatus.CLEAN_IN_DELIVERY,
            toStatus: ProductStatus.UNUSABLE,
            fromId: this.carrier?.id || "",
            toId: this.carrier?.id || "",
          };
        } else if (quantity > 0) {
          return {
            fromStatus: ProductStatus.IN_WASHING,
            toStatus: ProductStatus.CLEAN_IN_DELIVERY,
            fromId: this.from?.id || "",
            toId: this.carrier?.id || "",
          };
        } else {
          return {
            fromStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
            toStatus: ProductStatus.CLEAN_IN_DELIVERY,
            fromId: this.carrier?.id || "",
            toId: this.carrier?.id || "",
          };
        }
      }

      if ([OrderStatus.DELIVERED].includes(this.status)) {
        return {
          fromStatus: ProductStatus.CLEAN_IN_DELIVERY,
          toStatus: ProductStatus.AVAILABLE,
          fromId: this.carrier?.id || "",
          toId: "",
        };
      }

      if ([OrderStatus.CANCELED].includes(this.status)) {
        if (oldOrder.status === OrderStatus.WAITING_CARRIER) {
          return {
            fromStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
            toStatus: ProductStatus.IN_WASHING,
            fromId: this.from?.id || "",
            toId: this.from?.id || "",
          };
        } else {
          return {
            fromStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
            toStatus: ProductStatus.IN_WASHING,
            fromId: this.carrier?.id || "",
            toId: this.from?.id || "",
          };
        }
      }
    }

    return null;
  }

  getProductsLastStepPayload(): {
    fromStatus: ProductStatus;
    toStatus: ProductStatus;
    fromId: string;
    toId: string;
  } | null {
    if (!this.status) {
      return null;
    }

    if (this.type === OrderType.NEW_BOXES) {
      if ([OrderStatus.WAITING_CARRIER_ACCEPTATION].includes(this.status)) {
        return {
          fromStatus: ProductStatus.NEW_WAITING_FOR_COLLECT,
          toStatus: ProductStatus.AVAILABLE,
          fromId: this.carrier?.id || "",
          toId: "",
        };
      }
    } else if (this.type === OrderType.DIRTY_BOXES) {
      if ([OrderStatus.WAITING_CARRIER].includes(this.status)) {
        return {
          fromStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
          toStatus: ProductStatus.IN_CIRCULATION,
          fromId: "",
          toId: "",
        };
      }

      if ([OrderStatus.WAITING_CARRIER_ACCEPTATION].includes(this.status)) {
        return {
          fromStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
          toStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
          fromId: this.from?.id || "",
          toId: this.carrier?.id || "",
        };
      }

      if ([OrderStatus.CARRIAGE_ACCEPTED].includes(this.status)) {
        return {
          fromStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
          toStatus: ProductStatus.IN_CIRCULATION,
          fromId: this.carrier?.id || "",
          toId: "",
        };
      }

      if ([OrderStatus.COLLECTED].includes(this.status)) {
        return {
          fromStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
          toStatus: ProductStatus.DIRTY_IN_DELIVERY,
          fromId: this.carrier?.id || "",
          toId: this.carrier?.id || "",
        };
      }

      if ([OrderStatus.DELIVERED].includes(this.status)) {
        return {
          fromStatus: ProductStatus.IN_WASHING,
          toStatus: ProductStatus.IN_WASHING,
          fromId: this.carrier?.id || "",
          toId: this.to?.id || "",
        };
      }

      if ([OrderStatus.CANCELED].includes(this.status)) {
        return {
          fromStatus: ProductStatus.DIRTY_WAITING_FOR_COLLECT,
          toStatus: ProductStatus.IN_CIRCULATION,
          fromId: this.from?.id || "",
          toId: "",
        };
      }
    } else if (this.type === OrderType.CLEAN_BOXES) {
      if ([OrderStatus.WAITING_CARRIER].includes(this.status)) {
        return {
          fromStatus: ProductStatus.IN_WASHING,
          toStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
          fromId: this.from?.id || "",
          toId: this.from?.id || "",
        };
      }

      if ([OrderStatus.WAITING_CARRIER_ACCEPTATION].includes(this.status)) {
        return {
          fromStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
          toStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
          fromId: this.from?.id || "",
          toId: this.carrier?.id || "",
        };
      }

      if ([OrderStatus.COLLECTED].includes(this.status)) {
        return {
          fromStatus: ProductStatus.CLEAN_WAITING_FOR_COLLECT,
          toStatus: ProductStatus.CLEAN_IN_DELIVERY,
          fromId: this.carrier?.id || "",
          toId: this.carrier?.id || "",
        };
      }

      if ([OrderStatus.DELIVERED].includes(this.status)) {
        return {
          fromStatus: ProductStatus.CLEAN_IN_DELIVERY,
          toStatus: ProductStatus.AVAILABLE,
          fromId: this.carrier?.id || "",
          toId: this.to?.id || "",
        };
      }
    }

    return null;
  }

  removeAllAvatars() {
    this.from?.removeAvatar();
    this.to?.removeAvatar();
    this.carrier?.removeAvatar();
  }
}
