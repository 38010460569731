
import Vue from "vue";

export default Vue.extend({
  name: "HomeStockCard",
  props: {
    items: {
      type: [],
      default: () => [],
    },
  },
  methods: {
    seeAll() {
      this.$router.push("/stock");
    },
  },
});
