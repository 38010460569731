
import User from "@/data/models/user";
import Vue from "vue";
import ScheduleDialog from "../organisms/common/ScheduleDialog.vue";

export default Vue.extend({
  name: "LoadingCompany",
  props: {
    firstId: {
      type: String,
      default: () => "",
    },
    secondId: {
      type: String,
      default: () => "",
    },
    showCompany: {
      type: Boolean,
      default: true,
    },
    showAddress: {
      type: Boolean,
      default: false,
    },
    reversed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    text(): string {
      const company = this.first?.details?.company || "?";
      const address = this.first?.address?.label || "?";

      let finalString = "";

      if (this.showCompany) {
        finalString += company;
      }

      if (this.showAddress) {
        if (this.showCompany) {
          finalString += "\n";
        }

        finalString += address;
      }

      return finalString;
    },
  },
  data() {
    return {
      first: null as User | null,
      second: null as User | null,
      loading: false,
      showScheduleDialog: false,
    };
  },
  mounted() {
    this.getFirst();
    this.getSecond();
  },
  methods: {
    getFirst() {
      if (!this.firstId || this.firstId.length === 0) {
        return;
      }

      this.loading = true;

      this.$services.user
        .getUser(this.firstId)
        .then((user) => {
          this.first = user;
        })
        .finally(() => (this.loading = false));
    },
    getSecond() {
      if (this.secondId.length === 0) {
        return;
      }

      this.loading = true;

      this.$services.user
        .getUser(this.secondId)
        .then((user) => {
          this.second = user;
        })
        .finally(() => (this.loading = false));
    },
  },
  components: { ScheduleDialog },
});
