
import Vue from "vue";

export default Vue.extend({
  name: "ForgotPasswordDialog",
  data() {
    return {
      loading: false,
      passwordDialog: "",
      confirmPasswordDialog: "",
      valid: false,
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    async onSubmit(handleSubmit: Function, validate: any) {
      handleSubmit();
      const valid = await validate();

      if (!valid) {
        return;
      }

      this.loading = true;

      if (this.passwordDialog === this.confirmPasswordDialog) {
        this.$services.auth
          .changePassword(this.passwordDialog)
          .then(() => {
            this.$notify(this.$t("reset.successLabel").toString(), { color: "primary" });
          })
          .catch(() => {
            this.$notify(this.$t("common.error").toString(), { color: "error" });
          })
          .finally(() => {
            this.close();
            this.loading = false;
          });
      } else {
        this.$notify(this.$t("reset.errorNotSamePassword").toString(), { color: "error" });
      }
    },
    close() {
      (this.$refs.observer as any)?.reset();
      this.passwordDialog = "";
      this.confirmPasswordDialog = "";
      this.$emit("close");
    },
  },
});
