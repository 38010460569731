import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/i18n";
import apolloProvider from "@/plugins/apollo";
import "@/plugins/dayjs";
import { services } from "@/data/di";
import "@/plugins/vue-meta";
import "@/plugins/vue-clipboard2";
import "@/plugins/vee-validate";
import "@/scss/index.scss";
import VueCurrencyInput from "vue-currency-input";
import ViewContainer from "@/components/atoms/ViewContainer.vue";
import Button from "@/components/atoms/Button.vue";
import Input from "@/components/atoms/Input.vue";
import TextArea from "@/components/atoms/TextArea.vue";
import FileInput from "@/components/atoms/FileInput.vue";
import Select from "@/components/atoms/Select.vue";
import SelectAutoComplete from "@/components/atoms/SelectAutoComplete.vue";
import AddressAutoComplete from "@/components/atoms/AddressAutoComplete.vue";
import Dialog from "@/components/molecules/Dialog.vue";
import Header from "@/components/molecules/Header.vue";
import PriceInput from "@/components/atoms/PriceInput.vue";
import LoadingCompany from "@/components/atoms/LoadingCompany.vue";
import SelectProducts from "@/components/organisms/common/SelectProducts.vue";

Vue.config.productionTip = false;

// Global components
Vue.component("ViewContainer", ViewContainer);
Vue.component("Button", Button);
Vue.component("Input", Input);
Vue.component("TextArea", TextArea);
Vue.component("FileInput", FileInput);
Vue.component("Select", Select);
Vue.component("Dialog", Dialog);
Vue.component("Header", Header);
Vue.component("PriceInput", PriceInput);
Vue.component("LoadingCompany", LoadingCompany)
Vue.component("SelectAutoComplete", SelectAutoComplete)
Vue.component("AddressAutoComplete", AddressAutoComplete)
Vue.component("SelectProducts", SelectProducts)
Vue.component("SelectProducts", SelectProducts)

// Dependancy Injection
Vue.prototype.$services = services;

export const bus = new Vue();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(VueCurrencyInput);
