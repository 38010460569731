
import Vue, { PropType } from "vue";
import Input from "@/components/atoms/Input.vue";
import Button from "@/components/atoms/Button.vue";
import RemovableItem from "@/components/atoms/RemovableItem.vue";
import PriceHelper from "@/helpers/price-helper";
import ProductTypeWithQuantity from "@/data/models/product-type-with-quantity";
import ProductType from "@/data/models/product-type";
import OrderType from "@/data/enums/order-type";
import ProductStatus from "@/data/enums/product-status";
import UserRole from "@/data/enums/roles";
import { bus } from "@/main";

export default Vue.extend({
  name: "SelectProducts",
  components: { Input, Button, RemovableItem },
  props: {
    displayPrice: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array as PropType<ProductTypeWithQuantity[]>,
      required: true,
    },
    displayAvailableQuantity: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    orderType: {
      type: String as PropType<OrderType>,
    },
    status: {
      type: String as PropType<ProductStatus>,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
    },
  },
  data() {
    return {
      selectedProductId: null as string | null,
      quantity: null as number | null,
      products: [] as ProductType[],
      submitted: false,
      productError: false,
      quantityError: false,
      selectedProductQuantityAvailable: 0,
      loadingProducts: false,
    };
  },
  watch: {
    selectedProductId() {
      this.productError = false;
    },
    quantity() {
      this.quantityError = false;
    },
    userId: {
      handler() {
        this.getProducts();
      },
      immediate: true,
    },
    status: {
      handler() {
        this.getProducts();
      },
      immediate: true,
    },
  },
  computed: {
    numberOfAddedProducts(): number {
      return this.value
        .filter((p) => p.productType?.id === this.selectedProductId)
        .reduce((acc, p) => {
          return acc + (p.quantity || 0);
        }, 0);
    },
  },
  created() {
    bus.$on("updateProductsEvent", () => {
      this.getProducts();
    });
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      let _status: ProductStatus;
      const currentUserRole = this.$store.getters.currentUser?.defaultRole;

      if (!this.status) {
        if (this.orderType === OrderType.DIRTY_BOXES || currentUserRole === UserRole.STREAMER || currentUserRole === UserRole.CLD) {
          _status = ProductStatus.IN_CIRCULATION;
        } else if (this.orderType === OrderType.CLEAN_BOXES) {
          _status = ProductStatus.IN_WASHING;
        } else {
          _status = ProductStatus.AVAILABLE;
        }
      } else {
        _status = this.status;
      }

      this.loadingProducts = true;

      let productsVisibleByUser = [] as any[];

      this.$services.product
        .getProductTypes(_status)
        .then(async (productTypes) => {
          this.products = productTypes;

          if (this.orderType === OrderType.CLEAN_BOXES && this.userId) {
            productsVisibleByUser = await this.$services.product.getProducts(_status, this.userId);
            this.products.forEach((pt) => {
              const product = productsVisibleByUser.find((p) => p.productRefId === pt.id);
              const quantity = pt.quantity?.find((q) => q.status === _status);

              if (quantity) {
                if (product) {
                  quantity.count = productsVisibleByUser.length;
                } else {
                  quantity.count = 0;
                }
              }
            });
          } else {
            productsVisibleByUser = this.products;
          }

          this.updateProductQuantity();
        })
        .finally(() => {
          this.loadingProducts = false;
        });
    },
    add() {
      if (this.selectedProductId === null) {
        this.productError = true;
      }

      if (this.quantity === null) {
        this.quantityError = true;
      }

      if (this.productError || this.quantityError) {
        return;
      }

      if ((!this.orderType || this.orderType === OrderType.DIRTY_BOXES || this.orderType === OrderType.CLEAN_BOXES) && (this.quantity || 0) > this.selectedProductQuantityAvailable && this.userId) {
        return;
      }

      let newValue = this.value;
      const found = newValue.find((element: ProductTypeWithQuantity) => element.productType?.id === this.selectedProductId);
      if (found?.quantity) {
        found.quantity = Number(found.quantity) + Number(this.quantity);
      } else {
        newValue = [
          ...this.value,
          ProductTypeWithQuantity.map({
            productType: this.products.find((p) => p.id === this.selectedProductId)?.json,
            quantity: this.quantity,
          }),
        ];
      }
      this.updateProductQuantity();
      this.$emit("input", newValue);

      this.selectedProductId = null;
      this.quantity = null;

      this.productError = false;
      this.quantityError = false;
    },
    updateProductQuantity() {
      this.$nextTick(() => {
        const quantity = (this.products.find((element: any) => element.id === this.selectedProductId) as any)?.quantity;

        const count = quantity && quantity.length > 0 ? quantity[0].count : 0;
        const addedProduct = this.value.find((p) => p.productType?.id === this.selectedProductId);

        this.selectedProductQuantityAvailable = count - (addedProduct?.quantity || 0);

        if (this.selectedProductQuantityAvailable < 0) {
          this.selectedProductQuantityAvailable = 0;
        }
      });
    },
    getItemTitle(item: ProductTypeWithQuantity): string {
      if (!item || item.productType === null) {
        return "";
      }

      return `${item.quantity} x ${item.productType?.title}`;
    },
    getItemPrice(item: ProductTypeWithQuantity): string | null {
      if (!item || item.productType === null) {
        return null;
      }

      if (this.displayPrice) {
        const price = (item.productType?.consignmentPrice || 0) + (item.productType?.servicePrice || 0);
        return `${this.intToPriceString(price)} ${this.$t("common.exclVAT")} / u`;
      }

      return null;
    },
    deleteItem(index: number) {
      const newValue = Object.assign([], this.value);
      newValue.splice(index, 1);

      this.$emit("input", newValue);
      this.updateProductQuantity();
    },
    reset() {
      this.$emit("input", []);
      this.selectedProductId = null;
      this.quantity = null;
      this.productError = false;
      this.quantityError = false;
    },
    intToPriceString(value: number): string {
      return PriceHelper.intToPriceString(value);
    },
    checkIfEmpty(input: any) {
      if (input === "") {
        this.quantity = null;
      }
    }
  },
});
