import ConfigDao from "../dao/config-dao";
import Config from "../models/config";

export default class ConfigService {
  private static configDao = new ConfigDao();

  static getConfig(): Promise<Config> {
    return this.configDao.getConfig();
  }

  static getPassplatContactEmail(): Promise<string> {
    return this.configDao.getPassplatContactEmail();
  }
}
