
import UserAddress from "@/data/models/user-address";
import Vue from "vue";

export default Vue.extend({
  name: "AddressAutoComplete",
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      search: null,
      addresses: [] as UserAddress[],
    };
  },
  watch: {
    search(val) {
      if (!val || val.length < 3) {
        this.addresses = [];
        return;
      }

      if (this.isLoading) return;

      this.isLoading = true;

      // Obtiens les adresses avec une priorité sur la ville de Tours
      fetch(`https://api-adresse.data.gouv.fr/search/?q=${val}&lat=47.394144&lon=0.68484`)
        .then((res) => res.json())
        .then((res) => {
          this.addresses = res.features.map((f: any) =>
            UserAddress.map({
              label: f.properties.label,
              latitude: f.geometry.coordinates[1],
              longitude: f.geometry.coordinates[0],
            })
          );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    content: {
      get(): any {
        return this.value;
      },
      set(newValue: any) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    updateValue(e: InputEvent) {
      this.$emit("input", e);
    },
  },
});
