
import Vue from "vue";

export default Vue.extend({
  name: "ReportBreakagesLossesDialog",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    UvcBrokenOrLostOverLast12Months: {
      type: Number,
      required: true,
    },
    UvcReturnedOverLast12Months: {
      type: Number,
      required: true,
    },
    UvcNotColllectedOverLast12Months: {
      type: Number,
      required: true,
    },
    UvcSoldOverLast12Months: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    getReturnRate() {
      if (this.UvcSoldOverLast12Months === 0) {
        return 0;
      } else {
        return Math.round(((this.UvcReturnedOverLast12Months as number) / (this.UvcSoldOverLast12Months as number)) * 100);
      }
    },
    getRefusedRate() {
      if (this.UvcSoldOverLast12Months === 0) {
        return 0;
      } else {
        return Math.round(((this.UvcBrokenOrLostOverLast12Months as number) / (this.UvcSoldOverLast12Months as number)) * 100);
      }
    },
    getRenewalRate() {
      if (this.UvcSoldOverLast12Months === 0) {
        return 0;
      } else {
        return Math.round(((((this.UvcNotColllectedOverLast12Months as number) + this.UvcBrokenOrLostOverLast12Months) as number) / (this.UvcSoldOverLast12Months as number)) * 100);
      }
    },
    getAverageNumberOfUseCycles() {
      if ((this.UvcNotColllectedOverLast12Months as number) + (this.UvcBrokenOrLostOverLast12Months as number) === 0) {
        return 0;
      } else {
        return Math.round((this.UvcSoldOverLast12Months as number) / (((this.UvcNotColllectedOverLast12Months as number) + this.UvcBrokenOrLostOverLast12Months) as number));
      }
    },
  },
});
