
import Vue from "vue";
import Order from "@/data/models/order";
import PriceHelper from "@/helpers/price-helper";
import OrderActions from "@/components/molecules/orders/OrderActions.vue";
import ProductDisplay from "@/data/models/product-display";

export default Vue.extend({
  name: "ReturnDetailsDialog",
  props: {
    orderId: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      order: null as Order | null,
      loading: false,
      loadingInvoice: false,
      invoiceUrl: null as string | null,
    };
  },
  watch: {
    visible: {
      handler() {
        this.getOrder();
      },
      immediate: true,
    },
  },
  methods: {
    getOrder() {
      this.loading = true;

      this.$services.order
        .getOrder(this.orderId)
        .then((order) => {
          this.order = order;

          if (this.order?.carrier?.id !== this.$store.getters.currentUser?.id) {
            this.getInvoice();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getInvoice() {
      this.loadingInvoice = true;

      if (!this.order) {
        return;
      }

      this.$services.order
        .getInvoice(this.order)
        .then((invoiceData) => {
          if (!invoiceData) {
            return;
          }

          const blob = new Blob([invoiceData], { type: "application/pdf" });
          const link = URL.createObjectURL(blob);

          this.invoiceUrl = link;
        })
        .catch(() => {
          this.$notify(this.$t("returns.loadingInvoiceFailed").toString(), {
            color: "error",
          });
        })
        .finally(() => {
          this.loadingInvoice = false;
        });
    },
    close() {
      this.$emit("close");
    },
    getProductString(product: ProductDisplay): string {
      return `${product.quantity} x ${product?.name}`;
    },
    dateFormatted(date: string | null) {
      if (!date) {
        return "";
      }
      return date.toDateString(this.$t("common.dateFormat.long").toString());
    },
    intToPriceString(value: number): string {
      return PriceHelper.intToPriceString(value);
    },
    seeInvoice() {
      if (this.invoiceUrl) {
        window.open(this.invoiceUrl);
      }
    },
    refresh() {
      this.$emit("refresh");
      this.getOrder();
      this.getInvoice();
    },
  },
  components: { OrderActions },
});
