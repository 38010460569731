
import Vue from "vue";
import MissionsInProgressCard from "@/components/organisms/missions/MissionsInProgressCard.vue";
import Order from "@/data/models/order";
import MissionsTable from "@/components/organisms/missions/MissionsTable.vue";
import OrderStatus from "@/data/enums/order-status";
import UserRole from "@/data/enums/roles";

export default Vue.extend({
  name: "Missions",
  components: { MissionsInProgressCard, MissionsTable },
  computed: {
    filteredInProgressMissions(): any[] {
      const start = (this.inProgressPage - 1) * 2;
      const filtered = this.inProgress.slice(start, start + 2);
      return filtered;
    },
    numberOfInProgressPages(): number {
      return Math.ceil(this.inProgress.length / 2);
    },
  },
  data() {
    return {
      requests: [] as Order[],
      inProgress: [] as Order[],
      passed: [] as Order[],
      inProgressPage: 1,
      loading: false,
    };
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      if (!this.$store.getters.currentUser) {
        return;
      }

      this.loading = true;

      let promise: Promise<Order[]>;

      if (this.$store.getters.currentUser?.defaultRole === UserRole.ADMIN) {
        promise = this.$services.order.getOrders(null, null, [OrderStatus.WAITING_CARRIER_ACCEPTATION, OrderStatus.CARRIAGE_ACCEPTED, OrderStatus.COLLECTED, OrderStatus.DELIVERED]);
      } else {
        promise = this.$services.order.getOrdersByCarrier(
          [OrderStatus.WAITING_CARRIER_ACCEPTATION, OrderStatus.CARRIAGE_ACCEPTED, OrderStatus.COLLECTED, OrderStatus.DELIVERED],
          this.$store.getters.currentUser.id || ""
        );
      }

      promise
        .then((orders) => {
          this.requests = orders.filter((o) => o.status === OrderStatus.WAITING_CARRIER_ACCEPTATION);
          this.inProgress = orders.filter((o) => o.status === OrderStatus.CARRIAGE_ACCEPTED || o.status === OrderStatus.COLLECTED);
          this.passed = orders.filter((o) => o.status === OrderStatus.DELIVERED);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
