import TypeUtils from "@/helpers/type-utils";

export default class UserAddress {
  label: string | null = null;
  latitude: number | null = null;
  longitude: number | null = null;

  static map(data: Partial<UserAddress> | null): UserAddress {
    const object: UserAddress = new UserAddress();

    object.label = TypeUtils.hasStringOrDefault(data?.label);
    object.latitude = TypeUtils.hasNumberOrDefault(data?.latitude);
    object.longitude = TypeUtils.hasNumberOrDefault(data?.longitude);
    return object;
  }

  get json(): any {
    return JSON.parse(JSON.stringify(this));
  }
}
