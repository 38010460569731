import BaseModel from "@/data/models/base/base-model";
import TypeUtils from "@/helpers/type-utils";
import Constants from "../constants";

export default class Product extends BaseModel {
  id: string | null = null;
  title: string | null = null;
  consignmentPrice: number | null = null;
  servicePrice: number | null = null;
  quantity: object | null = null;
  status: string | null = null;
  productRefId: string | null = null;
  userId: string | null = null;

  static map(data: any): Product {
    const object: Product = new Product();

    object.id = TypeUtils.hasStringOrDefault(data?.id);
    object.title = TypeUtils.hasStringOrDefault(data?.product_type?.title);
    object.consignmentPrice = TypeUtils.hasNumberOrDefault(data?.product_type?.consignment_price);
    object.servicePrice = TypeUtils.hasNumberOrDefault(data?.product_type?.service_price);
    object.quantity = TypeUtils.hasObjectOrDefault(data?.quantity);
    object.status = TypeUtils.hasStringOrDefault(data?.status);
    object.productRefId = TypeUtils.hasStringOrDefault(data?.product_type?.id);
    object.userId = TypeUtils.hasStringOrDefault(data?.user_id);

    return object;
  }

  get consignmentPriceVAT(): number {
    return 0;
  }

  get servicePriceVAT(): number {
    return (this.servicePrice || 0) * Constants.taxRateMultiplier;
  }

  get json(): any {
    return JSON.parse(JSON.stringify(this));
  }

  getQuantityWithStatus(status: string): number {
    const quantityObject = (this.quantity as any[]).find((u: any) => u.status === status);
    if (quantityObject != undefined) {
      return quantityObject.count;
    }
    return 0;
  }
}
