
import Vue from "vue";
import Dialog from "@/components/molecules/Dialog.vue";
import { User } from "@nhost/hasura-auth-js";
import UserRole from "@/data/enums/roles";
import UserActions from "@/data/enums/user-actions";

export default Vue.extend({
  name: "DeleteUserDialog",
  components: {
    Dialog,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    fromUsersList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    deleteUsers() {
      const usersIDs = (this.users as User[]).map(function(user: User) {
        return user.id;
      });

      this.loading = true;

      if (this.$services.auth.session?.user?.defaultRole === UserRole.ADMIN) {
        this.$services.user
          .deleteUsers(usersIDs)
          .then(() => {
            this.$notify(this.$t("common.deleteUserDialog.deleteSuccessfully").toString(), { color: "primary" });
          })
          .catch((err) => {
            if(err.message.includes('"products_userId_fkey" on table "products"') 
              && err.graphQLErrors[0].extensions.code === "constraint-violation"){
              this.$notify(this.$t("common.deleteUserDialog.errorUserHasBoxes").toString(), { color: "error" });
            } else {
              this.$notify(this.$t("common.error").toString(), { color: "error" });
            }
          })
          .finally(async () => {
            if (this.$services.auth.session?.user?.id != undefined && usersIDs.includes(this.$services.auth.session?.user?.id)) {
              await this.$services.auth.signOut();
              this.$router.replace("/auth");
            }
            this.close();
            this.loading = false;
          });
      } else {
        this.$services.email
          .sendAskForDeleteEmail(this.$services.auth.session?.user?.email || "")
          .then(() => {
            this.$services.user
              .setAction(this.$services.auth.session?.user?.id || "", UserActions.DELETE)
              .then(() => {
                this.$notify(this.$t("profile.emailForAskDeleteAccountSentSuccessfully").toString(), { color: "primary" });
              })
              .catch(() => {
                this.$notify(this.$t("common.error").toString(), { color: "error" });
              });
          })
          .catch(() => {
            this.$notify(this.$t("common.error").toString(), { color: "error" });
          })
          .finally(() => {
            this.close();
            this.loading = false;
          });
      }
    },
    close() {
      this.$emit("reload");
      this.$emit("close");
    },
  },
});
