
import Vue, { PropType } from "vue";
import OrderActions from "@/components/molecules/orders/OrderActions.vue";

export default Vue.extend({
  name: "WashesTable",
  props: {
    items: {
      type: Array as PropType<any[]>,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        { text: this.$t("washes.table.headers.company"), value: "company" },
        { text: this.$t("washes.table.headers.products"), value: "products" },
        { text: this.$t("washes.table.headers.quantity"), value: "quantity" },
        { text: this.$t("washes.table.headers.wishDate"), value: "wishDate" },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    dateFormatted(date: string | null) {
      if (!date) {
        return "?";
      }
      return date.toDateString(this.$t("common.dateFormat.short").toString());
    },
  },
  components: { OrderActions },
});
