
import Vue from "vue";
import Drawer from "@/components/organisms/Drawer.vue";
import { mapGetters } from "vuex";
import store from "./store";
import gql from "graphql-tag";

export default Vue.extend({
  components: { Drawer },
  name: "App",
  metaInfo() {
    const title = this.$t(this.$route.meta?.title).toString();
    const appTitle = this.$t("common.appTitle").toString();
    return {
      title: `${title} | ${appTitle}`,
    };
  },
  computed: {
    showDrawer(): boolean {
      return this.$route.meta?.showDrawer || false;
    },
    showHeader(): boolean {
      return this.$route.meta?.showHeader || false;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    ...mapGetters(["isApplicationReady"]),
  },
  data() {
    return {
      drawer: false,
      userConnected: false,
    };
  },
  async mounted() {
    await this.$services.auth.isSignedInAsync.then(() => {
      store.watch(
        () => this.$store.getters.currentUser,
        () => {
          this.$store.commit("setApplicationReady");
        }
      );
    });

    this.$services.user.observeCurrentUser();
    window.setInterval(() => {
      this.checkIfUserDeleted();
    }, 30000);
  },
  methods: {
    checkIfUserDeleted() {
      this.$services.user.getCurrentUser().catch((e: any) => {
        this.$services.auth.signOut().then(() => {
          this.$notify(this.$t("common.accountDeleted").toString(), {
            color: "error",
          });
          this.$router.replace("/auth");
        });
      });
    },
  },
});
