
import Vue from "vue";
import HomeStockCard from "@/components/molecules/home/HomeStockCard.vue";
import HomeGraph from "@/components/molecules/home/HomeGraph.vue";
import { bus } from "@/main";

export default Vue.extend({
  name: "HomeStock",
  components: { HomeStockCard, HomeGraph },
  data() {
    return {
      graphData: {},
      cardData: [],
    };
  },
  mounted() {
    this.reload();
  },
  created() {
    bus.$on("updateProductsEvent", () => {
      this.reload();
    });
  },
  methods: {
    getGraphData() {
      this.$services.product.getGraphData().then((result) => {
        this.graphData = result.data;
      });
    },
    getCardsData() {
      this.$services.product.getCardsData().then((result) => {
        this.cardData = result.data;
      });
    },
    reload() {
      this.getGraphData();
      this.getCardsData();
    },
  },
});
