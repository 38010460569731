import FileStorageDao from "../dao/file-storage-dao";

export default class FileStorageService {
  private static fileStorageDao = new FileStorageDao();

  static upload(file: File, path: string, allowedUsers: string) {
    return this.fileStorageDao.upload(file, path, allowedUsers);
  }

  static get(path: string) {
    return this.fileStorageDao.get(path);
  }
}
