
import Vue from "vue";

export default Vue.extend({
  name: "forgotPasswordDialog",
  data() {
    return {
      loading: false,
      email: this.emailProps ? this.emailProps : "",
      valid: false,
    };
  },
  watch: {
    emailProps: function(newValue: string) {
      this.email = newValue;
    },
  },
  props: {
    emailProps: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.$services.email.sendResetEmail(this.email).then(() => {
        this.$notify(this.$t("auth.passwordResetSentMessage").toString(),{ color: "primary" });
        this.loading = false;
        this.close();
      }).catch(() => {
        this.$notify(this.$t("common.error").toString(), {color: "error"});
      });
    },
    close() {
      (this.$refs.observer as any)?.reset();
      this.email = "";
      this.$emit("close");
    },
  },
});
