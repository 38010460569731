
import Vue from "vue";
import dayjs from "dayjs";
import AuthService from "@/data/services/auth-service";
import UserRole, { UserRoleHelper } from "@/data/enums/roles";
import ReportBreakagesLossesDialog from "@/components/organisms/ReportBreakagesLossesDialog.vue";
import EnvironmentalSynthesisDialog from "@/components/organisms/EnvironmentalSynthesisDialog.vue";
import { bus } from "@/main";

export default Vue.extend({
  name: "Drawer",
  components: {
    ReportBreakagesLossesDialog,
    EnvironmentalSynthesisDialog,
  },
  computed: {
    isDrawerMini: {
      get(): boolean {
        return this.$store.getters.isDrawerMini;
      },
      set() {
        null;
      },
    },
    isAdmin(): boolean {
      return ((this.$store.getters.currentUser?.defaultRole as UserRole) || "") === UserRole.ADMIN;
    },
  },
  data() {
    return {
      canToggleDrawer: false,
      links1: [
        {
          title: this.$t("drawer.links.home"),
          path: "/",
          icon: "mdi-home-outline",
        },
        {
          title: this.$t("drawer.links.users"),
          path: "/users",
          icon: "mdi-account-multiple-outline",
        },
        {
          title: this.$t("drawer.links.products"),
          path: "/products",
          icon: "mdi-archive-outline",
        },
        {
          title: this.$t("drawer.links.orders"),
          path: "/orders",
          icon: "mdi-cart-outline",
        },
        {
          title: this.$t("drawer.links.returns"),
          path: "/returns",
          icon: "mdi-arrow-u-left-top",
        },
        {
          title: this.$t("drawer.links.missions"),
          path: "/missions",
          icon: "mdi-bullseye-arrow",
        },
        {
          title: this.$t("drawer.links.washes"),
          path: "/washes",
          icon: "mdi-silverware-clean",
        },
        {
          title: this.$t("drawer.links.stock"),
          path: "/stock",
          icon: "mdi-inbox-multiple-outline",
        },
      ].filter((r) => {
        const role = AuthService.currentUserRole;
        const disallowedRoutes = UserRoleHelper.disallowedRoutes(role);

        return !disallowedRoutes.includes(r.path);
      }),
      links2: [
        {
          title: this.$t("drawer.links.profile"),
          path: "/profile",
          icon: "mdi-account-outline",
        },
        {
          title: this.$t("drawer.links.contact"),
          url: "mailto:contact@passplat.org",
          icon: "mdi-email-outline",
        },
      ],
      date: dayjs().subtract(1, 'year').format(this.$t("common.dateFormat.short").toString()),
      showBreakDialog: false,
      detailsDialogVisible: false,
      UvcBrokenOrLostOverLast12Months: 0,
      UvcNotColllectedOverLast12Months: 0,
      UvcReturnedOverLast12Months: 0,
      UvcSoldOverLast12Months: 0,
    };
  },
  mounted() {
    this.getData();
  },
  created() {
    bus.$on("updateProductsEvent", () => {
      this.getData();
    });
  },
  methods: {
    onInput(event: boolean) {
      if (!this.canToggleDrawer) {
        this.canToggleDrawer = true;
        return;
      }

      this.$store.commit("setDrawerMini", event);
    },
    didTapLink(link: any) {
      window.open(link.url, "_blank");
    },
    isCarrier() {
      return ((this.$store.getters.currentUser?.defaultRole as UserRole) || "") === UserRole.CARRIER;
    },
    redirectToHome() {
      if (window.location.pathname != "/") {
        this.$router.push("/");
      }
    },
    getData() {
      this.$services.product.getDrawerData().then((result: any) => {
        this.UvcBrokenOrLostOverLast12Months = result.data.UvcBrokenOrLostOverLast12Months;
        this.UvcNotColllectedOverLast12Months = result.data.UvcNotColllectedOverLast12Months;
        this.UvcReturnedOverLast12Months = result.data.UvcReturnedOverLast12Months;
        this.UvcSoldOverLast12Months = result.data.UvcSoldOverLast12Months;
      });
    },
  },
});
