import UserDao from "@/data/dao/user-dao";
import AuthDao from "@/data/dao/auth-dao";
import store from "@/store";
import User from "@/data/models/user";
import Schedule from "../models/schedule";
import UserRole from "../enums/roles";

export default class UserService {
  private static userDao = new UserDao();
  private static authDao = new AuthDao();

  static observeCurrentUser() {
    this.getCurrentUser();

    this.authDao.onAuthStateChanged(() => {
      this.getCurrentUser();
    });
  }

  static getCurrentUser(): Promise<User | null> {
    const currentUserId = this.authDao.currentAuthUser?.id;

    if (!currentUserId) {
      store.commit("setCurrentUser", null);
      return Promise.resolve(null);
    }

    return this.getUser(currentUserId).then((user) => {
      store.commit("setCurrentUser", user);
      return user;
    });
  }

  static getUsers(): Promise<User[]> {
    return this.userDao.getUsers();
  }

  static getUser(id: string): Promise<User | null> {
    return this.userDao.getUser(id);
  }

  static async updateUser(user: User, schedules: Schedule[]): Promise<void> {
    try {
      await this.userDao.updateUser(user);
      await this.userDao.updateUserDetails(user.id, user.details);
      await this.userDao.updateUserAddress(user.id, user.address);
      await this.userDao.createSchedules(schedules);
      return Promise.resolve();
    } catch (error) {
        console.log(error)
      return Promise.reject(error);
    }
  }

  static async createUser(user: User, schedules: Schedule[]): Promise<void> {
    try {
      await this.userDao.createUser(user,schedules);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static getUsersWithRole(roles: UserRole[]): Promise<User[]> {
    return this.userDao.getUsersWithRole(roles);
  }

  static deleteUsers(id: Array<string>) {
    return this.userDao.deleteUsers(id);
  }

  static getSchedules(userId: string): Promise<Schedule[]> {
    return this.userDao.getSchedules(userId);
  }

  static createSchedules(schedules: Schedule[]) {
    return this.userDao.createSchedules(schedules);
  }

  static deleteSchedules(schedulesIds: string[]) {
    return this.userDao.deleteSchedules(schedulesIds);
  }

  static verifyUser(){
    return this.userDao.verifyUser();
  }

  static setAction(user_id: string, action: string){
    return this.userDao.setAction(user_id, action);
  }
}
