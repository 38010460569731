
import Vue, { PropType } from "vue";
import "@/helpers/date-helper";
import UserRole, { UserRoleHelper } from "@/data/enums/roles";

export default Vue.extend({
  name: "UsersTable",
  props: {
    items: {
      type: Array as PropType<any[]>,
      required: true,
    },
    loading: {
        type: Boolean,
        default: false
    }
  },
  watch: {
    edit(newValue: boolean) {
      if (!newValue) {
        this.selectedUsers = [];
      }
    },
  },
  data() {
    return {
      search: "",
      edit: false,
      selectedUsers: [] as any[],
      headers: [
        { text: "", value: "avatar", width: 0 },
        { text: this.$t("users.company"), value: "details.company" },
        { text: this.$t("users.firstName"), value: "details.firstName" },
        { text: this.$t("users.lastName"), value: "details.lastName" },
        { text: this.$t("users.email"), value: "email" },
        { text: this.$t("users.createdAt"), value: "createdAt" },
        { text: this.$t("users.role"), value: "defaultRole" },
        { text: "", value: "actions" },
      ],
      showScheduleDialog: false,
    };
  },
  methods: {
    didTapRow(row: any) {
      if (this.edit) {
        if (this.selectedUsers.find((su) => su.id === row.id)) {
          this.selectedUsers = this.selectedUsers.filter((su) => su.id !== row.id);
        } else {
          this.selectedUsers.push(row);
        }
      } else {
        this.$router.push("/users/" + row.id);
      }
    },
    deleteUsers(userSelected: any) {
      this.edit = false;
      this.$emit("deleteUsers", userSelected === undefined ? this.selectedUsers : [userSelected]);
    },
    createdAtFormatted(date: string | null) {
      if (!date) {
        return "?";
      }

      return date.toDateString(this.$t("common.dateFormat.short").toString());
    },
    closeScheduleDialog() {
      this.showScheduleDialog = false;
    },
    getRoleTitle(role: UserRole) {
      return this.$t(UserRoleHelper.getTitleKey(role));
    },
    resendVerifyEmail(userSelected: any) {
      this.$services.email
        .sendActivateAccountEmail(userSelected.email)
        .then(() => {
          this.$notify(this.$t("users.resendVerificationLinkSuccessfully").toString(), { color: "primary" });
        })
        .catch(() => {
          this.$notify(this.$t("common.error").toString(), { color: "error" });
        });
    },
  },
});
