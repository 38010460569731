
import Vue, { PropType } from "vue";
import LoadingCompany from "@/components/atoms/LoadingCompany.vue";
import FromToAddress from "@/components/atoms/FromToAddress.vue";
import OrderActions from "@/components/molecules/orders/OrderActions.vue";
import UserRole from "@/data/enums/roles";

export default Vue.extend({
  name: "OrdersTable",
  props: {
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array as PropType<any[]>,
      required: true,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoadingCompany,
    FromToAddress,
    OrderActions,
  },
  computed: {
    isAdmin(): boolean {return ((this.$store.getters.currentUser?.defaultRole as UserRole) || "") === UserRole.ADMIN;},
  },
  data() {
    return {
      headers: [
        { text: this.$t("orders.table.headers.company"), value: "company" },
        { text: this.$t("orders.table.headers.orderedAt"), value: "orderedAt" },
        { text: this.$t("orders.table.headers.products"), value: "products" },
        { text: this.$t("orders.table.headers.quantity"), value: "quantity" },
        { text: this.$t("orders.table.headers.wishDate"), value: "wishDate" },
        { text: this.$t("orders.table.headers.address"), value: "address" },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
    if (this.isAdmin) {
      this.headers.splice(this.headers.length - 1, 0, { text: this.$t("orders.table.headers.carrier"), value: "carrier" });
    }
  },
  methods: {
    dateFormatted(date: string | null) {
      if (!date) {
        return "?";
      }

      return date.toDateString(this.$t("common.dateFormat.short").toString());
    },
    didTapRow(row: any) {
      this.$router.push("/orders/" + row.id);
    },
  },
});
