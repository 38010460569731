import gql from "graphql-tag";

export const GET_USERS = gql`
  query GetAllUsers {
    users(order_by: {displayName: asc}) {
      id
      createdAt
      displayName
      avatarUrl
      locale
      email
      isAnonymous
      emailVerified
      defaultRole
      phoneNumber
      details {
        address
        company
        description
        firstName
        lastName
        action
        avatar
        visible
      }
      address {
        label
        latitude
        longitude
      }
    }
  }
`;

export const GET_USERS_BY_ROLES = gql`
  query GetAllUsersByRoles($roles: [String!]) {
    users(where: { defaultRole: { _in: $roles } }, order_by: {displayName: asc}) {
      id
      createdAt
      displayName
      avatarUrl
      locale
      email
      isAnonymous
      defaultRole
      phoneNumber
      roles {
        role
      }
      details {
        address
        company
        description
        firstName
        lastName
        visible
      }
      address {
        label
        latitude
        longitude
      }
    }
  }
`;


export const GET_USER = gql`
  query GetUser($id: uuid!) {
    user(id: $id) {
      id
      createdAt
      displayName
      avatarUrl
      locale
      email
      isAnonymous
      defaultRole
      phoneNumber
      details {
        address
        company
        description
        firstName
        lastName
        avatar
        visible
      }
      address {
        label
        latitude
        longitude
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($id: uuid!, $displayName: String, $phoneNumber: String, $defaultRole: String) {
    updateUser(pk_columns: { id: $id }, _set: { displayName: $displayName, phoneNumber: $phoneNumber, defaultRole: $defaultRole }) {
      id
      displayName
      phoneNumber
      defaultRole
    }
  }
`;

export const UPDATE_USER_DETAILS = gql`
  mutation UpdateUserDetails($id: uuid! $company: String, $lastName: String, $firstName: String, $description: String, $avatar: String, $visible: Boolean) {
    updateUserDetails(pk_columns: { id: $id }, _set: { company: $company, lastName: $lastName, firstName: $firstName, description: $description, avatar: $avatar, visible: $visible }) {
      id
      company
      lastName
      firstName
      description
      avatar
      visible
    }
  }
`;

export const UPDATE_USER_ADDRESS = gql`
  mutation UpdateUserAddress($id: uuid!, $label: String!, $latitude: numeric!, $longitude: numeric!) {
    insertUserAddress(object: { id: $id, label: $label, latitude: $latitude, longitude: $longitude }, on_conflict: { constraint: users_address_pkey, update_columns: [label, latitude, longitude] }) {
      id
      label
      latitude
      longitude
    }
  }
`;

export const UPDATE_USER_TICKET = gql`
  mutation UpdateUserTicket($ticket: String = "", $ticketExpiresAt: timestamptz = "", $email: citext = "") {
    updateUsers(where: { email: { _eq: $email } }, _set: { ticket: $ticket, ticketExpiresAt: $ticketExpiresAt }) {
      affected_rows
      returning {
        ticket
      }
    }
  }
`;

export const DELETE_USERS = gql`
  mutation DeleteUsers($users: [uuid!] = "") {
    deleteUsers(where: { id: { _in: $users } }) {
      affected_rows
    }
  }
`;

export const CREATE_SCHEDULES = gql`
  mutation CreateSchedules($objects: [schedules_insert_input!]!) {
    insertSchedules(objects: $objects) {
      returning {
        id
        userId
        day
        time
      }
    }
  }
`;

export const GET_SCHEDULES = gql`
  query GetAllSchedules($userId: uuid_comparison_exp!) {
    schedules(where: { userId: $userId }) {
      id
      day
      time
      userId
    }
  }
`;

export const DELETE_SCHEDULES = gql`
  mutation DeleteSchedules($ids: [uuid!]!) {
    deleteSchedules(where: { id: { _in: $ids } }) {
      returning {
        id
        userId
        day
        time
      }
    }
  }
`;

export const VERIFY_USER = gql`
  mutation VerifyUser($id: uuid = "") {
    updateUser(pk_columns: { id: $id }, _set: { emailVerified: true }) {
      id
    }
  }
`;

export const SET_ACTION = gql`
  mutation SetAction($id: uuid! = "", $action: String! = "") {
    updateUserDetails(pk_columns: { id: $id }, _set: { action: $action }) {
      id
    }
  }
`;
