
import Vue from "vue";

export default Vue.extend({
  name: "NotFound",
  computed: {
    containerBackgroundColor() {
      const theme = this.$vuetify.theme.dark ? "dark" : "light";
      const color = (this.$vuetify.theme.themes[theme].primary as any).darken1;
      return `background-color: ${color}`;
    },
  },
});
