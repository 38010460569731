
import Vue from "vue";
import dayjs from "dayjs";

export default Vue.extend({
  name: "ProductBreakagesLossesList",
  components: {},
  props: {
    itemsP: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("products.breakAndLossesTable.headers.company"),
          value: "company",
        },
        {
          text: this.$t("products.breakAndLossesTable.headers.reportingDate"),
          value: "reportingDate",
        },
        {
          text: this.$t("products.breakAndLossesTable.headers.products"),
          value: "products",
        },
        {
          text: this.$t("products.breakAndLossesTable.headers.quantity"),
          value: "quantity",
        },
      ],
    };
  },
  methods: {
    getFormattedDate(item: any) {
      return dayjs(item.reportingDate).format(this.$t("common.dateFormat.long").toString());
    },
  },
});
