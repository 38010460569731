
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "Select",
  props: {
    items: {
      type: Array as PropType<any>,
      default: () => [],
    },
    value: {},
    itemText: {
      type: String,
    },
    itemValue: {},
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    prependIcon: {
      type: String,
    },
  },
  computed: {
    content: {
      get(): any {
        return this.value;
      },
      set(newValue: any) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    updateValue(e: InputEvent) {
      this.$emit("input", e);
    },
  },
});
