
import ProductWithQuantity from "@/data/models/product-with-quantity";
import PriceHelper from "@/helpers/price-helper";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "OrdersPriceDetailsDialog",
  components: {},
  props: {
    products: {
      type: Array as PropType<ProductWithQuantity[]>,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    getConsignmentPriceTotal() {
      if (this.products.length === 0) {
        return 0;
      }
      return this.products.map((p) => p.consignmentPriceTotal || 0).reduce((a, b) => a + b);
    },
    getTotalPriceOfProductsTaxable() {
      if (this.products.length === 0) {
        return 0;
      }

      return this.products.map((p) => p.servicePriceTotal || 0).reduce((a, b) => a + b);
    },
    getVAT(price: any): number {
      return 0.2 * price;
    },
    close() {
      this.$emit("close");
    },
    intToPriceString(value: number | null): string {
      if (value === null) {
        return "";
      }

      return PriceHelper.intToPriceString(value);
    },
  },
});
