
import Vue, { PropType } from "vue";
import SelectProducts from "@/components/organisms/common/SelectProducts.vue";
import OrdersPriceDetailsDialog from "@/components/organisms/orders/OrdersPriceDetailsDialog.vue";
import PriceHelper from "@/helpers/price-helper";
import Order from "@/data/models/order";
import UserRole from "@/data/enums/roles";
import User from "@/data/models/user";
import OrderStatus from "@/data/enums/order-status";
import OrderType from "@/data/enums/order-type";
import dayjs from "dayjs";

export default Vue.extend({
  name: "OrdersCreateUpdateDialog",
  components: {
    SelectProducts,
    OrdersPriceDetailsDialog,
  },
  props: {
    order: {
      type: Object as PropType<Order | null>,
      default: null,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String as PropType<OrderType>,
      required: true,
    },
  },
  watch: {
    visible: {
      handler(newValue: boolean) {
        this.getUsers();
        this.getConfig();

        if (newValue) {
          this.getInvoice();
        }
      },
      immediate: true,
    },
    order: {
      handler(newValue: Order | null) {
        this.setOrder(newValue);
      },
      immediate: true,
    },
    "form.fromId": {
      handler(newValue: string) {
        if (!newValue || !newValue.length) {
          return;
        }

        this.form.fromAddress = this.order?.fromAddress || this.selectedUser?.address?.label || "";
      },
      immediate: true,
    },
    "form.toId": {
      handler(newValue: string) {
        if (!newValue || !newValue.length || newValue === this.passplat?.id) {
          return;
        }

        this.form.toAddress = this.order?.toAddress || this.selectedUser?.address?.label || null;
      },
      immediate: true,
    },
    "form.products"() {
      this.productsError = false;
    },
    passplat: {
      handler(newValue: User | null) {
        switch (this.type) {
          case OrderType.NEW_BOXES:
            this.form.fromAddress = newValue?.address?.label || null;
            break;
          case OrderType.CLEAN_BOXES:
            this.form.toId = newValue?.id || null;
            this.form.toAddress = newValue?.address?.label || null;
            break;
          default:
            break;
        }
      },
      immediate: true,
    },
  },
  computed: {
    selectedUser(): User | null {
      const user = this.users.find((s) => s.id === (this.reverse ? this.form.fromId : this.form.toId));
      return user || null;
    },
    totalPrice(): number {
      if (this.form.products.length === 0) {
        return 0;
      }

      return this.form.products.map((p) => p.totalVAT).reduce((a, b) => a + b);
    },
    computedDateFormatted(): string | null {
      if (!this.form.wishDate || this.form.wishDate.length === 0) {
        return null;
      }

      return this.form.wishDate.toDateString(this.$t("common.dateFormat.short").toString());
    },
    customAddress(): boolean {
      return this.form.toAddress != this.selectedUser?.address?.label;
    },
    title(): string {
      switch (this.type) {
        case OrderType.NEW_BOXES:
          return this.order ? this.$t("orders.editOrderLabel").toString() : this.$t("orders.newOrderLabel").toString();
        case OrderType.DIRTY_BOXES:
        case OrderType.CLEAN_BOXES:
          return this.order ? this.$t("orders.editReturnLabel").toString() : this.$t("orders.newReturnLabel").toString();
        default:
          return "";
      }
    },
    pickerTitle(): string {
      switch (this.type) {
        case OrderType.NEW_BOXES:
          return this.$t("common.roles.streamer").toString();
        case OrderType.DIRTY_BOXES:
          return this.$t("common.roles.picker").toString();
        case OrderType.CLEAN_BOXES:
          return this.$t("common.roles.washerman").toString();
        default:
          return "";
      }
    },
    pickerPlaceholder(): string {
      switch (this.type) {
        case OrderType.NEW_BOXES:
          return this.$t("common.roles.streamer").toString();
        case OrderType.DIRTY_BOXES:
          return this.$t("common.roles.picker").toString();
        case OrderType.CLEAN_BOXES:
          return this.$t("common.roles.washerman").toString();
        default:
          return "";
      }
    },
    canEditToAddress(): boolean {
      return this.type === OrderType.NEW_BOXES;
    },
    canAddProducts(): boolean {
      if (this.type === OrderType.DIRTY_BOXES || this.type === OrderType.CLEAN_BOXES) {
        return this.selectedUser !== null;
      }

      return true;
    },
    invoiceRequired(): boolean {
      return this.type === OrderType.DIRTY_BOXES;
    },
    reverse(): boolean {
      return this.type === OrderType.DIRTY_BOXES || this.type === OrderType.CLEAN_BOXES;
    },
    showStreamer(): boolean {
      const role = this.$store.getters.currentUser.defaultRole as UserRole;
      return role === UserRole.ADMIN;
    },
    todayDate(): string {
      return dayjs().format("YYYY-MM-DD");
    },
    isANewBoxOrder() {
      return this.type === OrderType.NEW_BOXES;
    },
    isADirtyBoxOrder() {
      return this.type === OrderType.DIRTY_BOXES;
    },
  },
  data() {
    return {
      showWishDateDialog: false,
      showDetailedPriceDialog: false,
      form: {
        fromId: null as string | null,
        toId: null as string | null,
        products: [] as any[],
        wishDate: null as string | null,
        fromAddress: null as string | null,
        toAddress: null as string | null,
        deliveryComment: null as string | null,
        invoice: null as File | null,
      },
      users: [] as User[],
      passplat: null as User | null,
      productsError: false,
      loadingInvoice: false,
      invoiceUrl: null as string | null,
      loading: false,
    };
  },
  methods: {
    getUsers() {
      let roles: UserRole[] = [];

      switch (this.type) {
        case OrderType.NEW_BOXES:
          roles = [UserRole.STREAMER, UserRole.CLD];
          break;
        case OrderType.DIRTY_BOXES:
          roles = [UserRole.PICKER, UserRole.CLD];
          break;
        case OrderType.CLEAN_BOXES:
          roles = [UserRole.WASHERMAN, UserRole.CLD];
          break;
        default:
          break;
      }

      this.$services.user.getUsersWithRole(roles).then((data) => {
        this.users = data;

        const role = this.$store.getters.currentUser.defaultRole as UserRole;
        const currentUserId = this.$store.getters.currentUser.id as string;

        if (role !== UserRole.ADMIN) {
          if (this.type === OrderType.NEW_BOXES) {
            this.form.toId = currentUserId;
          } else if (this.type === OrderType.DIRTY_BOXES) {
            this.form.fromId = currentUserId;
          } else if (this.type === OrderType.CLEAN_BOXES) {
            this.form.fromId = currentUserId;
          }
        }
      });
    },
    getConfig() {
      this.$services.config.getConfig().then((config) => {
        if (!config.passplatId) {
          return;
        }

        this.$services.user.getUser(config.passplatId).then((passplat) => {
          this.passplat = passplat;
        });
      });
    },
    setOrder(order: Order | null) {
      this.form.fromId = order?.from?.id || "";
      this.form.toId = order?.to?.id || "";
      this.form.products = order?.products.map((p) => p.toProductTypesWithQuantity) || [];
      this.form.wishDate = order?.wishDate?.toDateString("YYYY-MM-DD") || "";
      this.form.fromAddress = order?.fromAddress || "";
      this.form.toAddress = order?.toAddress || "";
      this.form.deliveryComment = order?.deliveryComment || null;
      this.form.invoice = null;
    },
    getInvoice() {
      if (!this.order) {
        return;
      }

      this.loadingInvoice = true;

      this.$services.order
        .getInvoice(this.order, this.$route.name !== "Washes")
        .then((invoiceData) => {
          if (!invoiceData) {
            this.invoiceUrl = null;
            return;
          }

          const blob = new Blob([invoiceData], { type: "application/pdf" });
          const link = URL.createObjectURL(blob);

          this.invoiceUrl = link;
        })
        .finally(() => {
          this.loadingInvoice = false;
        });
    },
    close() {
      this.$emit("close");
      (this.$refs.selectProducts as any)?.reset();
      (this.$refs.observer as any)?.reset();
      this.setOrder(null);
      this.loading = false;
      this.loadingInvoice = false;
      this.getInvoice();
    },
    updateProducts(products: any[]) {
      this.form.products = products;
    },
    displayAvailableQuantity() {
      return [UserRole.ADMIN, UserRole.PICKER, UserRole.WASHERMAN].includes((this.$store.getters.currentUser?.defaultRole as UserRole) || "");
    },
    async onSubmit(handleSubmit: Function, validate: any) {
      handleSubmit();
      const valid = (await validate()) && this.form.products.length > 0;

      if (this.form.products.length === 0) {
        this.productsError = true;
      }

      if (!valid || !this.passplat?.id) {
        return;
      }

      const products: any[] = this.form.products.map((p) => {
        return {
          product_type_id: p.productType.id,
          product_name: p.productType.title,
          product_quantity: p.quantity,
          product_consignment_price: p.productType.consignmentPrice,
          product_service_price: p.productType.servicePrice,
        };
      });

      let fromId: string | null = null;
      let toId: string | null = null;
      let fromAddress: string | null = null;
      let toAddress: string | null = null;

      switch (this.type) {
        case OrderType.NEW_BOXES:
          fromId = this.passplat?.id;
          toId = this.form.toId;
          fromAddress = this.passplat?.address?.label || null;
          toAddress = this.form.toAddress;
          break;
        case OrderType.DIRTY_BOXES:
          fromId = this.form.fromId;
          toId = this.order?.to?.id || null;
          fromAddress = this.form.fromAddress;
          toAddress = this.order?.toAddress || null;
          break;
        case OrderType.CLEAN_BOXES:
          fromId = this.form.fromId;
          toId = this.passplat?.id;
          fromAddress = this.form.fromAddress;
          toAddress = this.passplat?.address?.label || null;
          break;
      }

      this.loading = true;

      if (this.order) {
        this.$services.order
          .updateOrder({
            id: this.order.id,
            fromId: fromId,
            toId: toId,
            wishDate: this.form.wishDate,
            fromAddress: fromAddress,
            toAddress: toAddress,
            status: this.order.status,
            orders_has_products: {
              data: products,
            },
            deliveryComment: this.customAddress ? this.form.deliveryComment : null,
            orderedAt: this.order.orderedAt,
            type: this.type,
            carrierId: this.order.carrier?.id || null,
          })
          .then(async (orderId: string | null) => {
            this.$notify(this.$t("orders.dialog.updateOrderSuccess").toString(), {
              color: "primary",
            });

            if (this.form.invoice && orderId) {
              try {
                await this.$services.order.uploadInvoice(this.form.invoice, orderId, [fromId, toId].join(","));

                this.$notify(this.$t("orders.dialog.uploadInvoiceSuccess").toString(), {
                  color: "primary",
                });
              } catch (error) {
                this.$notify(this.$t("orders.dialog.uploadInvoiceFailed").toString(), {
                  color: "error",
                });
              }
            }

            this.close();
          })
          .catch(() => {
            this.$notify(this.$t("orders.dialog.updateOrderFailed").toString(), {
              color: "error",
            });

            this.loading = false;
            this.loadingInvoice = false;
          });
      } else {
        this.$services.order
          .createOrder({
            fromId: fromId,
            toId: toId,
            wishDate: this.form.wishDate,
            fromAddress: fromAddress,
            toAddress: toAddress,
            status: OrderStatus.WAITING_CARRIER,
            orders_has_products: {
              data: products,
            },
            deliveryComment: this.form.deliveryComment,
            type: this.type,
          })
          .then(async (result: any) => {
            const orderId = result.data?.insertOrder?.id;
            this.$notify(this.$t("orders.dialog.createOrderSuccess").toString(), {
              color: "primary",
            });

            if (this.type === OrderType.NEW_BOXES) {
              const toEmail = result.data?.insertOrder?.to?.email;
              await this.$services.email.sendOrderConfirmation(toEmail);
            } else {
              const fromEmail = result.data?.insertOrder?.from?.email;
              await this.$services.email.sendReturnConfirmation(fromEmail);
            }

            if (this.form.invoice && orderId) {
              try {
                await this.$services.order.uploadInvoice(this.form.invoice, orderId, [fromId, toId].join(","));
                this.$notify(this.$t("orders.dialog.uploadInvoiceSuccess").toString(), {
                  color: "primary",
                });
              } catch {
                this.$notify(this.$t("orders.dialog.uploadInvoiceFailed").toString(), {
                  color: "primary",
                });
              }
            }

            this.close();
          })
          .catch(() => {
            this.$notify(this.$t("orders.dialog.createOrderFailed").toString(), {
              color: "error",
            });

            this.loading = false;
            this.loadingInvoice = false;
          });
      }
    },
    intToPriceString(value: number): string {
      return PriceHelper.intToPriceString(value);
    },
    toggleCustomAddress() {
      let toAddress: string;

      if (this.order) {
        toAddress = this.order?.to?.address?.label || "";
      } else {
        const user = this.users.find((s) => s.id === this.form.toId);
        toAddress = user?.address?.label || "";
      }

      if (this.customAddress) {
        this.form.toAddress = toAddress;
      } else {
        this.form.toAddress = "";
      }
    },
    setInvoice(file: File) {
      this.form.invoice = file;
    },
    seeInvoice() {
      if (this.invoiceUrl) {
        window.open(this.invoiceUrl);
      }
    },
  },
});
