import TypeUtils from "@/helpers/type-utils";

export default class UserDetails {
  company: string | null = null;
  description: string | null = null;
  firstName: string | null = null;
  lastName: string | null = null;
  action: string | null = null;
  avatar: string | null = null;
  visible: boolean | null = null;

  static map(data: Partial<UserDetails> | null): UserDetails {
    const object: UserDetails = new UserDetails();

    object.company = TypeUtils.hasStringOrDefault(data?.company);
    object.description = TypeUtils.hasStringOrDefault(data?.description);
    object.firstName = TypeUtils.hasStringOrDefault(data?.firstName);
    object.lastName = TypeUtils.hasStringOrDefault(data?.lastName);
    object.action = TypeUtils.hasStringOrDefault(data?.action);
    object.avatar = TypeUtils.hasStringOrDefault(data?.avatar);
    object.visible = TypeUtils.hasBooleanOrDefault(data?.visible);
    
    return object;
  }

  get json(): any {
    return JSON.parse(JSON.stringify(this));
  }
}
