
import Order from "@/data/models/order";
import Vue, { PropType } from "vue";
import OrdersCreateUpdateDialog from "@/components/organisms/orders/OrdersCreateUpdateDialog.vue";
import HomeTransportRequestDialog from "@/components/organisms/home/HomeTransportRequestDialog.vue";
import OrderStatus from "@/data/enums/order-status";
import CorrectProductsDialog from "@/components/organisms/common/CorrectProductsDialog.vue";

export enum OrderActionsType {
  ORDER = "order",
  RETURN = "return",
}

export default Vue.extend({
  name: "OrderActions",
  components: {
    OrdersCreateUpdateDialog,
    HomeTransportRequestDialog,
    CorrectProductsDialog,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      showEditOrderDialog: false,
      showCancelOrderDialog: false,
      showCarrierDialog: false,
      showAcceptDialog: false,
      showRefuseDialog: false,
      showCollectDialog: false,
      showDeliverDialog: false,
      showReceiveDialog: false,
      showCorrectProductsDialog: false,
      showCancelCarriageRequestDialog: false,
      loading: false,
    };
  },
  methods: {
    closeEditOrderDialog() {
      this.showEditOrderDialog = false;
      this.$emit("refresh");
    },
    cancerOrder() {
      if (!this.order) {
        return;
      }

      this.loading = true;

      this.$services.order
        .cancelOrder(this.order)
        .then(async () => {
          this.$notify(this.$t("orders.cancelOrderSuccess").toString(), {
            color: "primary",
          });
        })
        .catch(() => {
          this.$notify(this.$t("orders.cancelOrderFailed").toString(), {
            color: "error",
          });
        })
        .finally(() => {
          this.showCancelOrderDialog = false;
          this.$emit("refresh");
          this.loading = false;
        });
    },
    closeCarrierDialog() {
      this.showCarrierDialog = false;
      this.$emit("refresh");
    },
    closeCorrectProductDialog() {
      this.showCorrectProductsDialog = false;
      this.$emit("refresh");
    },
    acceptOrder() {
      if (!this.order.id) {
        return;
      }

      this.showAcceptDialog = false;
      this.loading = true;
      this.$services.order
        .setOrderStatus(this.order, OrderStatus.CARRIAGE_ACCEPTED)
        .then(() => this.$emit("refresh"))
        .finally(() => {
          this.loading = false;
        });
    },
    refuseOrder() {
      if (!this.order) {
        return;
      }

      this.showRefuseDialog = false;
      this.loading = true;
      this.$services.order
        .refuseOrder(this.order)
        .then(() => {
          if (this.order.id) {
            this.$services.email.sendDenyTransportRequest(this.order.id);
          }
          this.$emit("refresh");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async updateOrderStatus(status: OrderStatus) {
      if (!this.order.id) {
        return;
      }

      this.loading = true;

      try {
        await this.$services.order
          .setOrderStatus(this.order, status)
          .then(async () => {
            await this.$services.order.updateOrderProductsStatuses(this.order.id || "");

            this.$notify(this.$t("common.orderStatusUpdateSuccess").toString(), {
              color: "primary",
            });

            this.showCollectDialog = false;
            this.showDeliverDialog = false;
            this.showReceiveDialog = false;
            this.$emit("refresh");
          })
          .catch(() => {
            this.$notify(this.$t("common.orderStatusUpdateFailed").toString(), {
              color: "error",
            });
          });

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    collect() {
      this.updateOrderStatus(OrderStatus.COLLECTED);
    },
    deliver() {
      this.updateOrderStatus(OrderStatus.DELIVERED);
    },
    cancelCarriageRequest() {
      if (!this.order) {
        return;
      }
      this.loading = true;
      const carrierEmail = this.order.carrier?.email || "";
      this.$services.order
        .setOrderCarrierAndWasherman(this.order, null, undefined)
        .then(() => {
          this.$notify(this.$t("common.cancelCarriageRequestDialog.cancelSuccessfully").toString(), {
            color: "primary",
          });
          this.showCancelCarriageRequestDialog = false;
          if (carrierEmail !== "") {
            this.$services.email.sendTransportRequestCancel(carrierEmail);
          }
          this.$emit("refresh");
        })
        .catch(() => {
          this.$notify(this.$t("common.cancelCarriageRequestDialog.cancelFailed").toString(), {
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
