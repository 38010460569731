import BaseModel from "@/data/models/base/base-model";
import TypeUtils from "@/helpers/type-utils";
import Constants from "../constants";

export default class ProductType extends BaseModel {
  id: string | null = null;
  title: string | null = null;
  consignmentPrice: number | null = null;
  servicePrice: number | null = null;
  co2: number | null = null; // In grams
  quantity: any[] | null = null;

  static map(data: any): ProductType {
    const object: ProductType = new ProductType();

    object.id = TypeUtils.hasStringOrDefault(data?.id);
    object.title = TypeUtils.hasStringOrDefault(data?.title);
    object.consignmentPrice = TypeUtils.hasNumberOrDefault(data?.consignment_price);
    object.servicePrice = TypeUtils.hasNumberOrDefault(data?.service_price);
    object.co2 = TypeUtils.hasNumberOrDefault(data?.co2);
    object.quantity = TypeUtils.hasArrayOrDefault(data?.quantity);
    return object;
  }

  get consignmentPriceVAT(): number {
    return 0;
  }

  get servicePriceVAT(): number {
    return (this.servicePrice || 0) * Constants.taxRateMultiplier;
  }

  get json(): any {
    return {
      id: this.id,
      title: this.title,
      consignment_price: this.consignmentPrice,
      service_price: this.servicePrice,
      co2: this.co2,
    };
  }
}
