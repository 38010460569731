import Home from "@/views/Home.vue";
import Users from "@/views/Users.vue";
import User from "@/views/User.vue";
import Products from "@/views/Products.vue";
import Orders from "@/views/Orders.vue";
import Order from "@/views/Order.vue";
import Returns from "@/views/Returns.vue";
import Missions from "@/views/Missions.vue";
import Washes from "@/views/Washes.vue";
import Profile from "@/views/Profile.vue";
import Password from "@/views/Password.vue";
import Stock from "@/views/Stock.vue";

import { RouteConfig } from "vue-router";

const mainRouteConfig = {
  isSecured: true,
  showDrawer: true,
  showHeader: true,
};

const privateRoutes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      ...mainRouteConfig,
      title: "home.title",
    },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      ...mainRouteConfig,
      title: "users.title",
    },
  },
  {
    path: "/users/:id",
    name: "User",
    component: User,
    meta: {
      ...mainRouteConfig,
      title: "user.title",
      showHeader: false,
    },
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
    meta: {
      ...mainRouteConfig,
      title: "products.title",
    },
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    meta: {
      ...mainRouteConfig,
      title: "orders.title",
    },
  },
  {
    path: "/orders/:id",
    name: "Order",
    component: Order,
    meta: {
      ...mainRouteConfig,
      title: "order.title",
      showHeader: false,
    },
  },
  {
    path: "/returns",
    name: "Returns",
    component: Returns,
    meta: {
      ...mainRouteConfig,
      title: "returns.title",
      subtitle: "returns.subtitle",
    },
  },
  {
    path: "/returns/:id",
    name: "Return",
    component: Returns,
    meta: {
      ...mainRouteConfig,
      title: "return.title",
      subtitle: "returns.subtitle",
    },
  },
  {
    path: "/missions",
    name: "Missions",
    component: Missions,
    meta: {
      ...mainRouteConfig,
      title: "missions.title",
      subtitle: "missions.subtitle",
    },
  },
  {
    path: "/missions/:id",
    name: "Mission",
    component: Missions,
    meta: {
      ...mainRouteConfig,
      title: "missions.title",
      subtitle: "missions.subtitle",
    },
  },
  {
    path: "/washes",
    name: "Washes",
    component: Washes,
    meta: {
      ...mainRouteConfig,
      title: "washes.title",
      subtitle: "washes.subtitle",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      ...mainRouteConfig,
      title: "profile.title",
    },
  },
  {
    path: "/resetPassword",
    name: "ResetPassword",
    component: Password,
    meta: {
      isSecured: true,
      title: "reset.title",
    },
  },
  {
    path: "/stock",
    name: "Stock",
    component: Stock,
    meta: {
      ...mainRouteConfig,
      isSecured: true,
      title: "stock.title",
    },
  },
];

export default privateRoutes;
