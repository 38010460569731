import apolloProvider from "@/plugins/apollo";
import { GET_CONFIG } from "../queries/config/queries";
import Config from "../models/config";

export default class ConfigDao {
  getConfig(): Promise<Config> {
    return apolloProvider.defaultClient
      .query({
        query: GET_CONFIG,
      })
      .then((result: any) => {
        const configArray = result.data.config as any[];
        const config = new Config();

        config.passplatId = configArray.find((c) => c.key === "passplatId").value || "";

        return config;
      });
  }

  getPassplatContactEmail(): Promise<string> {
    return apolloProvider.defaultClient
      .query({
        query: GET_CONFIG,
      })
      .then((result: any) => {
        const configArray = result.data.config as any[];
        return configArray.find((c) => c.key === "passplatEmail").value || "";
      });
  }
}
