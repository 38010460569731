
import Vue from "vue";
import UserForm from "@/components/organisms/common/UserForm.vue";

export default Vue.extend({
  name: "UsersCreateDialog",
  components: { UserForm },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loadingCreate: false,
    };
  },
  methods: {
    close(reset: Function, reload = false) {
      reset();
      this.$emit("close");

      if (reload) {
        this.$emit("reload");
      }

      (this.$refs.observer as any)?.reset();
      (this.$refs.userForm as any)?.resetForm();
      (this.$refs.userForm as any)?.clearLogo();
    },
    setLoadingCreate(value: boolean) {
      this.loadingCreate = value;
    },
  },
});
