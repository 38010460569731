import OrderDao from "@/data/dao/order-dao";
import Order from "@/data/models/order";
import FileStorageDao from "@/data/dao/file-storage-dao";
import OrderStatus from "@/data/enums/order-status";
import OrderType from "@/data/enums/order-type";
import User from "@/data/models/user";
import ProductDao from "@/data/dao/product-dao";

export default class OrderService {
  private static orderDao = new OrderDao();
  private static productDao = new ProductDao();
  private static fileStorageDao = new FileStorageDao();

  static getOrders(type: OrderType | null, limit: number | null = null, statuses: OrderStatus[] = [], userId: string | null = null): Promise<Order[]> {
    return this.orderDao.getOrders(type, limit, statuses, userId);
  }

  static getOrdersByCarrier(statuses: OrderStatus[] = [], carrierId: string): Promise<Order[]> {
    return this.orderDao.getOrdersByCarrier(statuses, carrierId);
  }

  static getLastOrders(type: OrderType, statuses: OrderStatus[] = []): Promise<Order[]> {
    return this.orderDao.getOrders(type, 2, statuses);
  }

  static getOrder(id: string): Promise<Order | null> {
    return this.orderDao.getOrder(id);
  }

  static async createOrder(order: any): Promise<object | null> {
    return this.orderDao.createOrder(order).then(async (data) => {
      await this.updateOrderProductsStatuses(data?.data?.insertOrder.id || "");

      return data;
    });
  }

  static async updateOrder(order: any): Promise<string | null> {
    const oldOrder = await this.getOrder(order.id || "");

    return this.orderDao
      .updateOrder(order)
      .then(async (orderId) => {
        const newOrder = await this.getOrder(order.id || "");

        if (!oldOrder || !newOrder) {
          return orderId;
        }

        this.updateProductsStatuses(oldOrder, newOrder, true);

        return orderId;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      });
  }

  static async cancelOrder(order: Order): Promise<void> {
    await this.orderDao.cancelOrder(order);
    const newOrder = await this.getOrder(order.id || "");

    if (!newOrder) {
      throw Error("This order doesn't exist");
    }

    await this.updateProductsStatuses(order, newOrder, false);
  }

  static async setOrderCarrierAndWasherman(order: Order, carrierId: string | null = null, washerman: User | null | undefined = undefined): Promise<void> {
    try {
      const oldOrder = await this.getOrder(order.id || "");
      await this.orderDao.setOrderCarrierAndWasherman(order, carrierId, washerman);
      const newOrder = await this.getOrder(order.id || "");

      if (!oldOrder || !newOrder) {
        throw Error("This order doesn't exist");
      }

      await this.updateProductsStatuses(oldOrder, newOrder, false);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async setOrderStatus(order: Order, status: OrderStatus): Promise<void> {
    await this.orderDao.setOrderStatus(order, status);
    await this.updateOrderProductsStatuses(order.id || "");
  }

  static async refuseOrder(order: Order): Promise<void> {
    const oldOrder = await this.getOrder(order.id || "");
    await this.orderDao.refuseOrder(order);
    const newOrder = await this.getOrder(order.id || "");

    if (!oldOrder || !newOrder) {
      throw Error("This order doesn't exist");
    }

    await this.updateProductsStatuses(oldOrder, newOrder, false);
  }

  static uploadInvoice(file: File, orderId: string, allowedUsers: string): Promise<void> {
    return this.fileStorageDao.upload(file, `orders/${orderId}/invoice.pdf`, allowedUsers);
  }

  static getInvoice(order: Order, getInvoice = true): Promise<any> {
    if (!order.id || !getInvoice) {
      return Promise.resolve(null);
    }

    if (order.type === OrderType.NEW_BOXES && order.status === OrderStatus.DELIVERED) {
      return this.orderDao.getInvoice(order.id);
    } else if (order.type === OrderType.DIRTY_BOXES) {
      return this.fileStorageDao.get(`orders/${order.id}/invoice.pdf`);
    }

    return Promise.resolve(null);
  }

  static createInvoice(order: Order): Promise<any> {
    return this.orderDao.createInvoice(order);
  }

  static async updateOrderProductsStatuses(orderId: string, reset = false) {
    const order = await this.getOrder(orderId);

    if (!order) {
      throw Error("This order doesn't exist");
    }

    const promises = order.products.map((p: any) => {
      const payload = reset ? order.getProductsLastStepPayload() : order.getProductsNextStepPayload(undefined, order);
      if (!payload) {
        return;
      }

      return this.productDao.updateProductsStatus(p?.quantity || 0, payload.fromStatus, payload.toStatus, p?.productTypeId || "", payload.fromId, payload.toId, new Date().toISOString());
    });

    return Promise.all(promises);
  }

  static updateProductsStatuses(oldOrder: Order, newOrder: Order, quantitiesChanged: boolean) {
    const updatedProducts: any = {};

    newOrder.products.forEach((newProduct) => {
      const productInOrder = oldOrder.products.find((product) => product.productTypeId === newProduct.productTypeId);
      if (productInOrder && productInOrder.productTypeId && newProduct.quantity !== null && productInOrder.quantity !== null && newProduct.quantity !== productInOrder.quantity) {
        updatedProducts[productInOrder.productTypeId] = newProduct.quantity - productInOrder.quantity;
      }
    });

    const promises = newOrder.products.map((p: any) => {
      const quantity = updatedProducts[p?.productTypeId] || 0;
      let fixedQuantity = quantity > 0 ? quantity : quantity * -1;

      if (!oldOrder.status) {
        return;
      }
      
      const payload = newOrder.getProductsNextStepPayload(quantity, oldOrder);

      if (!payload) {
        return;
      }

      if (!quantitiesChanged) {
        fixedQuantity = p.quantity;
      }

      return this.productDao.updateProductsStatus(fixedQuantity, payload.fromStatus, payload.toStatus, p?.productTypeId || "", payload.fromId, payload.toId, new Date().toISOString());
    });

    return Promise.all(promises);
  }
}
