
import Vue from "vue";
import HasuraAuthErrorUtils from "@/helpers/hasura-auth-error-utils";
import ForgotPasswordDialog from "@/components/organisms/auth/ForgotPasswordDialog.vue";
import { UserRoleHelper } from "@/data/enums/roles";

export default Vue.extend({
  name: "Auth",
  components: { ForgotPasswordDialog },
  data() {
    return {
      submitted: false,
      submittedForgottenPassword: false,
      email: "",
      password: "",
      valid: false,
      loadingAuth: false,
      loadingPasswordReset: false,
      showPassword: false,
    };
  },
  computed: {
    containerBackgroundColor() {
      const primary = this.$vuetify.theme.themes["light"].primary as any;
      const color = primary ? primary.base : "white";
      return `background-color: ${color}`;
    },
    form(): Vue & { validate: () => boolean; resetValidation: () => void } {
      return this.$refs.form as Vue & {
        validate: () => boolean;
        resetValidation: () => void;
      };
    },
  },
  methods: {
    onSubmit() {
      this.loadingAuth = true;
      this.$services.auth
        .signIn(this.email, this.password)
        .then(() => {
          return this.$services.user.getCurrentUser().then((user) => this.handleNext(user));
        })
        .catch((result) => {
          this.$notify(this.$t(HasuraAuthErrorUtils.getTranslation(result)).toString(), { color: "error" });
        })
        .finally(() => {
          this.loadingAuth = false;
        });
    },
    handleNext(user: any) {
      this.$router.push(UserRoleHelper.redirect(user.defaultRole));
    },
  },
});
