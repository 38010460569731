
import Vue from "vue";

export default Vue.extend({
  name: "FileInput",
  props: {
    value: {},
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    secured: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
    },
    appendIcon: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    accept: {
      type: String,
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    onFileInput(e: InputEvent) {
      this.$emit("change", e);
    },
    clear() {
      const fileInput = this.$refs.fileInput as any;

      if (fileInput) {
        fileInput.reset();
      }
    },
  },
});
