
import Vue, { PropType } from "vue";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import RemovableItem from "@/components/atoms/RemovableItem.vue";
import User from "@/data/models/user";
import UserDetails from "@/data/models/user-details";
import UserRole, { UserRoleHelper } from "@/data/enums/roles";
import Schedule from "@/data/models/schedule";
import notEmpty from "@/helpers/not-empty";
import ResetPasswordDialog from "@/components/organisms/profile/ResetPasswordDialog.vue";
import HasuraAuthErrorUtils from "@/helpers/hasura-auth-error-utils";
import DeleteUserDialog from "./DeleteUserDialog.vue";
import AddressAutoComplete from "@/components/atoms/AddressAutoComplete.vue";
import UserAddress from "@/data/models/user-address";
import Checkbox from "@/components/atoms/Checkbox.vue";

dayjs.extend(localeData);

export default Vue.extend({
  name: "UserForm",
  props: {
    user: {
      type: Object as PropType<User | null>,
      default: () => null as User | null,
    },
    showRGPD: {
      type: Boolean,
      default: true,
    },
    loadingUser: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    user: {
      handler(newValue: User | null) {
        if (newValue) {
          this.initForm(newValue);
          this.getSchedules();
          return;
        }

        this.resetForm();
      },
      immediate: true,
    },
  },
  data() {
    return {
      showPassword: false,
      selectedDay: "monday",
      scheduleStart: "",
      scheduleEnd: "",
      initialSchedulesIds: [] as string[],
      schedules: [] as Schedule[],
      times: [] as string[],
      scheduleKey: 0,
      userForm: new User(),
      resetPasswordDialogVisible: false,
      showDeleteAccountDialog: false,
      showDataCopyDialog: false,
      selectedImage: null as File | null,
    };
  },
  computed: {
    days(): any[] {
      const ids = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

      return dayjs.weekdays(true).map((d, index) => {
        return {
          id: ids[index],
          title: d.charAt(0).toUpperCase() + d.slice(1),
        };
      });
    },
    roles(): any[] {
      return UserRoleHelper.allValues.map((r) => {
        return {
          id: r,
          title: this.$t(UserRoleHelper.getTitleKey(r)),
        };
      });
    },
    selectedDaySchedules(): Schedule[] {
      return this.schedules.filter((s) => s.day === this.selectedDay);
    },
    isAdmin() {
      return (this.$store.getters.currentUser?.defaultRole as UserRole) === UserRole.ADMIN;
    },
    userMayBeVisibleOnMap(): boolean {
      return (this.userForm.defaultRole as UserRole) === UserRole.PICKER || (this.userForm.defaultRole as UserRole) === UserRole.STREAMER || (this.userForm.defaultRole as UserRole) === UserRole.CLD;
    },
    canEditPassword(): boolean {
      if (!this.user?.id) {
        return false;
      }

      return this.$store.getters.currentUser?.id === this.user?.id;
    },
  },
  mounted() {
    this.generateTimes();
  },
  methods: {
    resetForm() {
      this.userForm = this.user || new User();

      if (!this.userForm.details) {
        this.userForm.details = new UserDetails();
      }

      if (!this.userForm.address) {
        this.userForm.address = new UserAddress();
      }
    },
    getSchedules() {
      if (!this.user || !this.user.id) {
        return;
      }

      this.$services.user.getSchedules(this.user.id).then((schedules) => {
        this.schedules = schedules;
        this.initialSchedulesIds = schedules.map((s) => s.id).filter(notEmpty);
      });
    },
    onSubmit() {
      if (!this.userForm) {
        return;
      }

      this.$emit("loading", true);

      if (this.user) {
        this.$services.user
          .updateUser(this.userForm, [])
          .then(() => {
            this.$notify(this.$t("user.update.success").toString(), { color: "primary" });

            this.saveSchedules();
          })
          .catch(() => {
            this.$notify(this.$t("user.update.error").toString(), { color: "error" });
          })
          .finally(() => {
            this.$emit("loading", false);
          });
      } else {
        this.$services.user
          .createUser(this.userForm, this.schedules)
          .then(() => {
            this.$notify(this.$t("user.create.success").toString(), { color: "primary" });
            this.$emit("done");
          })
          .catch((err) => {
            this.$notify(this.$t(HasuraAuthErrorUtils.getTranslation(err.response.data.statusCode)).toString(), { color: "error" });
          })
          .finally(() => {
            this.$emit("loading", false);
          });
      }
    },
    closeDeleteUserDialog() {
      this.showDeleteAccountDialog = false;
      if (this.isAdmin) {
        this.$router.push({ name: "Users" });
      }
    },
    async saveSchedules() {
      const addedSchedules = this.schedules.filter((s) => !this.initialSchedulesIds.includes(s.id || ""));
      const deletedSchedulesIds = this.initialSchedulesIds.filter((id) => !this.schedules.map((s) => s.id).includes(id));

      try {
        await this.$services.user.createSchedules(addedSchedules);
        await this.$services.user.deleteSchedules(deletedSchedulesIds);
      } catch (error) {
        this.$notify(this.$t("user.schedule.saveFailed").toString(), { color: "error" });
      }
    },
    async onFileChange(file: File | null) {
      if (!this.userForm.details) {
        return;
      }

      function resizeImage(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          const maxW = 150;
          const maxH = 150;
          const img = document.createElement("img");

          img.onload = function() {
            const iw = img.width;
            const ih = img.height;
            const scale = Math.min(maxW / iw, maxH / ih);
            const iwScaled = iw * scale;
            const ihScaled = ih * scale;
            canvas.width = iwScaled;
            canvas.height = ihScaled;
            context?.drawImage(img, 0, 0, iwScaled, ihScaled);
            resolve(canvas.toDataURL());
          };
          img.onerror = function(error) {
            reject(error);
          };
          img.src = URL.createObjectURL(file);
        });
      }

      if (file) {
        const base64 = await resizeImage(file);
        this.userForm.details.avatar = base64;
      } else {
        if (this.userForm.details.avatar && this.userForm.details.avatar.length > 0) {
          this.userForm.details.avatar = this.user?.details?.avatar || null;
        } else {
          this.userForm.details.avatar = "";
        }
      }
    },
    clearLogo() {
      if (!this.userForm.details) {
        return;
      }

      this.userForm.details.avatar = "";

      const logoFileInput = this.$refs.logoFileInput as any;

      if (logoFileInput) {
        logoFileInput.clear();
      }
    },
    addSchedule() {
      if (this.scheduleStart.trim().length === 0 || this.scheduleEnd.trim().length === 0) {
        return;
      }

      this.schedules.push(
        Schedule.map({
          id: Date.now().toString(),
          userId: this.user?.id,
          day: this.selectedDay,
          time: `${this.scheduleStart} - ${this.scheduleEnd}`,
        })
      );

      this.scheduleStart = "";
      this.scheduleEnd = "";
    },
    deleteSchedule(schedule: Schedule) {
      this.schedules = this.schedules.filter((s) => s.id !== schedule.id);
    },
    initForm(data: User) {
      this.userForm = data.copy;

      this.$nextTick(() => {
        this.$nextTick(() => {
          const autocomplete = this.$refs.addressAutocomplete as any;

          if (autocomplete) {
            autocomplete.addresses = [data.address];
          }
        });
      });
    },
    generateTimes() {
      const x = 5;
      const times = [];
      let tt = 0;

      for (let i = 0; tt < 24 * 60; i++) {
        const hh = Math.floor(tt / 60);
        const mm = tt % 60;
        times[i] = ("0" + hh).slice(-2) + "h" + ("0" + mm).slice(-2);
        tt = tt + x;
      }

      this.times = times;
    },
    sendRGPDEmail() {
      this.$services.user.getCurrentUser().then((user) => {
        if (user) {
          this.$services.email.sendRGPDEmail(user);
          this.$notify(this.$t("common.emailSent").toString(), { color: "primary" });
        } else {
          this.$notify(this.$t("common.error").toString(), { color: "error" });
        }
        this.showDataCopyDialog = false;
      });
    },
    sendEmailToDPO() {
      this.$services.config.getPassplatContactEmail().then((DPOemail) => {
        navigator.clipboard
          .writeText(DPOemail)
          .then(() => {
            this.$notify(this.$tc("profile.rgpd.confirmDPOEmailCopiedToClipboard", 0, { email: DPOemail }).toString(), {
              color: "primary",
            });
          })
          .catch(() => {
            this.$notify(this.$t("common.error").toString(), { color: "error" });
          });
      });
    },
  },
  components: { RemovableItem, ResetPasswordDialog, DeleteUserDialog, AddressAutoComplete, Checkbox },
});
