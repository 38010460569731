
import Vue from "vue";
import SelectProducts from "@/components/organisms/common/SelectProducts.vue";
import ProductTypeWithQuantity from "@/data/models/product-type-with-quantity";
import ProductStatus from "@/data/enums/product-status";
import { bus } from "@/main";

export default Vue.extend({
  name: "HomeAddProductDialogs",
  components: {
    SelectProducts,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      products: [] as any[],
      loading: false,
    };
  },
  methods: {
    updateProducts(products: any[]) {
      this.products = products;
    },
    async validate() {
      if (this.products.length === 0) {
        return;
      }
      const productsToInsert = [] as any;
      this.products.forEach((product: ProductTypeWithQuantity) => {
        for (let i = 0; i < (product.quantity || 0); i++) {
          productsToInsert.push({
            product_id: product?.productType?.id,
            status: ProductStatus.AVAILABLE,
            user_id: null,
          });
        }
      });

      this.loading = true;

      this.$services.product
        .addProducts(productsToInsert)
        .then(() => {
          this.$notify(this.$t("home.addProductDialog.addedSuccessfully").toString(), { color: "primary" });
          bus.$emit("updateProductsEvent");
          this.close();
        })
        .catch(() => {
          this.$notify(this.$t("common.error").toString(), { color: "error" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("close");
      this.products = [];
      (this.$refs.selectProducts as any)?.reset();
    },
  },
});
