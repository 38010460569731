import apolloProvider from "@/plugins/apollo";
import {
  GET_USERS,
  DELETE_USERS,
  GET_USER,
  UPDATE_USER,
  UPDATE_USER_DETAILS,
  CREATE_SCHEDULES,
  GET_SCHEDULES,
  DELETE_SCHEDULES,
  GET_USERS_BY_ROLES,
  VERIFY_USER,
  SET_ACTION,
  UPDATE_USER_ADDRESS,
} from "@/data/queries/users/queries";
import User from "@/data/models/user";
import UserDetails from "../models/user-details";
import Schedule from "../models/schedule";
import UserRole from "../enums/roles";
import AuthService from "../services/auth-service";
import axios from "axios";
import UserAddress from "../models/user-address";

export default class UserDao {
  getUsers(): Promise<User[]> {
    return apolloProvider.defaultClient
      .query({
        query: GET_USERS,
      })
      .then((result: any) => {
        return Promise.resolve(
          result.data.users.map((u: object) => {
            return User.map(u);
          })
        );
      });
  }

  async getUser(id: string): Promise<User | null> {
    return apolloProvider.defaultClient
      .query({
        query: GET_USER,
        variables: {
          id: id,
        },
      })
      .then((result: any) => {
        if (!result.data || !result.data.user) {
          return Promise.reject(Error(`User with id ${id} doesn't exist.`));
        }

        return Promise.resolve(User.map(result.data.user));
      });
  }

  getUsersWithRole(roles: UserRole[]): Promise<User[]> {
    return apolloProvider.defaultClient
      .query({
        query: GET_USERS_BY_ROLES,
        variables: {
          roles: roles,
        },
      })
      .then((result: any) => {
        return Promise.resolve(result.data.users);
      });
  }

  updateUser(user: User) {
    return apolloProvider.defaultClient
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          id: user.id,
          displayName: user.fullName,
          phoneNumber: user.phoneNumber,
          defaultRole: user.defaultRole,
        },
      })
      .then((result: any) => {
        return Promise.resolve(result.data.updateUser);
      });
  }

  updateUserDetails(id: string | null, details: UserDetails | null): Promise<UserDetails> {
    return apolloProvider.defaultClient
      .mutate({
        mutation: UPDATE_USER_DETAILS,
        variables: {
          id: id,
          company: details?.company,
          lastName: details?.lastName,
          firstName: details?.firstName,
          description: details?.description || "",
          avatar: details?.avatar,
          visible : details?.visible
        },
      })
      .then((result: any) => {
        return Promise.resolve(result.data.updateUserDetails);
      });
  }

  updateUserAddress(id: string | null, address: UserAddress | null): Promise<UserDetails> {
    return apolloProvider.defaultClient
      .mutate({
        mutation: UPDATE_USER_ADDRESS,
        variables: {
          id: id,
          label: address?.label,
          latitude: address?.latitude,
          longitude: address?.longitude,
        },
      })
      .then((result: any) => {
        return Promise.resolve(result.data.updateUserAddress);
      });
  }

  async createUser(user: User, schedules: Schedule[]) {
    user.displayName = `${user.details?.firstName || ""} ${user.details?.lastName || ""}`.trim();
    user.locale = "fr";
    const userJson = user.json;
    userJson.schedules=schedules;
    const result = await axios.post(process.env.VUE_APP_HASURA_BACK_URL + `auth/createAccount?` + `redirectTo=` + window.location.origin + `/resetPassword`, userJson, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (result.data != "") {
      return Promise.reject(result.data);
    }
  }

  deleteUsers(ids: Array<string>) {
    return apolloProvider.defaultClient.mutate({
      mutation: DELETE_USERS,
      variables: {
        users: ids,
      },
    });
  }

  createSchedules(schedules: Schedule[]) {
    return apolloProvider.defaultClient.mutate({
      mutation: CREATE_SCHEDULES,
      variables: {
        objects: schedules.map((s) => {
          const json = s.json;
          delete json.id;
          return json;
        }),
      },
    });
  }

  getSchedules(userId: string): Promise<Schedule[]> {
    return apolloProvider.defaultClient
      .query({
        query: GET_SCHEDULES,
        variables: {
          userId: { _eq: userId },
        },
      })
      .then((result) => {
        return result.data.schedules;
      });
  }

  deleteSchedules(schedulesIds: string[]) {
    return apolloProvider.defaultClient.mutate({
      mutation: DELETE_SCHEDULES,
      variables: {
        ids: schedulesIds,
      },
    });
  }

  verifyUser() {
    return apolloProvider.defaultClient.mutate({
      mutation: VERIFY_USER,
      variables: {
        id: AuthService.session?.user?.id,
      },
    });
  }

  setAction(userId: string, action: string) {
    return apolloProvider.defaultClient.mutate({
      mutation: SET_ACTION,
      variables: {
        id: userId,
        action: action,
      },
    });
  }
}
