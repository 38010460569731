import TypeUtils from "@/helpers/type-utils";
import Constants from "../constants";
import ProductType from "./product-type";
import ProductTypeWithQuantity from "./product-type-with-quantity";

export default class ProductDisplay {
  name: string | null = null;
  quantity: number | null = null;
  consignmentPrice: number | null = null;
  servicePrice: number | null = null;
  productTypeId: string | null = null;

  static map(data: any): ProductDisplay {
    const object: ProductDisplay = new ProductDisplay();

    object.name = TypeUtils.hasStringOrDefault(data?.product_name);
    object.quantity = TypeUtils.hasNumberOrDefault(data?.product_quantity);
    object.consignmentPrice = TypeUtils.hasNumberOrDefault(data?.product_consignment_price);
    object.servicePrice = TypeUtils.hasNumberOrDefault(data?.product_service_price);
    object.productTypeId = TypeUtils.hasStringOrDefault(data?.product_type_id);

    return object;
  }

  get consignmentPriceTotal(): number {
    return (this.consignmentPrice || 0) * (this.quantity || 0);
  }

  get servicePriceTotal(): number {
    return (this.servicePrice || 0) * (this.quantity || 0);
  }

  get total(): number {
    return this.consignmentPriceTotal + this.servicePriceTotal;
  }

  get totalVAT(): number {
    return this.servicePriceTotal * Constants.taxRateMultiplier + this.consignmentPriceTotal;
  }

  get json(): any {
    return JSON.parse(JSON.stringify(this));
  }

  get toProductTypesWithQuantity(): ProductTypeWithQuantity {
    const productTypeWithQuantity = new ProductTypeWithQuantity();

    productTypeWithQuantity.productType = ProductType.map({
      title: this.name,
      consignment_price: this.consignmentPrice,
      service_price: this.servicePrice,
      id: this.productTypeId,
    });

    productTypeWithQuantity.quantity = this.quantity;

    return productTypeWithQuantity;
  }
}
