export default class HasuraResetPasswordErrorUtils {
  static getTranslation(error: any) {
    const authError = error;
    if (authError) {
      return this.getChangePasswordTranslation(authError)
    }
    return ''
  }

  protected static getChangePasswordTranslation(error: any): string {
    switch (error.status) {
      case 400:
        if(error.message == 'Error validating request body. "email" must be a valid email.'){
          return 'errors.hasuraAuth.invalidMailFormat';
        }else if(error.message == 'No user with such email exists'){
          return 'errors.hasuraAuth.noUserExistWithThisMail';
        }
        return 'errors.hasuraAuth.passwordTooShort';
      case 404:
        return 'errors.hasuraAuth.accessToServer';
      case 500:
        return "errors.hasuraAuth.accessToServer";
      default:
        return 'errors.unknown';
    }
  }
}
