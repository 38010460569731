
import Vue from "vue";
import ProductTypeQuantityTable from "@/components/organisms/stock/ProductTypeQuantityTable.vue";
import { bus } from "@/main";

export default Vue.extend({
  name: "Stock",
  components: { ProductTypeQuantityTable },
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  created() {
    bus.$on("updateProductsEvent", () => {
      this.getData();
    });
  },
  mounted() {
    this.loading = true;
    this.getData();
  },
  methods: {
    getData() {
      this.$services.product
        .getCardsData()
        .then((data) => {
          this.items = data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
