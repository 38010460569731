import StorageDao from "../dao/storage-dao";

export default class StorageService {
  private static storageDao = new StorageDao();

  public static ACCESS_TOKEN = "access_token";
  public static REFRESH_TOKEN = "refresh_token";
  public static USER_ID = "user_id";

  static set(key: string, value: string) {
    this.storageDao.set(key, value);
  }

  static get(key: string): string | null {
    return this.storageDao.get(key);
  }

  static remove(key: string) {
    return this.storageDao.remove(key);
  }
}
