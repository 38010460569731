import TypeUtils from "@/helpers/type-utils";

export default class Config {
  passplatId: string | null = null;

  static map(data: any): Config {
    const object: Config = new Config();

    object.passplatId = TypeUtils.hasStringOrDefault(data?.passplatId);

    return object;
  }

  get json(): any {
    return JSON.parse(JSON.stringify(this));
  }
}
