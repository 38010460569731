
import Vue from "vue";
import UserForm from "@/components/organisms/common/UserForm.vue";
import Dialog from "@/components/molecules/Dialog.vue";
import User from "@/data/models/user";

export default Vue.extend({
  name: "User",
  components: { UserForm, Dialog },
  computed: {
    title(): string {
      return `${this.$t("user.title")} / ${this.user?.displayName || ""}`;
    },
  },
  data() {
    return {
      loadingSignOut: false,
      loadingSave: false,
      dialogSignOut: false,
      dialogTitle: this.$t("profile.logOutAction"),
      dialogMessage: this.$t("profile.logOutConfirmation"),
      user: null as User | null,
      loadingUser: false,
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getUser() {
      const id = this.$route.params.id;

      this.loadingUser = true;

      this.$services.user
        .getUser(id)
        .then((user) => {
          this.user = user;
        })
        .finally(() => {
          this.loadingUser = false;
        });
    },
    signOut() {
      this.loadingSignOut = true;
      this.$services.auth
        .signOut()
        .then(() => {
          this.$router.push("/auth");
        })
        .catch(() => {
          this.$notify(this.$t("profile.logOutFailed").toString(), { color: "error" });
        })
        .finally(() => {
          this.loadingSignOut = false;
        });
    },
    setLoadingSave(value: boolean) {
      this.loadingSave = value;
    },
  },
});
