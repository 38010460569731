import Product from "@/data/models/product";
import apolloProvider from "@/plugins/apollo";
import {
  ADD_PRODUCTS,
  ADD_PRODUCT_TYPE,
  DELETE_PRODUCT,
  DELETE_PRODUCT_TYPE,
  GET_PRODUCTS,
  GET_PRODUCTS_BY_STATUS_AND_USER,
  GET_PRODUCTS_FOR_BREAKAGE,
  GET_PRODUCTS_FOR_STATUS_UPDATE,
  GET_PRODUCT_QUANTITY_ON_USER_AND_STATUS,
  GET_PRODUCT_TYPE,
  GET_PRODUCT_TYPES,
  GET_PRODUCT_TYPE_QUANTITY,
  UPDATE_PRODUCTS_FOR_BREAKAGE,
  UPDATE_PRODUCTS_FOR_STATUS_UPDATE,
  UPDATE_PRODUCT_TYPE,
} from "@/data/queries/products/queries";
import ProductType from "../models/product-type";
import axios from "axios";
import ProductStatus from "../enums/product-status";

export default class ProductDao {
  getProducts(status: ProductStatus | null = null, userId: string | null = null): Promise<Product[]> {
    const variables = {
      status,
      userId,
    };

    return apolloProvider.defaultClient
      .query({
        query: status !== null && userId !== null && userId.length > 0 ? GET_PRODUCTS_BY_STATUS_AND_USER : GET_PRODUCTS,
        variables: status !== null && userId !== null ? variables : undefined,
      })
      .then((result: any) => {
        if (!result.data) {
          return [];
        }

        return result.data.products.map((p: object) => {
          return Product.map(p);
        });
      });
  }

  getProductTypes(status: ProductStatus = ProductStatus.AVAILABLE): Promise<ProductType[]> {
    return apolloProvider.defaultClient
      .query({
        query: GET_PRODUCT_TYPES,
        variables: {
          status: status,
        },
      })
      .then((result: any) => {
        return Promise.resolve(
          result.data.productTypes.map((p: object) => {
            return ProductType.map(p);
          })
        );
      });
  }

  getProductQuantityOnUserAndStatus(status: ProductStatus = ProductStatus.AVAILABLE, userId: string) {
    return apolloProvider.defaultClient
      .query({
        query: GET_PRODUCT_QUANTITY_ON_USER_AND_STATUS,
        variables: {
          status: status,
          userId: userId,
        },
      })
      .then((result: any) => {
        return result;
      });
  }

  getProductType(id: string): Promise<ProductType> {
    return apolloProvider.defaultClient
      .query({
        query: GET_PRODUCT_TYPE,
        variables: {
          id: id,
        },
      })
      .then((result: any) => {
        return ProductType.map(result.data.productType);
      });
  }

  async getProductsWithStatus(status: string): Promise<Product[]> {
    const products = await this.getProducts();
    return Promise.resolve(products.filter((product) => product.getQuantityWithStatus(status) != 0) || []);
  }

  addProducts(prod: Product[]) {
    return apolloProvider.defaultClient.mutate({
      mutation: ADD_PRODUCTS,
      variables: {
        products: prod,
      },
    });
  }

  deleteProduct(prod: Product) {
    return apolloProvider.defaultClient.mutate({
      mutation: DELETE_PRODUCT,
      variables: {
        id: prod.id,
      },
    });
  }

  addProductType(prod: ProductType) {
    return apolloProvider.defaultClient.mutate({
      mutation: ADD_PRODUCT_TYPE,
      variables: {
        title: prod.title,
        service_price: prod.servicePrice,
        consignment_price: prod.consignmentPrice,
      },
    });
  }

  deleteProductType(id: number) {
    return apolloProvider.defaultClient.mutate({
      mutation: DELETE_PRODUCT_TYPE,
      variables: {
        id: id,
      },
    });
  }

  updateProductType(prod: ProductType) {
    return apolloProvider.defaultClient.mutate({
      mutation: UPDATE_PRODUCT_TYPE,
      variables: {
        id: prod.id,
        title: prod.title,
        service_price: prod.servicePrice,
        consignment_price: prod.consignmentPrice,
      },
    });
  }

  getLostBrokenProducts(): Promise<any> {
    return axios.get(`${process.env.VUE_APP_HASURA_BACK_URL}hasura-graphql/getFormattedUnusableProducts`);
  }

  getProductsForBreakage(status: ProductStatus, limit: number, productTypeId: string, userId: string): Promise<any> {
    const variables: any = {
      status: status,
      limit: limit,
      productTypeId: productTypeId,
    };

    if (userId !== "") {
      variables.userId = userId;
    }

    return apolloProvider.defaultClient.query({
      query: GET_PRODUCTS_FOR_BREAKAGE(userId !== ""),
      variables: variables,
    });
  }

  updateProductsForBreakage(ids: Array<string>, userId: string, update_date: string) {
    return apolloProvider.defaultClient.mutate({
      mutation: UPDATE_PRODUCTS_FOR_BREAKAGE,
      variables: {
        ids: ids,
        user_id: userId,
        update_date: update_date,
      },
    });
  }

  getGraphData(): Promise<any> {
    return axios.get(`${process.env.VUE_APP_HASURA_BACK_URL}hasura-graphql/getGraphData`);
  }

  getCardsData(): Promise<any> {
    return axios.get(`${process.env.VUE_APP_HASURA_BACK_URL}hasura-graphql/getCardsData`);
  }

  getDrawerData(): Promise<any> {
    return axios.get(`${process.env.VUE_APP_HASURA_BACK_URL}hasura-graphql/getDrawerData`);
  }

  updateProductsStatus(limit: number, fromStatus: ProductStatus, toStatus: ProductStatus, productTypeId: string, fromUserId: string | null, toUserId: string | null, date: string) {
    return axios.patch(`${process.env.VUE_APP_HASURA_BACK_URL}hasura-graphql/updateProductsStatus`, {
      limit: limit,
      fromStatus: fromStatus,
      toStatus: toStatus,
      productTypeId: productTypeId,
      fromUserId: fromUserId,
      toUserId: toUserId || "",
      date: date,
    });
  }

  getProductsForStatusUpdate(status: ProductStatus, limit: number, productTypeId: string, userId: string): Promise<any> {
    return apolloProvider.defaultClient.query({
      query: GET_PRODUCTS_FOR_STATUS_UPDATE,
      variables: {
        status: status,
        limit: limit,
        productTypeId: productTypeId,
        userId: userId,
      },
    });
  }

  setProductsForStatusUpdate(status: ProductStatus, ids: Array<string>, userId: string, update_date: string) {
    return apolloProvider.defaultClient.mutate({
      mutation: UPDATE_PRODUCTS_FOR_STATUS_UPDATE,
      variables: {
        ids: ids,
        user_id: userId,
        update_date: update_date,
        status: status,
      },
    });
  }

  getProductTypeQuantity(productId: string) {
    return apolloProvider.defaultClient.query({
      query: GET_PRODUCT_TYPE_QUANTITY,
      variables: {
        productId: productId,
      },
    });
  }
}
