
import Vue from "vue";
import OrdersTable from "@/components/organisms/orders/OrdersTable.vue";
import ReturnsTable from "@/components/organisms/returns/ReturnsTable.vue";
import HomeAddProductDialog from "@/components/organisms/home/HomeAddProductDialog.vue";
import Order from "@/data/models/order";
import OrderType from "@/data/enums/order-type";
import HomeStock from "@/components/organisms/home/HomeStock.vue";

export default Vue.extend({
  name: "Home",
  components: {
    OrdersTable,
    ReturnsTable,
    HomeAddProductDialog,
    HomeStock,
  },
  data() {
    return {
      showBreakDialog: false,
      showAddProductDialog: false,
      orders: [] as Order[],
      returns: [] as Order[],
      loadingOrders: false,
      loadingReturns: false,
    };
  },
  mounted() {
    this.getLastOrders();
    this.getLastReturns();
  },
  methods: {
    getLastOrders() {
      this.loadingOrders = true;

      this.$services.order
        .getLastOrders(OrderType.NEW_BOXES)
        .then((orders) => {
          this.orders = orders;
        })
        .finally(() => {
          this.loadingOrders = false;
        });
    },
    getLastReturns() {
      this.loadingReturns = true;

      this.$services.order
        .getLastOrders(OrderType.DIRTY_BOXES)
        .then((returns) => {
          this.returns = returns;
        })
        .finally(() => {
          this.loadingReturns = false;
        });
    },
    redirectToOrders() {
      this.$router.push("/orders");
    },
    redirectToReturn() {
      this.$router.push("/returns");
    },
    reload() {
      (this.$refs.homeStock as any)?.reload();
      this.getLastOrders();
      this.getLastReturns();
    },
  },
});
