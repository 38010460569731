
import Vue from "vue";
import UserForm from "@/components/organisms/common/UserForm.vue";
import Dialog from "@/components/molecules/Dialog.vue";
import User from "@/data/models/user";

export default Vue.extend({
  name: "Profile",
  components: { UserForm, Dialog },
  data() {
    return {
      loadingSignOut: false,
      loadingSave: false,
      dialogSignOut: false,
      dialogTitle: this.$t("profile.logOutAction"),
      dialogMessage: this.$t("profile.logOutConfirmation"),
      currentUser: null as User | null,
      loadingUser: false,
    };
  },
  mounted() {
    this.getCurrentUser();
  },
  methods: {
    getCurrentUser() {
      this.loadingUser = true;

      this.$services.user
        .getCurrentUser()
        .then((user) => {
          this.currentUser = user;
        })
        .finally(() => {
          this.loadingUser = false;
        });
    },
    signOut() {
      this.loadingSignOut = true;
      this.$services.auth
        .signOut()
        .then(() => {
          this.$router.push("/auth");
        })
        .catch(() => {
          this.$notify(this.$t("profile.logOutFailed").toString(), { color: "error" });
        })
        .finally(() => {
          this.loadingSignOut = false;
        });
    },
    setLoadingSave(value: boolean) {
      this.loadingSave = value;
    },
  },
});
