
import Vue from "vue";

export default Vue.extend({
  name: "Checkbox",
  props: {
    value: {
      type: Boolean,
    },
    color: {
      type: String,
    },
    title: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    content: {
      get(): any {
        return this.value;
      },
      set(newValue: any) {
        this.$emit("input", newValue);
      },
    },
  },
});
