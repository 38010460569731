import gql from "graphql-tag";

export const GET_PRODUCTS = gql`
  query GetProducts {
    products(distinct_on: product_id) {
      id
      status
      product_type {
        id
        consignment_price
        service_price
        title
      }
      user_id
      quantity {
        count
        status
      }
    }
  }
`;

export const GET_PRODUCTS_BY_STATUS_AND_USER = gql`
  query GetProductsByStatusAndUser($status: products_status_enum, $userId: uuid!) {
    products(where: { status: { _eq: $status }, user_id: { _eq: $userId } }) {
      id
      status
      product_type {
        id
        consignment_price
        service_price
        title
      }
      user_id
      quantity {
        count
        status
      }
    }
  }
`;

export const GET_PRODUCT_TYPES = gql`
  query GetAllProductTypes($status: String = "AVAILABLE") {
    productTypes(order_by: { title: asc }) {
      id
      consignment_price
      service_price
      title
      quantity(where: { status: { _eq: $status } }) {
        status
        count
      }
    }
  }
`;
export const GET_PRODUCT_QUANTITY_ON_USER_AND_STATUS = gql`
  query GetProductQuantityOnUserAndStatus($status: products_status_enum = AVAILABLE, $userId: uuid = "759aa19d-82dd-4d48-83bc-dfcaab9c9576") {
    productsAggregate(where: { status: { _eq: $status }, user_id: { _eq: $userId } }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_PRODUCT_TYPE = gql`
  query GetProductType($id: uuid!) {
    productType(id: $id) {
      id
      consignment_price
      service_price
      title
      quantity(where: { status: { _eq: "AVAILABLE" } }) {
        count
      }
    }
  }
`;

export const ADD_PRODUCTS = gql`
  mutation AddProducts($products: [products_insert_input!]!) {
    insertProducts(objects: $products) {
      affected_rows
    }
  }
`;

export const ADD_PRODUCT_TYPE = gql`
  mutation AddProductType($title: String = "", $service_price: Int = 10, $consignment_price: Int = 10) {
    insertProductType(object: { consignment_price: $consignment_price, service_price: $service_price, title: $title }) {
      id
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: uuid = "") {
    deleteProduct(id: $id) {
      id
    }
  }
`;

export const DELETE_PRODUCT_TYPE = gql`
  mutation DeleteProductType($id: uuid = "") {
    deleteProductType(id: $id) {
      id
    }
  }
`;

export const UPDATE_PRODUCT_TYPE = gql`
  mutation UpdateProductType($id: uuid = "", $title: String = "", $service_price: Int = 10, $consignment_price: Int = 10) {
    updateProductType(pk_columns: { id: $id }, _set: { consignment_price: $consignment_price, title: $title, service_price: $service_price }) {
      id
    }
  }
`;

export const GET_PRODUCTS_FOR_BREAKAGE = (filterOnUser: boolean) => {
  return gql`
    query GetProductForBreakage($limit: Int = 10, $status: products_status_enum = AVAILABLE, $productTypeId: uuid = ""${filterOnUser ? ', $userId: uuid = ""' : ""}) {
      products(limit: $limit, where: { _and: { status: { _eq: $status }, product_type: { id: { _eq: $productTypeId } }${filterOnUser ? ", user_id: { _eq: $userId }" : ""} } }) {
        id
      }
    }
  `;
};

export const UPDATE_PRODUCTS_FOR_BREAKAGE = gql`
  mutation UpdateProductsForBreakage($ids: [uuid!] = "", $user_id: uuid = "", $update_date: timestamptz = "") {
    updateProducts(where: { id: { _in: $ids } }, _set: { status: UNUSABLE, user_id: $user_id, update_date: $update_date }) {
      affected_rows
    }
  }
`;

export const GET_PRODUCTS_FOR_STATUS_UPDATE = gql`
  query GetProductForStatusUpdate($limit: Int = 10, $status: products_status_enum = AVAILABLE, $productTypeId: uuid = "", $userId: uuid = "") {
    products(limit: $limit, where: { _and: { status: { _eq: $status }, product_type: { id: { _eq: $productTypeId } }, user_id: { _eq: $userId } } }) {
      id
    }
  }
`;

export const UPDATE_PRODUCTS_FOR_STATUS_UPDATE = gql`
  mutation UpdateProductsForStatusUpdate($ids: [uuid!] = "", $user_id: uuid = "", $update_date: timestamptz = "", $status: products_status_enum = AVAILABLE) {
    updateProducts(where: { id: { _in: $ids } }, _set: { status: $status, user_id: $user_id, update_date: $update_date }) {
      affected_rows
    }
  }
`;

export const GET_PRODUCT_TYPE_QUANTITY = gql`
  query GetProductTypeQuantity($productId: uuid = "") {
    products_quantity(where: { product_id: { _eq: $productId } }) {
      count
      status
    }
  }
`;
