
import Vue from "vue";
import ReturnsTable from "@/components/organisms/returns/ReturnsTable.vue";
import Order from "@/data/models/order";
import OrderType from "@/data/enums/order-type";
import OrdersCreateUpdateDialog from "@/components/organisms/orders/OrdersCreateUpdateDialog.vue";
import UserRole from "@/data/enums/roles";

export default Vue.extend({
  name: "Returns",
  components: { ReturnsTable, OrdersCreateUpdateDialog },
  data() {
    return {
      returns: [] as Order[],
      showCreateReturnDialog: false,
      loading: false,
    };
  },
  computed: {
    isAdmin(): boolean {
      return this.$store.getters.currentUser?.defaultRole === UserRole.ADMIN;
    },
  },
  mounted() {
    this.getReturns();
  },
  methods: {
    getReturns() {
      let toId: string | null = null;

      if (!this.isAdmin) {
        toId = this.$store.getters.currentUser?.id;
      }

      this.loading = true;

      this.$services.order
        .getOrders(OrderType.DIRTY_BOXES, null, [], toId)
        .then((orders) => {
          this.returns = orders;
        })
        .then(() => {
          this.loading = false;
        });
    },
    close() {
      this.showCreateReturnDialog = false;
      this.getReturns();
    },
  },
});
