
import Vue from "vue";
import ProductsTable from "@/components/organisms/products/ProductsTable.vue";
import ProductsBreakagesLossesTable from "@/components/organisms/products/ProductsBreakagesLossesTable.vue";
import ProductType from "@/data/models/product-type";

export default Vue.extend({
  name: "Products",
  components: { ProductsTable, ProductsBreakagesLossesTable },
  data() {
    return {
      products: [] as ProductType[],
      productsLost: [] as any[],
      productsLoading: false,
      productsLostLoading: false,
    };
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      this.productsLoading = true;

      this.$services.product
        .getProductTypes()
        .then((products) => {
          this.products = products;
        })
        .finally(() => {
          this.productsLoading = false;
        });

      this.productsLostLoading = true;

      this.$services.product
        .getLostBrokenProducts()
        .then((products) => {
          this.productsLost = products.data;
        })
        .finally(() => {
          this.productsLostLoading = false;
        });
    },
  },
});
