
import Vue, { PropType } from "vue";
import LoadingCompany from "@/components/atoms/LoadingCompany.vue";
import Order from "@/data/models/order";
import OrderStatus from "@/data/enums/order-status";
import OrderActions from "@/components/molecules/orders/OrderActions.vue";
import UserRole from "@/data/enums/roles";

export default Vue.extend({
  name: "MissionsInProgressCard",
  data(){
    return {
      expanded: false
    }  
  },
  props: {
    mission: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  computed: {
    actionTitle(): string | null {
      switch (this.mission.status) {
        case OrderStatus.CARRIAGE_ACCEPTED:
          return this.$t("missions.actions.collected").toString();
        case OrderStatus.COLLECTED:
          return this.$t("missions.actions.delivery").toString();
        default:
          return null;
      }
    },
    actionIcon(): string | null {
      switch (this.mission.status) {
        case OrderStatus.CARRIAGE_ACCEPTED:
          return "mdi-hand-coin-outline";
        case OrderStatus.COLLECTED:
          return "mdi-truck-outline";
        default:
          return null;
      }
    },
    isAdmin(): boolean {return ((this.$store.getters.currentUser?.defaultRole as UserRole) || "") === UserRole.ADMIN;},
  },
  methods: {
    dateFormatted(date: string | null) {
      if (!date) {
        return "?";
      }

      return date.toDateString(this.$t("common.dateFormat.short").toString());
    },
    expand(){
      this.expanded = !this.expanded;
    }
  },
  components: { LoadingCompany, OrderActions },
});
