import dayjs from "dayjs";

declare global {
  interface String {
    toDateString(format: string): string;
  }
}

String.prototype.toDateString = function(this: string, format: string) {
  return dayjs(this).format(format);
};
