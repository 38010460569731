import AuthDao from "@/data/dao/auth-dao";
import { ApiResetPasswordResponse, ApiChangePasswordResponse, Session } from "@nhost/hasura-auth-js";
import StorageDao from "@/data/dao/storage-dao";
import StorageService from "./storage-service";
import UserRole from "../enums/roles";
import store from "@/store";

export default class AuthService {
  private static authDao = new AuthDao();
  private static storageDao = new StorageDao();

  public static get isSignedIn(): boolean {
    return this.authDao.isSignedIn;
  }

  public static get isSignedInAsync(): Promise<boolean> {
    return this.authDao.isSignedInAsync;
  }

  public static get session(): Session | null {
    return this.authDao.session;
  }

  public static get currentUserRole(): UserRole {
    return store.getters.currentUser?.defaultRole || UserRole.NOT_CONNECTED;
  }

  static signIn(email: string, password: string): Promise<void> {
    return this.authDao.signIn(email, password).then((result) => {
      if (result && result.session) {
        this.storageDao.set(StorageService.ACCESS_TOKEN, result.session.accessToken);
        this.storageDao.set(StorageService.REFRESH_TOKEN, result.session.refreshToken);

        if (result.session.user) {
          this.storageDao.set(StorageService.USER_ID, result.session.user.id);
        }
      }
    });
  }

  static signUp(email: string, password: string): Promise<void> {
    return this.authDao.signUp(email, password).then((result) => {
      if (result && result.session) {
        this.storageDao.set(StorageService.ACCESS_TOKEN, result.session.accessToken);
        this.storageDao.set(StorageService.REFRESH_TOKEN, result.session.refreshToken);

        if (result.session.user) {
          this.storageDao.set(StorageService.USER_ID, result.session.user.id);
        }
      }
    });
  }

  static resetPassword(email: string): Promise<ApiResetPasswordResponse> {
    return this.authDao.resetPassword(email);
  }

  static signOut(): Promise<void> {
    return this.authDao.signOut().then(() => {
      this.storageDao.remove(StorageService.ACCESS_TOKEN);
      this.storageDao.remove(StorageService.REFRESH_TOKEN);
      this.storageDao.remove(StorageService.USER_ID);
    });
  }

  static changePassword(newPassword: string): Promise<ApiChangePasswordResponse> {
    return this.authDao.changePassword(newPassword);
  }
}
