import axios from "axios";
import User from "../models/user";

export default class EmailService {
    public static sendResetEmail(email: string) {
        return axios.post(`${process.env.VUE_APP_HASURA_BACK_URL}email/resetPasswordMail?&email=${email}&redirectTo=${window.location.origin}/resetPassword`);
    }

    public static sendActivateAccountEmail(email: string) {
        const json = JSON.parse('{"email" : "'+email+'"}');
        return axios.post(`${process.env.VUE_APP_HASURA_BACK_URL}email/activateAccountMail?&redirectTo=${window.location.origin}/resetPassword`,json,
        {
            headers:{
                'Content-Type': 'application/json'
            }
        });
    }

    public static sendAskForDeleteEmail(email: string) {
        return axios.post(`${process.env.VUE_APP_HASURA_BACK_URL}email/askForDeleteMail?&email=${email}`);
    }

    public static sendRGPDEmail(user: User){
        return axios.post(`${process.env.VUE_APP_HASURA_BACK_URL}email/askForRGPDMail`,
        user.json,
        {
            headers:{
                'Content-Type': 'application/json'
            }
        });
    }
    
    public static sendAskForTransportEmail(email: string) {
        return axios.post(`${process.env.VUE_APP_HASURA_BACK_URL}email/askForTransportMail?&email=${email}`);
    }
    
    public static sendOrderConfirmation(email: string) {
        return axios.post(`${process.env.VUE_APP_HASURA_BACK_URL}email/confirmOrder?&email=${email}`);
    }

    public static sendReturnConfirmation(email: string) {
        return axios.post(`${process.env.VUE_APP_HASURA_BACK_URL}email/confirmReturn?&email=${email}`);
    }

    public static sendDenyTransportRequest(orderId: string) {
        return axios.post(`${process.env.VUE_APP_HASURA_BACK_URL}email/denyRequestTransport?&orderId=${orderId}`);
    }

    public static sendTransportRequestCancel(email: string) {
        return axios.post(`${process.env.VUE_APP_HASURA_BACK_URL}email/transportRequestCancel?&email=${email}`);
    }
}



