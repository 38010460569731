
import Vue from "vue";
import Button from "@/components/atoms/Button.vue";
import SelectProducts from "@/components/organisms/common/SelectProducts.vue";
import ProductTypeWithQuantity from "@/data/models/product-type-with-quantity";
import UserRole from "@/data/enums/roles";
import User from "@/data/models/user";
import { bus } from "@/main";
import ProductStatus from "@/data/enums/product-status";

export default Vue.extend({
  name: "ReportBreakagesLossesDialog",
  components: { Button, SelectProducts },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      products: [] as any[],
      userId: "",
      origin: null as string | null,
      items: [] as User[],
      productsError: false,
      valid: false,
      submitted: false,
      user: {} as User,
      loading: false,
      origins: [
        { id: ProductStatus.AVAILABLE, title: this.$t("stock.table.passplat").toString().toUpperCase() },
        { id: ProductStatus.IN_CIRCULATION, title: this.$t("stock.table.streamers") },
      ],
    };
  },
  watch: {
    products: {
      handler(newValue: any[]) {
        this.productsError = newValue.length === 0 && this.submitted;
      },
    },
  },
  computed: {
    isAdmin(): boolean {
      return ((this.$store.getters.currentUser?.defaultRole as UserRole) || "") === UserRole.ADMIN;
    },
  },
  mounted() {
    if (!this.isAdmin) {
      this.userId = this.$store.getters.currentUser?.id;
      return;
    }

    this.$services.user.getUsers().then((users) => {
      this.items = users;
    });
    this.user = this.$store.getters.currentUser;
  },
  methods: {
    updateProducts(products: any[]) {
      this.productsError = false;
      this.products = products;
    },
    getProductStatusToUpdate(userId: string) {
      return this.$services.user.getUser(userId).then((data) => {  
        return (data as User).sourceProductStatus;
      });
    },
    async onSubmit(handleSubmit: Function, validate: any) {
      this.submitted = true;
      handleSubmit();
      const valid = await validate();

      if (this.products.length === 0) {
        this.productsError = true;
        return;
      }

      if (!valid) {
        return;
      }

      const currentUserRole = this.$store.getters.currentUser?.defaultRole as UserRole;

      const user = this.userId === "" ? this.$store.getters.currentUser.id : this.userId;

      const reportDate = new Date();
      const prodStat = await this.getProductStatusToUpdate(user);
        
      let filterOnUserId = user;

      if (currentUserRole === UserRole.ADMIN || currentUserRole === UserRole.STREAMER || currentUserRole === UserRole.CLD || currentUserRole === UserRole.PICKER) {
        filterOnUserId = "";
      }
      
      const promises: Promise<any>[] = [];

      this.products.forEach((product: ProductTypeWithQuantity) => {
        promises.push(
          this.$services.product
            .getProductsForBreakage(prodStat, product?.quantity || 0, product.productType?.id || "", filterOnUserId)
            .then((prodId) => {
              const ids = prodId.data.products.map((x: any) => x.id);
              return this.$services.product.updateProductsForBreakage(ids, user, reportDate.toISOString().toDateString("YYYY-MM-DDTH:m:s")).then((result) => {
                if (result.data.updateProducts.affected_rows !== product?.quantity) {
                  if (result.data.updateProducts.affected_rows === 0) {
                    this.$notify(
                      this.$t("common.breakagesLosses.notOneProdutToReport", {
                        product: product.productType?.title || "",
                      }).toString(),
                      {
                        color: "error",
                      }
                    );
                  } else {
                    this.$notify(
                      this.$t("common.breakagesLosses.notEnoughProductsButBreakLossSuccessfully", {
                        count: result.data.updateProducts.affected_rows,
                        product: product.productType?.title || "",
                      }).toString(),
                      {
                        color: "primary",
                      }
                    );
                  }
                } else {
                  this.$notify(this.$t("common.breakagesLosses.productsBreakLossSuccessfully").toString(), {
                    color: "primary",
                  });
                }
              });
            })
            .catch((e) => console.log(e))
        );
      });

      Promise.all(promises)
        .then(() => {
          this.close();
          bus.$emit("updateProductsEvent");
        })
        .catch(() => {
          this.$notify(this.$t("common.error").toString(), {
            color: "error",
          });
        });
    },
    close() {
      this.$emit("close");
      this.products = [];
      this.productsError = false;
      this.submitted = false;
      (this.$refs.selectProducts as any)?.reset();
      (this.$refs.observer as any)?.reset();
      this.userId = "";
      this.origin = null;
    },
  },
});
