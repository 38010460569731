import StorageService from "@/data/services/storage-service";
import Vue from "vue";
import Vuetify, { VSnackbar, VBtn, VIcon } from "vuetify/lib";
import Notify from "@/plugins/notify";
import fr from "vuetify/src/locale/fr";

Vue.use(Notify, { $vuetify: new Vuetify().framework, queueable: true });

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
});

const darkMode = JSON.parse(StorageService.get("darkMode") || "false") as boolean;

export default new Vuetify({
  lang: {
    locales: { fr },
    current: "fr",
  },
  theme: {
    dark: darkMode,
    themes: {
      light: {
        primary: {
          base: "#A4C400",
          darken1: "#97b500",
        },
        secondary: "4A5BF2",
        background: "#F2F3F7",
        white: "#FFFFFF",
        link: "#2389E8",
        error: {
          base: "#FF5252",
          lighten1: "#FFCDD2",
        },
      },
    },
  },
});
