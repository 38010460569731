enum OrderStatus {
  WAITING_CARRIER = "WAITING_CARRIER",
  WAITING_CARRIER_ACCEPTATION = "WAITING_CARRIER_ACCEPTATION",
  COLLECTED = "COLLECTED",
  CARRIAGE_ACCEPTED = "CARRIAGE_ACCEPTED",
  DELIVERED = "DELIVERED",
  CANCELED = "CANCELED",
}

export default OrderStatus;
