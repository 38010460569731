import gql from "graphql-tag";

const GET_ORDERS_DATA = `id
orders_has_products {
  product_name
  product_quantity
  product_consignment_price
  product_service_price
  product_type_id
}
from {
  id
  createdAt
  displayName
  avatarUrl
  locale
  email
  isAnonymous
  defaultRole
  phoneNumber
  roles {
    role
  }
  details {
    address
    company
    description
    firstName
    lastName
    avatar
  }
}
to {
  id
  createdAt
  displayName
  avatarUrl
  locale
  email
  isAnonymous
  defaultRole
  phoneNumber
  roles {
    role
  }
  details {
    address
    company
    description
    firstName
    lastName
    avatar
  }
}
carrier {
  id
  createdAt
  displayName
  avatarUrl
  locale
  email
  isAnonymous
  defaultRole
  phoneNumber
  roles {
    role
  }
  details {
    address
    company
    description
    firstName
    lastName
    avatar
  }
}
fromAddress
toAddress
deliveryComment
orderedAt
wishDate
status
type
`;

export const GET_ORDERS = gql`
  query GetOrders($limit: Int, $statuses: [orders_status_enum!], $types: [String!]) {
    orders(limit: $limit, where: { type: { _in: $types }, status: { _in: $statuses } }, order_by: { orderedAt: desc }) {
        ${GET_ORDERS_DATA}
    }
  }
`;

export const GET_ORDER_BY_CARRIER = gql`
  query GetOrdersByCarrier($limit: Int, $statuses: [orders_status_enum!], $carrierIds: [uuid!]) {
    orders(limit: $limit, order_by: { orderedAt: desc }, where: { status: { _in: $statuses  }, carrier: { id: { _in: $carrierIds } } }) {
        ${GET_ORDERS_DATA}
    }
  }
`;

export const GET_ORDERS_BY_USER_ID = (type: "from" | "to") => {
  let userFilter: string;

  if (type === "from") {
    userFilter = `fromId: { _eq: $userId }`;
  } else {
    userFilter = `toId: { _eq: $userId }`;
  }

  return gql`
    query GetOrdersByStatusesAndTypes($limit: Int, $statuses: [orders_status_enum!], $types: [String!], $userId: uuid!) {
      orders(limit: $limit, where: { type: { _in: $types }, status: { _in: $statuses }, ${userFilter} }, order_by: { orderedAt: desc }) {
          ${GET_ORDERS_DATA}
      }
    }
  `;
};

export const GET_ORDER = gql`
  query GetOrder($id: uuid!) {
    order(id: $id) {
        ${GET_ORDERS_DATA}
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation CreateOrder($object: orders_insert_input!) {
    insertOrder(object: $object) {
      id
      to {
        email
      }
      from {
        email
      }
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation UpdateOrder($orderId: uuid!, $object: orders_insert_input!) {
    deleteOrdersHasProducts(where: { order_id: { _eq: $orderId } }) {
      affected_rows
    }
    insertOrder(object: $object, on_conflict: { constraint: orders_pkey, update_columns: [carrierId, fromAddress, fromId, orderedAt, status, toAddress, toId, wishDate, deliveryComment, type] }) {
      id
      orders_has_products {
        id
        product_name
        product_quantity
        product_service_price
        product_consignment_price
      }
      deliveryComment
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation CancelOrder($orderId: uuid!) {
    updateOrder(pk_columns: { id: $orderId }, _set: { status: CANCELED }) {
      id
    }
  }
`;

export const SET_ORDER_CARRIER = gql`
  mutation SetOrderCarrierAndWasherman($orderId: uuid!, $carrierId: uuid, $status: orders_status_enum) {
    updateOrder(pk_columns: { id: $orderId }, _set: { carrierId: $carrierId, status: $status }) {
      id
    }
  }
`;

export const SET_ORDER_CARRIER_AND_WASHERMAN = gql`
  mutation SetOrderCarrierAndWasherman($orderId: uuid!, $carrierId: uuid, $washermanId: uuid, $washermanAddress: String, $status: orders_status_enum) {
    updateOrder(pk_columns: { id: $orderId }, _set: { carrierId: $carrierId, toId: $washermanId, toAddress: $washermanAddress, status: $status }) {
      id
    }
  }
`;

export const SET_ORDER_STATUS = gql`
  mutation SetOrderStatus($orderId: uuid!, $status: orders_status_enum) {
    updateOrder(pk_columns: { id: $orderId }, _set: { status: $status }) {
      id
    }
  }
`;

export const REFUSE_ORDER = (removeWasherman: boolean) => {
  const washermanRemover = removeWasherman ? ", toId: null, toAddress: null" : "";

  return gql`
    mutation RefuseOrder($orderId: uuid!) {
      updateOrder(pk_columns: { id: $orderId }, _set: { status: WAITING_CARRIER, carrierId: null${washermanRemover} }) {
        id
      }
    }
  `;
};
