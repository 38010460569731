
import Vue from "vue";

export default Vue.extend({
  name: "InformationDialog",
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
});
