import Vue from "vue";
import { ValidationObserver, ValidationProvider, extend, localize } from "vee-validate";
import { email, max } from "vee-validate/dist/rules";

extend("required", {
  validate(value) {
    return {
      required: true,
      valid: ["", null, undefined].indexOf(value) === -1,
    };
  },
  computesRequired: true,
});

extend("email", {
  ...email,
});

extend("max", {
  ...max,
});

extend("fileSize", {
  validate(value: File) {
    return {
      required: true,
      valid: !value || value.size < 10000000,
    };
  },
});

localize("fr");

localize({
  fr: {
    messages: {
      required: "Ce champ est requis",
      min: "{length} caractères minimum",
      max: "{length} caractères maximum",
      email: "Ce champ doit être une adresse mail",
      fileSize: "La taille de la facture ne doit pas dépasser 10 Mo",
    },
  },
});
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
