import store from "@/store";
import axios, { AxiosRequestConfig } from "axios";

export default class FileStorageDao {
  upload(file: File, path: string, allowedUsers: string): Promise<void> {
    const formData = new FormData();

    formData.set("file", file);
    formData.set("allowedUsers", allowedUsers);

    const config: AxiosRequestConfig = {
      method: "put",
      url: `${process.env.VUE_APP_HASURA_BACK_URL}file-storage/${path}`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    return axios(config).then();
  }

  get(path: string): Promise<any> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `${process.env.VUE_APP_HASURA_BACK_URL}file-storage/${path}`,
      responseType: "arraybuffer",
      headers: {
        "x-user-id": store.getters.currentUser?.id || null,
      },
    };

    return axios(config).then((response) => {
      if ((response.data as ArrayBuffer).byteLength === 0) {
        return null;
      }

      return response.data;
    });
  }
}
