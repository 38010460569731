
import Vue from "vue";
import UserRole from "@/data/enums/roles";
import User from "@/data/models/user";
import ProductType from "@/data/models/product-type";
import Order from "@/data/models/order";
import OrderType from "@/data/enums/order-type";

export default Vue.extend({
  name: "HomeTransportRequestDialog",
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    addWasherman: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible: {
      handler(newValue: boolean) {
        if (newValue) {
          this.verifyQuantity();
          this.getCarriers();
          this.getWashermen();
        } else {
          this.quantityError = false;
        }
      },
    },
  },
  data() {
    return {
      order: null as Order | null,
      carrierId: "",
      washermanId: null,
      loadingQuantity: false,
      loadingCarriers: false,
      loadingWashermen: false,
      valid: false,
      carriers: [] as User[],
      washermen: [] as User[],
      quantityError: false,
    };
  },
  computed: {
    loading(): boolean {
      return this.loadingQuantity || this.loadingCarriers || this.loadingWashermen;
    },
  },
  methods: {
    verifyQuantity() {
      this.loadingQuantity = true;

      this.$services.order
        .getOrder(this.orderId)
        .then(async (result) => {
          this.order = result;

          if (this.order?.type !== OrderType.NEW_BOXES) {
            return;
          }

          if (!result) {
            return;
          }

          const promises: Promise<ProductType>[] = result.products.map((p) => {
            return this.$services.product.getProductType(p.productTypeId || "");
          });

          const productTypes = await Promise.all(promises);

          productTypes.forEach((pt) => {
            const orderProduct = result.products.find((p) => p.productTypeId === pt.id);
            const ptQuantity = (pt.quantity || [])[0] ? (pt.quantity || [])[0].count : 0;
            this.quantityError = (orderProduct?.quantity || 0) > ptQuantity;

            if (this.quantityError) {
              return;
            }
          });
        })
        .finally(() => {
          this.loadingQuantity = false;
        });
    },
    onSubmit() {
      const washerman = this.washermen.find((w) => w.id === this.washermanId);

      if ((this.addWasherman && !washerman) || !this.order) {
        return;
      }

      const carrier = this.carriers.find((c) => c.id === this.carrierId);

      this.loadingQuantity = true;

      this.$services.order
        .setOrderCarrierAndWasherman(this.order, this.carrierId, washerman)
        .then(() => {
          if (carrier != null) {
            this.$services.email.sendAskForTransportEmail(carrier.email || "");
          } else {
            this.$notify(this.$t("comon.errorEmailNotSent").toString(), { color: "error" });
          }
          this.$notify(this.$t("orders.setCarrierSuccess").toString(), {
            color: "primary",
          });
          this.close();
        })
        .catch(() => {
          this.$notify(this.$t("orders.setCarrierFailed").toString(), {
            color: "error",
          });
        })
        .finally(() => {
          this.loadingQuantity = false;
        });
    },
    close() {
      (this.$refs.observer as any)?.reset();
      this.carrierId = "";
      this.washermanId = null;
      this.$emit("close");
    },
    getCarriers() {
      this.loadingCarriers = true;

      this.$services.user
        .getUsersWithRole([UserRole.CARRIER, UserRole.ADMIN])
        .then((data) => {
          this.carriers = data;
        })
        .finally(() => {
          this.loadingCarriers = false;
        });
    },
    getWashermen() {
      this.loadingWashermen = true;

      this.$services.user
        .getUsersWithRole([UserRole.WASHERMAN])
        .then((data) => {
          this.washermen = data;
        })
        .finally(() => {
          this.loadingWashermen = false;
        });
    },
  },
});
