import Order from "@/data/models/order";
import apolloProvider from "@/plugins/apollo";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { DocumentNode } from "graphql";
import OrderStatus from "../enums/order-status";
import OrderType from "../enums/order-type";
import User from "../models/user";
import {
  CANCEL_ORDER,
  CREATE_ORDER,
  GET_ORDER,
  GET_ORDERS,
  GET_ORDERS_BY_USER_ID,
  GET_ORDER_BY_CARRIER,
  REFUSE_ORDER,
  SET_ORDER_CARRIER,
  SET_ORDER_CARRIER_AND_WASHERMAN,
  SET_ORDER_STATUS,
  UPDATE_ORDER,
} from "../queries/orders/queries";

export default class UserDao {
  getOrders(type: OrderType | null, limit: number | null = null, statuses: OrderStatus[] = [], userId: string | null = null): Promise<Order[]> {
    const variables: any = { limit: limit };

    if (statuses.length > 0) {
      variables["statuses"] = statuses;
    } else {
      variables["statuses"] = [OrderStatus.WAITING_CARRIER, OrderStatus.WAITING_CARRIER_ACCEPTATION, OrderStatus.COLLECTED, OrderStatus.CARRIAGE_ACCEPTED, OrderStatus.DELIVERED, OrderStatus.CANCELED];
    }

    variables["types"] = type ? [type] : [OrderType.NEW_BOXES, OrderType.DIRTY_BOXES, OrderType.CLEAN_BOXES];

    if (userId) {
      variables["userId"] = userId;
    }

    let query: DocumentNode = GET_ORDERS;

    if (type === OrderType.NEW_BOXES && userId) {
      query = GET_ORDERS_BY_USER_ID("to");
    } else if (type === OrderType.DIRTY_BOXES && userId) {
      query = GET_ORDERS_BY_USER_ID("from");
    }

    return apolloProvider.defaultClient
      .query({
        query: query,
        variables: variables,
      })
      .then((result: any) => {
        if (!result.data) {
          return [];
        }

        const orders: Order[] = result.data.orders.map((o: any) => {
          return Order.map(o);
        });

        return Promise.resolve(orders);
      });
  }

  getOrdersByCarrier(statuses: OrderStatus[] = [], carrierId: string): Promise<Order[]> {
    const variables: any = { carrierIds: [carrierId] };

    if (statuses.length > 0) {
      variables["statuses"] = statuses;
    }

    return apolloProvider.defaultClient
      .query({
        query: GET_ORDER_BY_CARRIER,
        variables: variables,
      })
      .then((result: any) => {
        if (!result.data) {
          return [];
        }

        const orders: Order[] = result.data.orders.map((o: any) => {
          return Order.map(o);
        });

        return orders;
      });
  }

  async getLastOrders(type: OrderType): Promise<Order[]> {
    return await this.getOrders(type, 2);
  }

  async getOrder(id: string): Promise<Order | null> {
    return apolloProvider.defaultClient
      .query({
        query: GET_ORDER,
        variables: { id: id },
      })
      .then((result: any) => {
        return Order.map(result.data.order);
      });
  }

  createOrder(order: any): Promise<any> {
    return apolloProvider.defaultClient
      .mutate({
        mutation: CREATE_ORDER,
        variables: {
          object: order,
        },
      })
      .then((result) => {
        return result;
      });
  }

  updateOrder(order: any): Promise<string | null> {
    return apolloProvider.defaultClient
      .mutate({
        mutation: UPDATE_ORDER,
        variables: {
          orderId: order.id,
          object: order,
        },
      })
      .then((result) => {
        const orderId = result.data?.insertOrder?.id;

        if (orderId) {
          return orderId;
        }

        return null;
      });
  }

  cancelOrder(order: Order): Promise<void> {
    return apolloProvider.defaultClient
      .mutate({
        mutation: CANCEL_ORDER,
        variables: {
          orderId: order.id,
        },
      })
      .then();
  }

  setOrderCarrierAndWasherman(order: Order, carrierId: string | null = null, washerman: User | null | undefined = undefined): Promise<void> {
    const variables: any = {
      orderId: order.id,
      carrierId: carrierId,
      status: carrierId ? OrderStatus.WAITING_CARRIER_ACCEPTATION : OrderStatus.WAITING_CARRIER,
    };

    if (washerman !== undefined) {
      variables["washermanId"] = washerman?.id || null;
      variables["washermanAddress"] = washerman?.address?.label || null;
    }

    return apolloProvider.defaultClient
      .mutate({
        mutation: washerman ? SET_ORDER_CARRIER_AND_WASHERMAN : SET_ORDER_CARRIER,
        variables: variables,
      })
      .then();
  }

  setOrderStatus(order: Order, status: OrderStatus): Promise<void> {
    return apolloProvider.defaultClient
      .mutate({
        mutation: SET_ORDER_STATUS,
        variables: {
          orderId: order.id,
          status: status,
        },
      })
      .then(() => {
        if (order.type === OrderType.NEW_BOXES && status === OrderStatus.DELIVERED) {
          /* Desactivate invoice generation for the moment */
          //return this.createInvoice(order);
          return;
        } else {
          return;
        }
      });
  }

  refuseOrder(order: Order): Promise<void> {
    return apolloProvider.defaultClient
      .mutate({
        mutation: REFUSE_ORDER(order.type === OrderType.DIRTY_BOXES),
        variables: {
          orderId: order.id,
        },
      })
      .then();
  }

  createInvoice(order: Order) {
    order.removeAllAvatars();
    const config: AxiosRequestConfig = {
      method: "post",
      url: `${process.env.VUE_APP_HASURA_BACK_URL}invoice`,
      data: order,
    };

    return axios(config).then(() => Promise.resolve());
  }

  getInvoice(orderId: string) {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `${process.env.VUE_APP_HASURA_BACK_URL}invoice/${orderId}`,
      responseType: "arraybuffer",
    };

    return axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 404) {
          return Promise.resolve(null);
        }

        throw error;
      });
  }
}
