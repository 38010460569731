
import Vue from "vue";
import HasuraResetPasswordErrorUtils from "@/helpers/hasura-reset-password-utils";

export default Vue.extend({
  name: "Password",
  data() {
    return {
      valid: false,
      password: "",
      passwordConfirmation: "",
      loadingPasswordReset: false,
      showPassword: false,
      showPasswordConfirmation: false,
    };
  },
  computed: {
    containerBackgroundColor() {
      const primary = this.$vuetify.theme.themes["light"].primary as any;
      const color = primary ? primary.base : "white";
      return `background-color: ${color}`;
    },
  },
  methods: {
    onSubmit() {
      if (this.passwordConfirmation == this.password) {
        this.loadingPasswordReset = true;
        this.$services.auth
          .changePassword(this.password)
          .then(() => {
            this.$notify(this.$t("reset.successLabel").toString(), {
              color: "primary",
            });
            this.$services.user.verifyUser();
            this.handleNext();
          })
          .catch((result) => {
            this.$notify(
              this.$t(
                HasuraResetPasswordErrorUtils.getTranslation(result.status)
              ).toString(),
              { color: "error" }
            );
          })
          .finally(() => {
            this.loadingPasswordReset = false;
          });
      } else {
        this.$notify(this.$t("reset.errorNotSamePassword").toString(), {
          color: "error",
        });
      }
    },
    handleNext() {
      this.$router.push("/");
    },
  },
});
