
export default class HasuraAuthErrorUtils {
    static getTranslation(error: number) {
      const authError = error;
  
      if (authError) {
        return this.getAuthTranslation(authError)
      }
      return 'errors.unknown'
    }
  
    protected static getAuthTranslation(error: number): string {
        switch (error) {
            case 400:
                return 'errors.hasuraAuth.invalidMailFormat';
            case 401:
                return 'errors.hasuraAuth.invalidEmailOrPassword';
            case 404 :
                return 'errors.hasuraAuth.accessToServer'; 
            case 409 :
                return 'errors.hasuraAuth.emailAlreadyUsed';
            default:
                return 'errors.unknown';
      }
    }
  }
  