import TypeUtils from "@/helpers/type-utils";
import Constants from "../constants";
import ProductType from "./product-type";

export default class ProductTypeWithQuantity {
  productType: ProductType | null = null;
  quantity: number | null = null;

  static map(data: Partial<ProductTypeWithQuantity> | null): ProductTypeWithQuantity {
    const object: ProductTypeWithQuantity = new ProductTypeWithQuantity();
    object.productType = data?.productType ? ProductType.map(data.productType) : null;
    object.quantity = TypeUtils.hasNumberOrDefault(data?.quantity);

    return object;
  }

  get consignmentPriceTotal(): number {
    return (this.productType?.consignmentPrice || 0) * (this.quantity || 0);
  }

  get servicePriceTotal(): number {
    return (this.productType?.servicePrice || 0) * (this.quantity || 0);
  }

  get total(): number {
    return this.consignmentPriceTotal + this.servicePriceTotal;
  }

  get totalVAT(): number {
    return this.servicePriceTotal * Constants.taxRateMultiplier + this.consignmentPriceTotal;
  }

  get json(): any {
    return JSON.parse(JSON.stringify(this));
  }
}
