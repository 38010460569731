import BaseModel from "@/data/models/base/base-model";
import TypeUtils from "@/helpers/type-utils";

export default class Schedule extends BaseModel {
  userId: string | null = null;
  day: string | null = null;
  time: string | null = null;

  static map(data: Partial<Schedule> | null): Schedule {
    const object: Schedule = new Schedule();

    object.id = TypeUtils.hasStringOrDefault(data?.id);
    object.userId = TypeUtils.hasStringOrDefault(data?.userId);
    object.day = TypeUtils.hasStringOrDefault(data?.day);
    object.time = TypeUtils.hasStringOrDefault(data?.time);

    return object;
  }

  get json(): any {
    return JSON.parse(JSON.stringify(this));
  }
}
