
import Vue, { PropType } from "vue";
import LoadingCompany from "@/components/atoms/LoadingCompany.vue";
import OrderActions from "@/components/molecules/orders/OrderActions.vue";
import ReturnDetailsDialog from "./ReturnDetailsDialog.vue";
import Order from "@/data/models/order";
import OrderType from "@/data/enums/order-type";
import UserRole from "@/data/enums/roles";

export default Vue.extend({
  name: "ReturnsTable",
  props: {
    items: {
      type: Array as PropType<any[]>,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        { text: this.$t("returns.fromAddress"), value: "fromAddress" },
        { text: this.$t("returns.toAddress"), value: "toAddress" },
        { text: this.$t("returns.orderedAt"), value: "orderedAt" },
        { text: this.$t("returns.products"), value: "products" },
        { text: this.$t("returns.quantity"), value: "quantity" },
        { text: this.$t("returns.wishDate"), value: "wishDate" },
        { text: "", value: "actions" },
      ],
      selectedOrderId: null as string | null,
      showReturnDetailsDialog: false,
    };
  },
  mounted() {
    if (this.isAdmin) {
      this.headers.splice(this.headers.length - 1, 0, { text: this.$t("orders.table.headers.carrier"), value: "carrier.displayName" });
    }
  },
  watch: {
    $route: {
      handler(newValue: any) {
        this.selectedOrderId = newValue.params.id;
        this.showReturnDetailsDialog = true;
      },
      immediate: true,
    },
  },
  computed: {
    isAdmin(): boolean {return ((this.$store.getters.currentUser?.defaultRole as UserRole) || "") === UserRole.ADMIN;},
  },
  methods: {
    dateFormatted(date: string | null) {
      if (!date) {
        return "?";
      }

      return date.toDateString(this.$t("common.dateFormat.short").toString());
    },
    didTapRow(row: Order) {
      if (row.type === OrderType.CLEAN_BOXES) {
        return;
      }

      this.$router.push("/returns/" + row.id);
    },
    closeDetails() {
      this.showReturnDetailsDialog = false;
      this.selectedOrderId = null;
      this.$router.push("/returns");
    },
  },
  components: { LoadingCompany, OrderActions, ReturnDetailsDialog },
});
