import User from "@/data/models/user";
import State from "./state";

export default {
  // Must be call after necessary operation (like checking if current user is connected or not)
  setApplicationReady(state: State) {
    state.isApplicationReady = true;
  },
  setDrawerMini(state: State, mini: boolean) {
    state.isDrawerMini = mini;
  },
  setCurrentUser(state: State, currentUser: User | null) {
    state.currentUser = currentUser;
  },
};
