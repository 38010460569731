
import User from "@/data/models/user";
import Vue from "vue";

export default Vue.extend({
  name: "Header",
  props: {
    customTitle: {
      type: String,
    },
  },
  computed: {
    title(): string {
      if (this.customTitle) {
        return this.customTitle;
      }

      return this.$route.meta?.title ? this.$t(this.$route.meta.title).toString() : "";
    },
    subtitle(): string | null {
      return this.$route.meta?.subtitle ? this.$t(this.$route.meta?.subtitle).toString() : null;
    },
    initials(): string {
      if (!this.$store.getters.currentUser) {
        return "";
      }

      const currentUser: User = this.$store.getters.currentUser;

      const firstName = (currentUser.details?.firstName || "?")[0];
      const lastName = (currentUser.details?.lastName || "?")[0];

      return firstName + lastName;
    },
    avatar(): string {
      if (!this.$store.getters.currentUser) {
        return "";
      }

      const currentUser: User = this.$store.getters.currentUser;

      return currentUser.details?.avatar || "";
    },
    avatarBackgroundColor(): string {
      const primary = this.$vuetify.theme.themes["light"].primary as any;
      const color = primary ? primary.base : "white";
      return `background-color: ${color}33;`;
    },
  },
  methods: {
    showProfile() {
      if (this.$route.path !== "/profile") {
        this.$router.push("/profile");
      }
    },
  },
});
