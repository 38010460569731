import gql from "graphql-tag";

export const GET_CONFIG = gql`
  query GetConfig {
    config {
      key
      value
    }
  }
`;
