
import OrderStatus from "@/data/enums/order-status";
import OrderType from "@/data/enums/order-type";
import ProductStatus from "@/data/enums/product-status";
import Order from "@/data/models/order";
import ProductTypeWithQuantity from "@/data/models/product-type-with-quantity";
import Vue from "vue";

export default Vue.extend({
  name: "EditableItem",
  props: {
    product: {
      type: ProductTypeWithQuantity,
      required: true,
    },
    order: {
      type: Order,
    },
  },
  data() {
    return {
      initialQuantity: 0,
      availableQuantity: 0,
    };
  },
  mounted() {
    this.reset();
    this.refreshAvailableQuantity();
  },
  methods: {
    async refreshAvailableQuantity() {
      if ((this.order.status === OrderStatus.COLLECTED || this.order.status === OrderStatus.CARRIAGE_ACCEPTED) && this.order.type === OrderType.DIRTY_BOXES) {
        await this.$services.product.getProductTypes(ProductStatus.IN_CIRCULATION).then((data) => {
          const productInDDB = data.find((element: any) => element.id === this.product.productType?.id);
          if (productInDDB?.quantity && productInDDB.quantity.length > 0) {
            this.availableQuantity = productInDDB.quantity[0].count;
          } else {
            this.availableQuantity = 0;
          }
        });
      } else {
        await this.$services.product.getProductQuantityOnUserAndStatus(ProductStatus.IN_WASHING, this.order?.from?.id || "").then((result) => {
          this.availableQuantity = result.data.productsAggregate.aggregate.count;
        });
      }
    },
    reset() {
      this.initialQuantity = this.product.quantity || 0;
    },
    addQuantity() {
      this.product.quantity = Number(this.product.quantity) + 1;
    },
    removeQuantity() {
      this.product.quantity = Number(this.product.quantity) - 1;
    },
    async valid(): Promise<boolean> {
      await this.refreshAvailableQuantity();

      if (this.product.quantity === null) {
        return false;
      }

      return this.product.quantity <= this.initialQuantity + this.availableQuantity;
    },
  },
});
