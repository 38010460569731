<template>
  <Input
    ref="input"
    v-currency="options"
    :value="formattedValue"
    @change="onChange"
    @input="onInput"
    prependIcon="mdi-currency-eur"
  />
</template>

<script>
export default {
  name: "PriceInput",
  props: {
    value: {
      type: Number,
      default: null
    },
    prependIcon: {
      type: String,
    },
  },
  data() {
    return {
      formattedValue: null,
      options: { "currency": "EUR","precision" : 2}
    };
  },
  watch: {
    value(value) {
      this.setValue(value);
    }
  },
  mounted() {
    this.setValue(this.value);
  },
  methods: {
    setValue(value) {
      this.$ci.setValue(this.$refs.input, (value < 0 || value>999) ? 0 : value);
    },
    onInput(value) {
      this.$emit("input", this.$ci.getValue(this.$refs.input));
      this.formattedValue = value;
    },
    onChange(value) {
      this.$emit("change", this.$ci.getValue(this.$refs.input));
      this.formattedValue = value;
    }
  }
};
</script>
