enum UserRole {
  CARRIER = "carrier",
  PICKER = "picker",
  STREAMER = "streamer",
  ADMIN = "owner",
  USER = "user",
  WASHERMAN = "washerman",
  CLD = "cld",
  NOT_CONNECTED = "notConnected",
}

export default UserRole;

export class UserRoleHelper {
  static get allValues(): UserRole[] {
    return [UserRole.CARRIER, UserRole.PICKER, UserRole.STREAMER, UserRole.ADMIN, UserRole.USER, UserRole.WASHERMAN, UserRole.CLD, UserRole.NOT_CONNECTED];
  }

  static getTitleKey(type: UserRole): string {
    return "common.roles." + type;
  }

  static disallowedRoutes(role: UserRole): string[] {
    switch (role) {
      case UserRole.ADMIN:
        return [];
      case UserRole.CARRIER:
        return ["/", "/users", "/users/:id", "/products", "/orders", "/orders/:id", "/returns", "/return/:id", "/washes", "/stock"];
      case UserRole.PICKER:
        return ["/", "/users", "/users/:id", "/products", "/orders", "/orders/:id", "/missions", "/washes", "/stock"];
      case UserRole.STREAMER:
        return ["/", "/users", "/users/:id", "/products", "/returns", "/return/:id", "/missions", "/washes", "/stock"];
      case UserRole.WASHERMAN:
        return ["/", "/users", "/users/:id", "/products", "/returns", "/return/:id", "/missions", "/orders", "/orders/:id", "/stock"];
      case UserRole.USER:
        return ["/", "/users", "/users/:id", "/products", "/orders", "/orders/:id", "/returns", "/return/:id", "/missions", "/washes", "/stock"];
      case UserRole.CLD:
        return ["/", "/users", "/users/:id", "/products", "/missions", "/washes", "/stock"];
      case UserRole.NOT_CONNECTED:
        return ["/", "/users", "/users/:id", "/products", "/orders", "/orders/:id", "/returns", "/return/:id", "/missions", "/profile", "/washes", "/stock"];
      default:
        return [];
    }
  }

  static redirect(role: UserRole): string {
    switch (role) {
      case UserRole.ADMIN:
        return "/";
      case UserRole.CARRIER:
        return "/missions";
      case UserRole.PICKER:
        return "/returns";
      case UserRole.STREAMER:
        return "/orders";
      case UserRole.CLD:
        return "/orders";
      case UserRole.WASHERMAN:
        return "/washes";
      case UserRole.USER:
        return "/404";
      case UserRole.NOT_CONNECTED:
        return "404";
      default:
        return "404";
    }
  }
}
